<template>
  <v-card class="w-100 d-flex flex-column card-height">
    <div class="w-100 sticky-top d-flex align-center justify-space-between">
      <div class="d-flex align-center ml-4">
        <v-card width="fit-content" class="h-fit elevation-0">
          <div class="text-h6 word-break-word">Course Content</div>
        </v-card>
      </div>
      <v-icon
        class="cursor mr-2"
        @click="
          goToRoute('ProductView', {
            subjectId: $route.query?.subjectId,
            subject: $route.query?.subject,
          })
        "
        >mdi-close</v-icon
      >
    </div>

    <v-card class="elevation-0 list-card">
      <v-list class="pa-0">
        <v-card class="elevation-0">
          <v-expansion-panels
          
            class="mx-0 pa-0"
            active-class="list-active "
            variant="accordion"
            v-model="unitIdFromRouter"

          >
            <v-expansion-panel
              class="ma-0 pa-0 border-outlined overflow-hidden"
              v-for="(item, i) in selectedSubject?.units"
              :key="i"
            >
              <v-expansion-panel-header>
                <div class="w-100 d-flex mxpx-12 flex-column">
                  <div class="pa-0 mbpx-10 course-sub-title-name text-overline">
                    Unit {{ i + 1 }}:{{  }}
                  </div>
                  <div
                    :class="[
                      'ma-0',
                      'pa-0',
                      'course-title',
                      'text-subtitle-1',
                      { 'onColor-T': item.indexing === unitIdFromRouter },
                    ]"
                  >
                    <span class="cursor text-container">
                      {{ item?.unitName }}
                      <!-- {{ truncateText(item?.unitName, 27) }} -->
                      <!-- {{item.indexing+" "+ unitIdFromRouter}} -->
                    </span>
                  </div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content
                class="px-0 mx-0 overflow-hidden m-expansion-panel-content pa-0"
              >
                <div class="m-nav-unit-list">
                  <div
                    v-for="(chapter, j) in item?.chapters"
                    :key="j"
                    class="roundex-xl px-0 px-0"
                  >
                  <v-list-item class="ma-0 pa-0 py-1">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <div
                            @click="
                              chapterspage(
                                i,
                                j,
                                item.unitName,
                                chapter.chapterName
                              )
                            "
                            :class="{
                              'clicked-chapter':
                                chapterFromRouter == chapter?.chapterName,
                            }"
                            class="cursor mx-6 my-2 text-container"
                            v-bind="attrs"
                            v-on="on"
                          >
                            Chapter {{ j + 1 }} :
                            {{ chapter?.chapterName }}
                          </div>
                        </template>
                        <span>{{ chapter?.chapterName }}</span>
                      </v-tooltip>
                    </v-list-item>
                    <!-- <v-list-item class="ma-0 pa-0 py-1">
                      <div
                        @click="
                          chapterspage(i, j, item.unitName, chapter.chapterName)
                        "
                        :class="{ 'clicked-chapter': chapterFromRouter == chapter?.chapterName }"
                        class="cursor mx-6 my-2 text-container"
                      >
                        Chapter {{ j + 1 }} :
                        {{ chapter?.chapterName }}
                       
                      </div>
                    </v-list-item> -->
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>         
        </v-card>
      </v-list>
    </v-card>
  </v-card>
</template>
   
   <script>
import { myMixin } from "../myMixin.js";

export default {
  name: "SidenavCourses",
  mixins: [myMixin],

  data() {
    return {
      selectedSubject: {},
      unitIdPrinting: "",
      activeHeadder: 0,
    };
  },

  methods: {
    truncateText(text, limit) {
      if (text.length > limit) {
        return text.substring(0, limit) + "...";
      }
      return text;
    },
    getColorClass(unitId) {
      return unitId === 0 ? "text-red" : "text-blue";
    },
    async getSubjectData() {
      const courseData = await this.$store.dispatch("getCourseData");
      this.selectedSubject =
        courseData[0].subjects[this.$route.query.subjectId];
    },
    toggleChapterColor(unitIndex, chapterIndex) {
    this.selectedSubject.units.forEach((unit) => {
      unit.chapters.forEach((chapter) => {
        chapter.clicked = false;
      });
    });
    this.selectedSubject.units[unitIndex].chapters[chapterIndex].clicked = true;
  },
    async chapterspage(unitIndex, chapterIndex, unit, chapter) {

      this.toggleChapterColor(unitIndex, chapterIndex);
      if (
        this.$route.query?.unitId == unitIndex &&
        this.$route.query?.chapterId == chapterIndex
      ) {
        return;
      }
      this.$router.push({
        name: "Topics",
        query: {
          chapter: chapter,
          chapterId: chapterIndex,
          unit: unit,
          unitId: unitIndex,
          subject: this.$route.query?.subject,
          subjectId: this.$route.query?.subjectId,
        },
      });
    },
  },
  mounted() {
    this.getSubjectData();
  },
  computed: {
    unitIdFromRouter() {

      return parseInt(this.$route.query.unitId);
    },
    
    chapterFromRouter() {
      return this.$route.query.chapter
    },
  },

  watch: {
    "$route.query.subjectId"() {
      this.getSubjectData();
    },
  },
};
</script>
  <style scoped>
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}
.card-height {
  height: 100%;
  background-color: var(--subject-secondary);
}
.v-card > *:last-child:not(.v-btn):not(.v-chip):not(.v-avatar) {
  border-bottom-left-radius: unset;
  border-bottom-right-radius: unset;
}
.v-card > *:first-child:not(.v-btn):not(.v-chip):not(.v-avatar),
.v-card > .v-card__progress + *:not(.v-btn):not(.v-chip):not(.v-avatar) {
  border-top-left-radius: unset;
  border-top-right-radius: unset;
}

.nav-unit-list-head {
  border: 1px solid #82d2da;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  background: #faf8ee;
}
.sticky-top {
  position: sticky;
  top: 0;
  z-index: 2;
  height: 60px;
}
.clicked-chapter {
  color: var(--subject-onColor) !important;
}
.text-container {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /* Show only 2 lines */
  overflow: hidden;
  text-overflow: ellipsis;
  /* max-height: 3em;  */
  /* line-height: 1.5em; */
  word-break: break-word; /* Break long words */
}

.m-nav-unit-list > *:not(:last-child) {
  border-bottom: 1px solid rgba(28, 28, 28, 0.12) !important;
  border-width: 100% !important;
}
.h-fit {
  height: fit-content !important;
}

.word-break-word {
  word-break: break-word !important;
  background: var(--subject-secondary) !important;

  text-overflow: ellipsis;
}
.divider-class {
  width: 100%;
  height: 1px;
  background: #e0e0e0;
}
.list-card {
  /* height: calc(100% - 100px); */
  max-height: calc(100vh - 170px);
  overflow-y: auto;
}

.border-outlined {
  border: 1px solid rgba(28, 28, 28, 0.12) !important;
}
.graphic {
  font-family: "GraphikRegular", sans-serif !important;
  color: #b38100;
}
.course-sub-title {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.text-red {
  color: red;
}

.text-blue {
  color: blue;
}
.course-sub-title-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
}
.v-card__subtitle,
.v-card__text,
.v-card__title {
  padding: 6px;
}
.more-rounded-progress {
  border-radius: 60px;
  width: 100%;
}
.active-title {
  font-weight: 600;
}
.course-title {
  /* font-size: 16px; */
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.m-expansion-panel-content .v-expansion-panel-content__wrap {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 0px !important;
}

.los-completed {
  color: var(--user-facing-color-text-text-high, #212121);
  /* font-size: 14px; */
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
  margin-left: 48px;
  margin-top: 20px;
}
.theme--light .v-expansion-panel .v-expansion-panel-header {
  background-color: var(--subject-secondary);
  /* color: rgba(0, 0, 0, 0.87); */
}
</style>
