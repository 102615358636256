<template>
    <div class="vh-100">
      <HeaderView/>
      <div class="w-100 content-height d-flex flex-row white">
        <v-card class="corDetFirPostTestDivStart elevation-0">
    
        <PostTestReportShow/>
        </v-card>
        <v-card class="corDetSecPostTestDivStart elevation-0">
          <SideNavCourses />
        </v-card>
  
    
      </div>
    </div>
  </template>
  
  
  
    
    <script>
  import SideNavCourses from "../components/SideNavCoursesView.vue";
  import HeaderView from "../components/HeaderView.vue";
 import PostTestReportShow from "../components/PostTestReportShow.vue";
import CourseConsumptionController from "@/controllers/CourseConsumptionController";

  export default {
    name: "CourseConsumptionView",
    components: {
      SideNavCourses,
      HeaderView,
      PostTestReportShow
   
    },
    data() {
      return {
        reportData: {}
  
      };
    },
    mounted() {
      // this.getCourseData();
      this.getSubTopicReport()
    },
    methods: {
      getRouterQuery() {
      var routerQuery = this.$route.query;
      var payload = {
        unitId: routerQuery.unit,
        chapterId: routerQuery.chapter,
        subjectId: routerQuery.subject,   
        subTopicId: routerQuery.subTopic,
        topicId: routerQuery.topic, 
        courseId: routerQuery.course 
      };

      return payload;
    },
      async getSubTopicReport() {
    //  console.log('============================')
      var payload = this.getRouterQuery();
      const responce = await CourseConsumptionController.getSubTopicReport(
        payload
      );
      if (responce.status == 200) {
        this.reportData = responce?.data?.data;
        this.DataLoading = false;
      }
    },
    }
  
  };
  </script>
  
    
  <style scoped>
  
 
  </style>