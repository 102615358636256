import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary:'#4234df',
                secondary:'#FF734A',
                primaryyy:'#82D2DA57',
                primary1: '#4234df',
                primary2: '#caecaf',
                primary3: '#dff3f5',
                primary4: '#e6f6f8',
                primarryOn:'#4234DF',
                accent1:'#6c367e',
                accent2:'#9c52b5',
                accent3:'#ede7f0',
                accentOn1:'#fbe8dd',
                accentOn2:'#ffffff',
                background:'#6F65E7',
                textColor1:'#212121',
                textColor2:'#666666',
                textColor3:'#a3a3a3',
         
                textColor4:'#bdbdba',
                textColor5:'#e0e0e0',
                textColor6:'#ebebeb',
                verified:'#228B22',
                ratingColor:'#FFCD3C',
                primaryShadow: '#a39bd4',
                Surfaceshade3: "#F0EFFE",
                surface:"#E9E9EB",
                tabColor: '#FFFFFF',
                answered:'#009051',
                skipped:'#F6E866',
                surface2 : '#FFF8E5',
                bookmarked:'#E868E3',
                textColor7: '#999999',
                sendotp: '#EAEAEA',
                myProfile: '#9f97ef',
                textCard: '#D4D0FF',
                doubts: '#7B7B7B',
                newColor: '#D4D1FF',
                topper: '#DEDCFC',
            
                // newShadeBlack: 'rgb(0,0,0)',
                newShadeBlack: "#666666",
                blackVariant: '#353535',
                yellowVariant: '#E6A500',
            },
         
        },
    },

});
