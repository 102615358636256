<template>
  <div class="sub-unit-screen pb-0 mt-0">
    <div>
      <div v-if="chapterDataLength == 0" class="loader-container">
        <img
          src="../assets/loader-eazily.gif"
          alt="Loading..."
          class="loader-img"
        />
      </div>

      <div class="text-subtitle-1 content-name ml-3 mb-2 cursor">
        <v-icon @click="home" class="unitss" size="20">mdi-home</v-icon>

        <!-- <img src="../assets/photo7.svg" @click="home" class="size-lock mt-1 mr-1" /> -->
        <div class="font-weight-medium">
          > <span class="unitss ml-1 mr-1">{{ subject }}</span> >
          <span class="unitss mr-1" @click="menu">Units</span> >
          <span class="unitss ml-1 mr-1">Chapters</span> >
          <span class="topicss">Topics</span>
        </div>
      </div>

      <div class="text-h6 font-weight-medium ml-2 pl-2">
        Chapter {{ this.$route.query.chapterId + 1 }}
      </div>

      <div class="text-h5 font-weight-medium ml-2 pl-2 mb-6">
        {{ this.chapterData.chapterName }}
      </div>

      <div class="subunit-cards">
        <div class="d-flex flex-wrap w-100">
          <div
            v-for="(topic, index) in this.chapterData.topics"
            :key="index"
            class="subUnitCard col-sm-4"
          >
            <!-- Common content for the rest of the cards -->
            <div
              class="w-100 h-100"
              style="height: 100% !important; position: relative"
            >
              <div class="content-name">
                <!-- <img
                  src="../assets/bookeys.svg"
                  class=" size-lock mt-1"
                /> -->
                <div class="text-caption mt-1 font-weight-medium">
                  TOPIC {{ index + 1 }}
                </div>
              </div>
              <div class="font-weight-medium text-h6">
                {{ topic?.topicName }}
              </div>

              <div class="content-name">
                <img src="../assets/random13.svg" class="size-lock mt-1" />
                <div class="text-body-2 mt-1 random12 font-weight-medium ml-1">
                  {{ topic.subTopics.length }} Sub Topics
                </div>
                <!-- <img src="../assets/random12.svg" class="size-lock mt-1 ml-5" /> -->
                <!-- <div class="text-body-2 mt-1 random12 font-weight-medium ml-1">
                  10 Hours
                </div> -->
              </div>

              <div class="">
                <v-btn
                  @click="subunittopics(index, topic.topicName)"
                  text
                  class="elevation-0 text-subtitle-2 subUnitBtn mt-3"
                  >View Sub-Topics</v-btn
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "UnitView",
  // props: ["subUnits"],
  data() {
    return {
      subUnitsData: [],
      courseId: "",
      subUnits: [],
      topics: [],
      topicId: 0,
      showPreTestReport: false,
      circleStepStaus: [],
      chapterName: "",
      subjectId: "",
      unitName: "",
      chapterData: {},
      subject: "",
      chapterDataLength: 0,
    };
  },
  methods: {
    home() {
      this.$router.push({
        name: "homeView",
      });
    },
    menu() {
      this.$router.push({
        name: "NoUnitSelected",
        query: {
          subjectId: this.$route.query.subjectId,
          subject: this.$route.query.subject,
        },
      });
    },

    subunittopics(topicIndex, topicName) {
      // this.$router.push('/subtopics');
      var pastQuery = this.$route.query;
      pastQuery.topicId = topicIndex;
      pastQuery.topic = topicName;
      pastQuery.course = this.courseId;
      this.$router.push({
        name: "SubTopics",
        query: pastQuery,
      });
    },
    async getChapterData() {
      try {
        var subjectId = this.$route.query.subjectId;
        var chapterId = this.$route.query.chapterId;

        var unitId = this.$route.query.unitId;

        this.chapterName = this.$route.query.chapter;
        if(!this.$store.state.courseData){
              await this.$store.dispatch("getCourseData");
        }
        const courseData = await this.$store.state?.courseData;
        this.courseId = courseData[0].id;
        this.chapterData =
          courseData[0].subjects[subjectId].units[unitId].chapters[chapterId];
        const chapterDataLength = Object.keys(this.chapterData).length;
        this.chapterDataLength = chapterDataLength;
      } catch (error) {
        console.error("Error fetching chapter data:", error);
      }
    },
  },
  mounted() {
    this.getChapterData();
    this.subject = this.$route.query.subject;
  },
  computed: {},
  watch: {
    "$route.query"() {
      this.getChapterData();
    },
  },
};
</script>
<style scoped>


.start-activity {
  color: var(--user-facing-color-text-text-low, #a3a3a3);
  /* font-family: Graphik; */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}

.step-progress {
  position: inherit !important;
  width: 90%;
}

.step-progress-style {
  position: absolute;
  top: 120px;
}



.content-last {
  display: flex;
  position: absolute !important;
  bottom: 12px !important;
  right: 20px;
}

.v-application .white {
  background-color: rgba(0, 0, 0, 0.04) !important;
  /* border-color: rgba(0, 0, 0, 0.04); */
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  min-width: 64px;
  padding: 0 0px;
}

.size-lock {
  width: 18px;
  height: 18px;
  /* color: red; */
}

.subunit-cards {
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  /* gap: 18px; */
  margin-left: 10px;
  /* grid-template-columns: auto auto auto auto; */
  /* grid-template-columns: repeat(4, 1fr); */
}

.subUnitCard {
  /* height: 156px; */
  /* width: 22.2rem; */
  /* width: calc(100% - 20px); */

  background-color: var(--subject-secondary) !important;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  cursor: pointer;
  flex-basis: calc(33.33% - 20px);
  margin: 10px;
  /* background: #fff; */
  /* margin-left: 30px; */
  /* margin-right: 30px; */
}
.green-circle {
  background-color: green;
}

.orange-circle {
  background-color: orange;
}

.topicss {
  color: rgba(0, 0, 0, 0.6);
}
.red-circle {
  background-color: red;
}

.subUnitCard-first {
  height: 240px;
  width: 21.2rem;
  /* border-radius: 16px; */
  border: 0.5px solid var(--user-facing-color-primary-primary-2, #b4e4e9);
  background: var(--user-facing-color-surface-surface, #f0effe);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
}

.v-application .white {
  background-color: rgba(0, 0, 0, 0.04);
}

.dot {
  width: 14px;
  height: 14px;
  /* background-color: red; */
  background-color: #c4c4c4;
  border-radius: 99px;
  margin-left: 6px;
  margin-top: 6px;
}

/* .dot-completed:before {
  border-color: rgba(6, 194, 112, 0.80);
  background: rgba(6, 194, 112, 0.80);
  color: black;
}

.dot-completed:after {
  background: rgba(6, 194, 112, 0.80);
}

.dot:before {
  border-color: rgba(255, 149, 1, 0.80);
  background: rgba(255, 149, 1, 0.80);
  color: black;
}

.dot:after {
  background: rgba(255, 149, 1, 0.80);
}

.dot:not(.dot-completed):before {
  background: #e0e0e0;
  border-color: #e0e0e0;
  color: black;
}

.dot:not(.dot-completed):after {
  background: #e0e0e0;
  border-color: #e0e0e0;
  color: black;
}
 */

.subUnitBtn {
  /* color: var(--user-facing-color-text-text-low, #a3a3a3);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-left: -10px; */
  height: 36px;
  width: 150px;
  border-radius: 4px;
  border: 1px solid var(--subject-onColor);
  color: var(--subject-onColor) !important;
  /* background-color: var(--subject-primary) !important; */
}

.subUnitBtn-again {
  color: var(--user-facing-color-primary-primary-on, #005b64);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-left: -10px;
}

.v-chip {
  background-color: #e0e0e0 !important;
  color: #212121 !important;
}

.active {
  background-color: rgba(6, 194, 112, 0.8) !important;
}

.random12 {
  color: rgba(0, 0, 0, 0.6);
}
.inactive {
  background-color: rgba(255, 149, 1, 0.8) !important;
}

/* .progressbar li.active:before {
  border-color: rgba(6, 194, 112, 0.80);
  background: rgba(6, 194, 112, 0.80) !important;
  color: black;
}

.progressbar li.active:after {
  background: rgba(6, 194, 112, 0.80) !important;
}

.progressbar li.inactive:before {
  border-color: rgba(255, 149, 1, 0.80);
  background: rgba(255, 149, 1, 0.80);
  color: black;
}

.progressbar li.inactive:after {
  background: rgba(255, 149, 1, 0.80);
}

.progressbar li:not(.active):not(.inactive):before {
  background: #e0e0e0;
  border-color: #e0e0e0;
  color: black;
}

.progressbar li:not(.active):not(.inactive):after {
  background: #e0e0e0;
} */
</style>