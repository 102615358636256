import axios from "axios";
import AuthService from "@/services/AuthService";
const instance = axios.create({
  baseURL: process.env.VUE_APP_MASTERS_API_URL,
});
export default {
  createGovtJobs: async function (data) {
    try {
      const response = await instance.post("govtjob", data, {
        headers: {
          Authorization: AuthService.getToken(),
        },
      });
      return response;
    } catch (error) {
      console.error("Error creating Govt Job:", error);
      return {
        data: {
          flag: false,
          error: "An error occurred while creating Govt Job.",
        },
      };
    }
  },

  getBoards: async function () {
    try {
      const response = await instance.get("govtjobs");
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getGovtJobByPagination: async function (pageSize, page) {
    try {
      const response = await instance.get(
        "govtjobs?pageSize=" + pageSize + "&page=" + page
      );
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getGovtJobs: async function () {
    try {
      const response = await instance.get(
        "govtjobs"
      );
      return response;
    } catch (error) {
      return error.response;
    }
  },

  updateGovtJob: async function (
    name,
    desc,
    eligibility,
    selectedSubject,
    logo,
    id
  ) {
    try {
      const response = await instance.put(
        "govtjob?id=" + id,
        {
          name: name,
          description: desc,
          eligibility: eligibility,
          selectedSubject: selectedSubject,
          iconUrl: logo,
        },
        {
          headers: {
            Authorization: AuthService.getToken(),
          },
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  },

  deleteGovt: async function (id) {
    // console.log("delete selected id", id);
    try {
      const response = await instance.delete("admin/meta/govtjob/" + id, {
        headers: {
          Authorization: AuthService.getToken(),
        },
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  deleteBulkGovtJob: async function (ids) {
    // console.log("delete selected id", ids);
    try {
      const response = await instance.delete(
        "govtjob",
        {
          data: {
            ids: ids,
          },
        },
        {
          headers: {
            Authorization: AuthService.getToken(),
          },
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  },
};
