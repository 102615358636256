<template>
  <div>
    <div v-if="isLoading" class="loader-container">
      <img
        src="../assets/loader-eazily.gif"
        alt="Loading..."
        class="loader-img"
      />
    </div>
    <div class="d-flex flex-row w-100 h-100 white">
      <v-card class="leftSide rounded-0">
        <NavDrawer />
      </v-card>

      <v-card
        class="subjectCardSlider w-100 z-index-2 rounded-xl transparent d-flex flex-column pl-5 elevation-0 rounded-0"
      >
        <div class="text-subtitle-1 content-name ml-3 mb-2 cursor">
          <v-icon @click="home" class="unitss" size="20">mdi-home</v-icon>
          <div class="font-weight-medium dropdown-container">
            > <span class="unitss mr-1">{{ subjects[this.$route.query.subjectId]?.name }}</span>
            <v-btn icon @click="toggleDropdown">
              <v-icon class="newShadeBlack--text">mdi-chevron-down</v-icon>
            </v-btn>

            <!-- Dropdown content -->

            <div v-if="dropdownOpen" class="dropdown-contentt" ref="dropdown">
              <!-- <v-divider class="mb-2"></v-divider> -->
              <div
                v-for="(subject, index) in subjects"
                :key="index"
                class="subject-item"
              >
                <div
                  v-if="$route.query.subjectId != index"
                  @click="changeSubject(subject.indexing, subject.name)"
                  class="container90 cursor"
                >
                  <span class="text newShadeBlack--text">{{
                    subject?.name
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <v-card class="d-flex overflow-hidden flex-column rounded-xxl">
          <v-card
            class="coursecardcolor d-flex flex-row justify-space-between align-center w-100 h100 custom-elevation"
          >
            <div class="section-1 pl-8 mb-2">
              <img
                :src="selectedSubject.icon"
                class="ml-5 mt-5"
                height="156"
                width="156"
              />
            </div>

            <div class="section-2 pl-12">
              <div class="coursename text-subtitle-1">
                {{ this.newOurseData?.courseName }}
              </div>
              <div class="text-h4 pdpcolor">
                {{ this.selectedSubject?.name }}
              </div>
              <div class="d-flex flex-row mt-3">
                <div class="modBoxx pdpcolor">
                  <img class="mr-3" src="../assets/hellooo2.svg" alt="" />
                  <span class="text-subtitle-2">
                    {{ this.selectedSubject?.unitsCount }} Units</span
                  >
                </div>
                <div class="modBoxx pdpcolor">
                  <img class="mr-3" src="../assets/clocking.svg" alt="" />
                  <span class="text-subtitle-2">{{
                    formatTime(this.selectedSubject?.subjectDuration)
                  }}</span>
                </div>
                <div class="modBoxx pdpcolor">
                  <img class="mr-3" src="../assets/testingScreen.svg" alt="" />
                  <span class="text-subtitle-2">
                    {{ this.selectedSubject?.subtopicCount }} Tests</span
                  >
                </div>
              </div>
              <v-btn
                  height="48px"
                  width="200px"
             
                  class="rounded-xl mt-4 cta-color  text-subtitle-2"
                  @click="startCourse"
                >
                START YOUR JOURNEY
                </v-btn>
              <!-- <div
                class="d-flex mt-4 flex-column justify-center text-center align-center"
                style="position: relative; height: 48px; width: 200px"
              >
                <span
                  @click="startCourse"
                  class="text-subtitle-1 cursor pdpcolor"
                  style="position: absolute !important; z-index: 100"
                >
                  START YOUR JOURNEY</span
                >
                <v-btn
                  height="48px"
                  width="200px"
                  style="
                    opacity: 0.6 !important;
                    position: absolute !important;
                    top: 0px;
                  "
                  class="rounded-xl onColor-B text-subtitle-2"
                  @click="startCourse"
                >
                </v-btn>
              </div> -->
            </div>
          </v-card>

          <div
            class="tabs-container shadingColor d-flex flex-row justify-end align-center"
          >
            <div
              class="tabbing d-flex align-center pdpcolor text-subtitle-1"
              @click="showComponent('curriculum')"
              :class="{ 'active-tab': activeTab === 'curriculum' }"
            >
              Curriculum Index
            </div>
            <div
              class="tabbing d-flex align-center pdpcolor text-subtitle-1"
              @click="showComponent('courseDescription')"
              :class="{ 'active-tab': activeTab === 'courseDescription' }"
            >
              Description
            </div>
            <div
              class="tabbing d-flex align-center pdpcolor text-subtitle-1"
              @click="showComponent('practiceTest')"
              :class="{ 'active-tab': activeTab === 'practiceTest' }"
            >
              Reviews
            </div>
          </div>
        </v-card>
        <div
          style="
            height: calc(100vh - 23rem);
            overflow-y: scroll;
            overflow-x: hidden;
            scrollbar-width: none;
          "
          class="mt-5"
        >
          <!-- Conditional rendering based on selected section -->
          <Curriculum
            v-if="activeTab === 'curriculum'"
            :selectedSubject="selectedSubject"
          />
          <CourseDescription
            :CourseDescription="selectedSubject.description"
            v-if="activeTab === 'courseDescription'"
          />
          <PracticeTest v-if="activeTab === 'practiceTest'" />
        </div>
      </v-card>
    </div>
  </div>
</template>
<script>
import Curriculum from "@/components/CurriculumView.vue";
import CourseDescription from "@/components/CourseDescription.vue";
import PracticeTest from "@/components/PracticeTest.vue";
import { myMixin } from "../myMixin.js";
export default {
  name: "ProductDetail",
  mixins: [myMixin],
  components: {
    Curriculum,
    CourseDescription,
    PracticeTest,
  },
  data() {
    return {
      activeTab: "",
      isLoading: true,
      selectedSubject: {},
      subjectId: "",
      newOurseData: {},
      selectedSubjct: "",
      dropdownOpen: false,
      dropDownIconClicked: false,
      subjects: [],
    };
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  watch: {
    "$route.query": function routerwatch() {
      this.getSubjectColor();
    },
  },
  methods: {
    showComponent(section) {
      this.activeTab = section;
    },
    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen; // Toggle dropdown state
      this.dropDownIconClicked = true;
      if (this.dropdownOpen) {
        document.addEventListener("click", this.handleClickOutside);
      } else {
        document.removeEventListener("click", this.handleClickOutside);
      }
    },
    startCourse() {
      this.$router.push({
        path: "/course",
        query: {
          subjectId: this.subjectId,
          subject: this.$route.query.subject, // Pass subjectId as a parameter to the '/course' route
        },
      });
    },
    home() {
      this.$router.push({
        name: "homeView",
      });
    },

    async getCourseData() {
      try {
        const courseData = await this.$store.dispatch("getCourseData");
        this.newOurseData = courseData[0];
        this.selectedSubject =
          courseData[0].subjects[this.$route.query.subjectId];
        this.selectedSubjct =
          courseData[0].subjects[this.$route.query.subjectId].name;
        // console.log("djbc", this.selectedSubjct);
      } catch (error) {
        console.error("An error occurred while fetching course data:", error);
      } finally {
        this.isLoading = false;
        // console.log("Course data retrieval attempt completed.");
      }
    },
    handleClickOutside(event) {
      if (
        this.$refs.dropdown &&
        !this.$refs.dropdown.contains(event.target) &&
        this.dropDownIconClicked === false
      ) {
        this.dropdownOpen = false;
        document.removeEventListener("click", this.handleClickOutside);
      }
      this.dropDownIconClicked = false;
    },

    async getSubjectColor() {
      try {
        var path = this.$route.path;
        if (path.includes("/courseDetail")) {
          var subjects = this.$store.state.courseData[0].subjects;
          var secondaryColor =
            subjects[this.$route.query.subjectId]?.primaryColor || "#5a4efa";
          var primaryColor =
            subjects[this.$route.query.subjectId]?.secondaryColor || "#dcd9ff";
          var onColor =
            subjects[this.$route.query.subjectId]?.onColor || "black";
            var ctaColor =
            subjects[this.$route.query.subjectId].ctaColor || '#5a4efa';

          document.documentElement.style.setProperty(
            "--subject-primary",
            primaryColor
          );
          document.documentElement.style.setProperty(
            "--subject-secondary",
            secondaryColor
          );
          document.documentElement.style.setProperty(
            "--subject-onColor",
            onColor
          );
          document.documentElement.style.setProperty(
            "--subject-cta",
            ctaColor
          );
        } else {
          document.documentElement.style.setProperty(
            "--subject-primary",
            "#5a4efa"
          );
          document.documentElement.style.setProperty(
            "--subject-secondary",
            "#dcd9ff"
          );
          document.documentElement.style.setProperty(
            "--subject-onColor",
            "black"
          );
        }
      } catch (error) {
        console.log(error);
      }
    },

    changeSubject(index, subject) {
      this.$router.push({
        path: "/courseDetail",
        query: {
          subjectId: index,
          subject: subject, // Pass subjectId as a parameter to the '/course' route
        },
      });
      this.selectedSubject =
        this.$store?.state?.courseData[0]?.subjects[
          this.$route.query.subjectId
        ];
      this.dropdownOpen = false;
    },

    async getSubjectData() {
      const courseData = await this.$store.dispatch("getCourseData");
      this.subjects = courseData[0].subjects;
      this.getSubjectColor();
    },
  },
  mounted() {
    this.getSubjectData();
    document.addEventListener("click", this.handleClickOutside);
    this.getCourseData();
    this.showComponent("curriculum");
    const subjectId = this.$route.query.subjectId;
    this.subjectId = subjectId;
  },
};
</script>
<style scoped>
.v-sheet.v-card {
  border-radius: unset;
}
.dropdown-container {
  position: relative;
  display: inline-block;
  width: 250px; /* Adjust width as needed */
}
.dropdown-contentt {
  width: 60%;

  background-color: #ffffff;
  color: black;
  /* padding: 10px; */
  border: 1px solid #ddd;
  border-radius: 4px;
  position: absolute;
  top: 100%; /* Position below the button */
  left: 5%;
  z-index: 1000;
  border-radius: 10px;
}

/* .subject-item {
  padding: 2px;
  cursor: pointer;
} */
.subject-item .container90 .text {
  display: inline-block;
  width: 100%; /* Ensure it takes full width */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

/* .subject-item:hover {
  background-color: #f0f0f0;
} */
</style>