<template>
  <div class="h-100">
    <v-card class="mx-auto h-100 pa-3">
      <v-card-title class="headline text-h5 font-weight-medium"
        ><v-icon @click="goBack" class="mr-2 ml-n3" color="black"
          >mdi-keyboard-backspace</v-icon
        >Your Test Report</v-card-title
      >
      <v-card-subtitle class="subtitle mb-0 ml-n3">
        Your Test Report summarizes your performance, highlighting key strengths
        and areas for improvement, along with detailed feedback and scores.
      </v-card-subtitle>

      <v-card
        outlined
        style="background-color: var(--subject-primary)"
        class="rounded-xl py-3 px-5 d-flex flex-row justify-center align-center"
      >
        <div style="width: 70%">
          <v-row>
            <v-col cols="12" sm="4" md="4" align-self="center">
              <div class="summary-box d-flex flex-column justify-space-between">
                <div class="text-center text-body-1">Score</div>

                <div class="summary-text font-weight-regular">
                  <v-avatar size="40" color="#A8DADC">
                    <v-icon size="22" color="black">mdi-trophy-outline</v-icon>
                  </v-avatar>
                  {{ reportData?.currentAnswer }}/{{
                    reportData?.totalQuestion
                  }}
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="4" md="4" align-self="center">
              <div class="summary-box d-flex flex-column justify-space-between">
                <div class="text-body-1 text-center">
                  Total Number Of Questions
                </div>
                <div class="summary-text font-weight-regular">
                  {{ reportData?.totalQuestion }}
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="4" md="4" align-self="center">
              <div class="summary-box d-flex flex-column justify-space-between">
                <div class="font-weight-regular text-body-1 text-center">
                  Attempted & Un-attempted
                </div>
                <div class="d-flex flex-row justify-start">
                  <div class="d-flex flex-column">
                    <div class="text-start text-body-2 text--secondary">
                      <v-icon color="green">mdi-circle-medium</v-icon>
                      Attempted:
                      {{ reportData?.currentAnswer + reportData?.WrongAnswer }}
                      Ques
                    </div>
                    <div class="text-start text-body-2 text--secondary">
                      <v-icon color="blue">mdi-circle-medium</v-icon>
                      Un-Attempted: {{ reportData?.skippedQuestions }} Ques
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
        <div style="width: 30%" class="pl-5">
          <v-row>
            <v-col cols="12" sm="12" md="12" align-self="center">
              <div class="summary-box d-flex flex-column justify-space-between">
                <div class="font-weight-regular text-body-1 text-center">
                  Correct, Incorrect & Skipped Questions
                </div>
                <div class="d-flex flex-row justify-space-between w-100">
                  <div class="d-flex flex-row">
                    <v-avatar size="40" color="#CCFFCC">
                      <v-icon size="28" color="black" class="summary-icon"
                        >mdi-checkbox-marked-circle-outline</v-icon
                      >
                    </v-avatar>
                    <div class="d-flex flex-column pl-2">
                      <div
                        class="text--secondary text-body-2 font-weight-regular"
                      >
                        Correct
                      </div>
                      <div
                        class="text--primary text-body-2 font-weight-regular"
                      >
                        {{ reportData?.currentAnswer }} Ques
                      </div>
                    </div>
                  </div>

                  <div class="d-flex flex-row">
                    <v-avatar size="40" color="#FFBCB2">
                      <v-icon size="28" color="black" class="summary-icon"
                        >mdi-shield-alert-outline</v-icon
                      >
                    </v-avatar>
                    <div class="d-flex flex-column pl-2">
                      <div
                        class="text--secondary text-body-2 font-weight-regular"
                      >
                        Incorrect
                      </div>
                      <div
                        class="text--primary text-body-2 font-weight-regular"
                      >
                        {{ reportData?.WrongAnswer }} Ques
                      </div>
                    </div>
                  </div>

                  <div class="d-flex flex-row">
                    <v-avatar size="40" color="#E0E0E0">
                      <v-icon color="black" size="28" class="summary-icon"
                        >mdi-skip-forward-outline</v-icon
                      >
                    </v-avatar>
                    <div class="d-flex flex-column pl-2">
                      <div
                        class="text--secondary text-body-2 font-weight-regular"
                      >
                        Skipped
                      </div>
                      <div
                        class="text--primary text-body-2 font-weight-regular"
                      >
                        {{ reportData?.skippedQuestions }} Ques
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-card>

      <v-card
        outlined
        class="w-100 rounded-xl mt-4 pa-4 overflow-y"
        style="height: calc(100% - 19.688rem)"
      >
        <div
          v-for="(question, i) in reportData.userResponses"
          :key="i"
          class="d-flex flex-row mt-3"
        >
          <v-card
            width="56"
            height="auto"
            class="elevation-0 rounded-xl rounded-r-0 text-center d-flex justify-center align-center"
            :color="getSideColor(question)"
          >
            Q{{ i + 1 }}
          </v-card>
          <v-card
            outlined
            style="background-color: #00000005"
            class="d-flex pa-2 flex-column w-100 rounded-xl rounded-l-0"
          >
            <div
              v-html="question.questionStatement"
              class="font-weight-medium text-body-1"
            ></div>
            <div v-if="!true" class="d-flex flex-row ml-2 mt-4">
              <div
                v-for="(option, j) in question.questionOptions"
                :key="j"
                class="mr-6"
              >
                {{ option.optionKey }} )
                <span
                  v-if="isFirebaseLink(option.optionValue)"
                  @click="openDialog(option.optionValue)"
                  class="preview-text cursor"
                >
                  Preview Photo
                </span>
                <span v-else>{{ option.optionValue }}</span>
              </div>
            </div>

            <div v-else class="d-flex flex-column mt-2 ml-4">
              <div v-for="(option, j) in question.questionOptions" :key="j">
                {{ option.optionKey }} )
                <span
                  v-if="isFirebaseLink(option.optionValue)"
                  @click="openDialog(option.optionValue)"
                  class="preview-text cursor"
                >
                  Preview Photo
                </span>
                <span v-else>{{ option.optionValue }}</span
                ><span v-if="option?.optionKey == question?.myAnswer">
                  <v-icon
                    v-if="question.correctAnswer == question?.myAnswer"
                    color="green"
                    >mdi-check-bold</v-icon
                  >
                  <v-icon v-else color="red">mdi-close</v-icon>
                </span>
              </div>
            </div>
            <v-divider class="mt-4"></v-divider>
            <div class="d-flex flex-row py-1">
              <span class="mr-6">
                Correct Answer:
                <span class="green--text">
                  Option {{ question.correctAnswer }}</span
                ></span
              >
              <span class="mr-6"> Score: <b>01</b></span>
            </div>
            <div>
              <span class="mr-6">
                Answer Description:
                <!-- Check if the answerExplanation contains a Firebase image URL -->
                <span v-if="isFirebaseLink(question.answerExplanation)">
                  <a
                    @click="showImage(question.answerExplanation)"
                    class="view-photo cursor"
                  >
                    Click here to see the answer explanation
                  </a>
                </span>
                <span v-else>
                  {{ question.answerExplanation }}
                </span>
              </span>
            </div>

            <!-- <div>
              <span class="mr-6"
                >Answer Description: {{ question.answerExplanation }}</span
              >
            </div> -->
          </v-card>
        </div>

        <!-- Dialog for displaying the image -->
        <!-- <v-dialog v-model="dialoging" max-width="600px">
    <v-card>
      <v-img :src="dialogImage" max-height="400px" contain></v-img>
      <v-card-actions>
        <v-btn color="primary" text @click="closeDialog">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog> -->

        <v-dialog v-model="dialoging" max-width="500px">
          <v-card>
            <v-card-title class="headline">Image Preview</v-card-title>
            <v-card-text>
              <img
                :src="dialogImage"
                alt="Enlarged Option Image"
                width="100%"
              />
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="var(--subject-onColor)"
                text
                @click="dialoging = false"
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="imageDialogVisible" max-width="800px">
          <v-card>
            <v-card-title class="headline">Answer Explanation</v-card-title>
            <v-card-text>
              <img
                :src="imageUrlToShow"
                alt="Enlarged Option Image"
                width="100%"
              />
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="var(--subject-onColor)"
                text
                @click="imageDialogVisible = false"
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>

      <div class="buttons justify-end">
        <v-btn
          class="mustardd onColor-T rounded-lg mr-6 white elevation-0 button mr-3"
          @click="goToMenu"
          style="border: 1px solid var(--subject-onColor) !important"
        >
          Go to Menu
        </v-btn>
        <v-btn
          @click="goToNext"
          class="mustardd rounded-lg white--text elevation-0 button"
        >
          GO TO NEXT SUB TOPIC
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ["reportData"],
  data() {
    return {
      questions: [
        {
          text: "What is the sum of 5 and 7?",
          options: ["a) 13", "b) 11", "c) 10", "d) 12"],
          isCorrect: true,
          timeTaken: 8,
        },
        {
          text: "What is the sum of 5 and 7?",
          options: ["a) 13", "b) 11", "c) 10", "d) 12"],
          isCorrect: false,
          timeTaken: 8,
        },
        {
          text: "What is the sum of 5 and 7?",
          options: ["a) Mercury", "b) Saturn", "c) Earth", "d) Jupiter"],
          isCorrect: true,
          timeTaken: 8,
        },
      ],
      dialoging: false,
      dialogImage: "",
      imageDialogVisible: false,
      imageUrlToShow: ""
    };
  },
  methods: {
    goToMenu() {
      this.$emit("goTotopic");
    },
    isFirebaseLink(optionValue) {
      return optionValue.match(/\.(jpeg|jpg|gif|png|svg)$/) != null;
    },
    // Open the dialog and set the image source
    openDialog(imageUrl) {
      this.dialogImage = imageUrl;
      this.dialoging = true;
    },
    showImage(imageUrl){
      this.imageUrlToShow = imageUrl;
      this.imageDialogVisible = true;
    },
    // Close the dialog
    closeDialog() {
      this.dialoging = false;
    },
    goToNext() {
      this.$emit("goNext");
    },
    getSideColor(question) {
      if (question?.myAnswer == question?.correctAnswer) return "#CCFFCC";
      else if (question?.myAnswer?.length == 0) return "#E0E0E0";
      else return "#FFD9D9";
    },
    goBack() {
      this.$emit("close-dialog");
    },

    goToNextSubTopic() {
      // Handle navigation to the next sub topic
    },
  },
};
</script>

<style scoped>
.pa-3 {
  padding: 16px;
}

.summary-box {
  height: 114px;
  min-height: 114px;
  text-align: center;
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.summary-icon {
  font-size: 2rem;
  color: #1976d2;
}

.summary-text {
  font-size: 1.5rem;
  font-weight: bold;
}

.subtitle {
  margin-bottom: 20px;
}

.question-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
}

.question-title {
  font-weight: bold;
  margin-bottom: 8px;
}

.question-options {
  list-style: none;
  padding: 0;
  margin: 0 0 8px 0;
}

.question-options li {
  padding: 4px 0;
}

.question-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttons {
  display: flex;

  margin-top: 16px;
}
</style>