<template>
  <div v-if="isDataAvailable" class="donut-chart-container">
    <div class="chart-wrapper">
      <GChart
        class="chart"
        type="PieChart"
        :data="chartData"
        :options="chartOptions"
      />
      <div class="center-text">{{correct+'/'+ total }}</div> <!-- Add center text here -->
    </div>
  </div>
</template>

<script>
import { GChart } from "vue-google-charts/legacy";

export default {
  name: "DonutProgressVue",
  props: ["correct", "incorrect", "skipped",'total'],
  components: {
    GChart,
  },
  data() {
    return {
      chartData: [["Status", "Value"]], // Default grey chart data
      chartOptions: {
        colors: ["#009051", "#FF1744", "#EEEEEE"], // Colors for different statuses
        legend: {
          position: "none",
        },
        chartArea: {
          left: "4%",
          right: 0,
          height: "80%",
          width: "100%",
        },
        pieHole: 0.6, // Making it a donut chart
        backgroundColor: "transparent",
        pieSliceBorderColor: "none", // Remove the outline from the slices
        pieSliceText: "none", // Remove the text from the middle of the donut chart
      },
    };
  },
  created() {
    this.getHomeMenu();
  },
  computed: {
    isDataAvailable() {
      return (
        this.correct !== undefined &&
        this.incorrect !== undefined &&
        this.skipped !== undefined
      );
    },
  },
  methods: {
    async getHomeMenu() {
      try {
        const chartData = [
          ["Correct", this.correct],
          ["Incorrect", this.incorrect],
          ["Skipped", this.skipped],
        ];
        this.chartData = [["Status", "Value"], ...chartData];
      } catch (error) {
        console.error("Error fetching home data:", error);
      }
    },
  },
};
</script>

<style scoped>
.donut-chart-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; /* Ensure positioning context for center text */
}

.chart-wrapper {
  position: relative; /* Ensure positioning context for center text */
  width: 100%;
  height: 100%;
}

.chart {
  width: 100%;
  height: 100%;
}

.center-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-weight: bold;
  color: #000; /* Adjust color as needed */
  text-align: center; /* Ensure text is centered */
  pointer-events: none; /* Ensure text does not interfere with chart interactions */
}
</style>
