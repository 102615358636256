<template>
  <div class="sub-unit-screen pb-0 mt-0">
    <div>
      <div v-if="questionsDataLength == 0" class="loader-container">
        <img src="../assets/loader-eazily.gif" alt="Loading..." class="loader-img" />
      </div>
      <div class="text-subtitle-1 content-name ml-3 mb-2 cursor">
        <v-icon @click="goToRoute('homeView', {})" class="unitss" size="20">mdi-home</v-icon>

        <div class="font-weight-medium">
          > <span class="unitss mr-1">{{ subject }}</span> >
          <span class="unitss mr-1" @click="
        goToRoute('NoUnitSelected', {
          subjectId: $route.query?.subjectId,
          subject: $route.query?.subject,
        })
        ">Units</span>
          > <span class="unitss ml-1 mr-1">Chapters</span> >
          <span class="unitss ml-1 mr-1" @click="goToRoute('Topics', $route.query)">Topics</span>
          >
          <span class="unitss ml-1 mr-1" @click="goToRoute('SubTopics', $route.query)">Sub Topics</span>
          >
          <span class="topicss">Test</span>
        </div>
      </div>

      <PolygonStepperViewVue />

      <div class="ml-2 pl-2 mb-6 d-flex">
        <div class="text-h5 font-weight-medium">
          Test - Sets and their Representation
        </div>
        <div class="image-containert mt-1 cursor mr-5" @click="toggleFullScreen">
          <img src="../assets/fullScreen.svg" />
        </div>
      </div>

      <div class="upper-divs" :class="{ 'full-screen': isFullScreen }">
        <div v-if="isFullScreen" class="mt-5">
          <img class="logo-test-screen" src="../assets/doo.png" alt="Logo" />
          <div class="exit-fullscreen-btn cursor" @click="exitFullScreen">
            <img src="../assets/exitFUllScreen.svg" />
          </div>
        </div>

        <!-- <button class="exit-fullscreen-btn" @click="exitFullScreen">Exit Full Screen</button> -->
        <div class="w-40 d-flex justify-center mb-4 step-progress-style">
          <StepProgressBarUnlock class="step-progress" :selectedQuestion="selectedQuestion" />
        </div>

        <div v-if="questions.length > 0" class="test-instructions">
          <button class="triangle-btn backButton-test-screen" v-if="selectedQuestion > 0"
            @click="selectedQuestion--"></button>

          <div class="text-h5 heelo font-weight-medium">
            Question {{ this.selectedQuestion + 1 }}
          </div>

          <div v-html="questions[this.selectedQuestion]?.questionStatement"
            class="text-subtitle-1 heelo ma-4 questionStatement"></div>

          <!-- Display image or video if available -->
          <div v-if="questions[this.selectedQuestion]?.questionAssetUrl !== 'NA'" class="ma-4">
            <template v-if="isImageLoaded">
              <img :src="questions[this.selectedQuestion]?.questionAssetUrl" alt="Question Image" width="100%"
                height="auto" />
            </template>
            <template v-else>
              <video controls width="100%" height="auto">
                <source :src="questions[this.selectedQuestion]?.questionAssetUrl" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </template>
          </div>

          <div class="option-container">
            <v-radio-group hide-details v-model="questions[selectedQuestion].myAnswer" row>
              <div v-for="(option, index) in questions[this.selectedQuestion]
        .questionOptions" :key="index" class="abc-1 d-flex align-center mb-4 px-4">
                <v-tooltip top :content="option.optionValue">
                  <template v-slot:activator="{ on, attrs }">
                    <v-radio color="var(--subject-onColor)" v-bind="attrs" :value="questions[selectedQuestion].questionOptions[index].optionKey,
                      " class="" @click="setOption(option.optionKey)"
                      v-on="shouldApplyHandlers(option.optionValue) ? on : {}">
                      <template v-slot:label>

                        <div v-if="shouldApplyHandlers(option.optionValue)">{{ truncateText(option.optionValue, 80) }}
                        </div>
                        <div v-else class="questionStatement" v-html="option.optionValue"></div>

                      </template>


                    </v-radio>
                  </template>
                  <div class="font-14 tooltip-content">
                    {{ option.optionValue }}
                  </div>
                </v-tooltip>

              </div>
            </v-radio-group>
          </div>

          <div class="containery mb-1">
            <v-btn v-if="selectedQuestion < questions.length - 1" @click="selectedQuestion++" class="color-selected"
              :class="{
        'color-selected':
          questions[selectedQuestion].myAnswer.length > 0,
      }" style="margin-bottom: 15px; border-radius: 10px" height="48px" width="230px">
              NEXT
            </v-btn>
            <v-btn v-else @click="testpage" :class="{ 'color-selected': row1 != null }" class="color-selected"
              style="margin-bottom: 15px; border-radius: 10px" height="48px" width="230px">
              Submit
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PolygonStepperViewVue from "./PolygonStepperView.vue";
import StepProgressBarUnlock from "./StepProgressBarUnlock.vue";
import CourseConsumptionController from "@/controllers/CourseConsumptionController";
import { myMixin } from "../myMixin.js";

export default {
  name: "UnitView",
  mixins: [myMixin],

  components: {
    PolygonStepperViewVue,
    StepProgressBarUnlock,
  },

  data() {
    return {
      selectedQuestion: 0,
      questionsDataLength: 0,
      questions: [],
      row1: null,
      row2: null,
      isFullScreen: false,
      subject: "",
      isImageLoaded: false,
    };
  },
  watch: {
    selectedQuestion() {
      this.checkIfImageLoaded();
    },
  },

  methods: {
    setOption(value) {
      this.questions[this.selectedQuestion].myAnswer = value;
    },
    shouldApplyHandlers(optionValue) {
      return !optionValue.includes('<p>');
    },

    truncateText(text, limit) {
      if (text.length > limit) {
        return text.substring(0, limit) + "...";
      }
      return text;
    },
    checkIfImageLoaded() {
      // console.log("rfbfrj");
      const img = new Image();
      // console.log("chk1:",img);
      img.src = this.questions[this.selectedQuestion]?.questionAssetUrl;
      img.onload = () => {
        this.isImageLoaded = true;
      };
      img.onerror = () => {
        this.isImageLoaded = false;
      };
    },

    async getSubTopicData() {
      try {
        var payload = this.getRouterQuery();
        const response = await CourseConsumptionController.getTopicQuestions(
          payload
        );
        // console.log("response data", response);
        if (response.status === 200) {
          this.questions = response?.data?.data;
          const questionsDataLength = Object.keys(this.questions).length;
          this.questionsDataLength = questionsDataLength;
          this.questions.forEach((obj) => {
            obj.myAnswer = [];
          });
          // console.log("##############", this.questions);

          this.DataLoading = false;
        }
      } catch (error) {
        // Handle the error
        console.error("Error fetching sub-topic questions:", error);
      }
    },

    exitFullScreen() {
      this.isFullScreen = false;
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    },

    async testpage() {
      var payload = this.getRouterQuery();
      // this.$router.push("/post-test-report");
      var payload2 = { ...payload, userResponses: this.questions };
      const responce =
        await CourseConsumptionController.submitSubTopicTestQuestion(payload2);
      if (responce.status == 200) {
        var pastQuery = this.$route.query;
        this.updateCompleteStatus("test");
        this.$router.push({
          name: "PostTestReportShowView",
          query: pastQuery,
        });
      } else {
        alert("Something went wrong");
      }
    },
    toggleFullScreen() {
      // Toggle full-screen mode
      this.isFullScreen = !this.isFullScreen;

      // If entering full-screen mode, request full-screen
      if (this.isFullScreen) {
        const element = document.querySelector(".upper-divs");
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        }
      } else {
        // If exiting full-screen mode, exit full-screen
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
    },
  },
  mounted() {
    this.subject = this.$route.query.subject;
    this.getSubTopicData();
  },
};
</script>
<style scoped>
.theme--light.v-btn.v-btn--has-bg {
  background-color: #9e9e9e;
}

.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 4px;
}

.abc-1 {
  height: 58px;
  width: 390px;
  background-color: #d5d5d5bf;
  align-content: center;
  border-radius: 16px;
  margin-left: 40px;
}

.upper-divs {
  /* background-color: #00e668; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.option {
  width: 390px;
  height: 58px;
  background-color: #606060;
  margin-bottom: 10px;
  /* Add some spacing between options if needed */
}

.step-progress {
  position: inherit !important;
  width: 80%;
}

.heelo {
  text-align: center;
  margin-top: 15px;
}

.step-progress-style {
  position: relative;
  /* Change to relative position */
  left: 51%;
  /* Center horizontally */
  transform: translateX(-50%);
  /* Center horizontally */
}

.button-container {
  display: flex;
}

.test-instructions {
  position: relative;
  width: 90%;
  /* Adjust the width as needed for responsiveness */
  max-width: 900px;
  /* Limit the maximum width to 900px */
  border-radius: 21px;
  box-shadow: 0px 1px 4px 0px #666666;
  margin: 20px auto;
}
</style>