<template>
  <div
    id="myScroll"
    class="background-color1 d-flex flex-column w-100 vh-100 align-center"
  >
    <!-- <div class="w-100">
      <img src="../assets/Png.png" class="pl-4 logMax-width" />
    </div> -->
    <div class="d-flex justify-space-between align-items-center w-100 p-2">
      <img src="../assets/Png.png" class="pl-4 logMax-width" />
      <v-btn icon @click="logout">
        <img src="../assets/Button.svg" class="logout-top" />
      </v-btn>
    </div>
    <div class="personal-details-page text-h4 pa-0 ml-5 mb-5">
      Personal Details
    </div>
    <div
      class="w-90 d-flex flex-row ma-0 pa-0 main-class-heading1 with-scrollbar-1"
    >
      <v-form lazy-validation ref="profile" class="corDetFirDivv-first">
        <v-row class="">
          <v-col cols="12" class="text-center overflow-hidden">
            <label for="file-input" class="avatar-label-1">
              <v-avatar size="120">
                <v-img v-if="avatar" :src="avatar" alt="User Image"></v-img>
                <!-- <v-icon v-else>mdi-account-circle</v-icon> -->
                <img v-else src="../assets/personcircle.png" />
              </v-avatar>
            </label>

            <input
              type="file"
              id="file-input"
              accept="image/*"
              style="display: none"
              @change="handleImageUpload"
            />
            <div v-if="!avatar" class="edit-button-1 relative">
              <input
                type="file"
                id="file-input"
                accept="image/*"
                class="w-100"
                style="opacity: 0; position: absolute; top: 0"
                @change="handleImageUpload"
              />
              Upload Image
            </div>
            <div v-if="avatar" class="edit-button-1 relative">
              <input
                type="file"
                id="file-input"
                accept="image/*"
                class="w-100"
                style="opacity: 0; position: absolute; top: 0"
                @change="handleImageUpload"
              />
              Edit
            </div>
          </v-col>
        </v-row>
        <v-row class="ma-0 pa-0">
          <v-col cols="4">
            <!-- <div class="all-text-class text-subtitle-2 mb-2">FIRST NAME *</div> -->
            <v-text-field
              v-model="personalInfo.firstName"
              label="First Name *"
              outlined
              hide-details
              :rules="[
                (v) => !!v || 'First Name is required',
                (v) =>
                  /^[a-zA-Z ]{3,25}$/.test(v) ||
                  'This value must contain alphabets',
              ]"
              required
            ></v-text-field>
            <!-- <v-text-field
              v-model="personalInfo.firstName"
              hide-details
              prepend-inner-icon="mdi-account"
              placeholder="First Name *"
              rounded
              class="rounded-xl elevation-0"
              :rules="[
                (v) => !!v || 'First Name is required',
                (v) =>
                  /^[a-zA-Z ]{3,25}$/.test(v) ||
                  'This value must contain alphabets',
              ]"
              required
              outlined
            ></v-text-field> -->
          </v-col>
          <v-col cols="4">
            <!-- <div class="all-text-class text-subtitle-2 mb-2">MIDDLE NAME</div> -->
            <v-text-field
              v-model="personalInfo.middleName"
              label="Middle Name"
              outlined
              hide-details
            ></v-text-field>
            <!-- <v-text-field
              v-model="personalInfo.middleName"
              hide-details
              prepend-inner-icon="mdi-account"
              placeholder="Middle Name"
              rounded
              class="rounded-xl elevation-0"
              outlined
            ></v-text-field> -->
          </v-col>
          <v-col cols="4">
            <!-- <div class="all-text-class text-subtitle-2 mb-2">LAST NAME *</div> -->
            <v-text-field
              v-model="personalInfo.lastName"
              label="Last Name *"
              outlined
              hide-details
              :rules="[
                (v) => !!v || 'Last Name is required',
                (v) =>
                  /^[a-zA-Z ]{3,25}$/.test(v) ||
                  'This value must contain alphabets',
              ]"
              required
            ></v-text-field>
            <!-- <v-text-field
              v-model="personalInfo.lastName"
              hide-details
              prepend-inner-icon="mdi-account"
              placeholder="Last Name *"
              rounded
              class="rounded-xl elevation-0"
              :rules="[
                (v) => !!v || 'Last Name is required',
                (v) =>
                  /^[a-zA-Z ]{3,25}$/.test(v) ||
                  'This value must contain alphabets',
              ]"
              required
              outlined
            ></v-text-field> -->
          </v-col>
        </v-row>

        <v-row class="ma-0 pa-0">
          <v-col cols="6">
            <!-- <div class="all-text-class text-subtitle-2 mb-2">GENDER *</div> -->
            <v-select
              v-model="personalInfo.gender"
              :items="['Male', 'Female', 'Other']"
              class="c-select"
              label="Gender*"
              solo
              hide-details
              :rules="[(v) => !!v || 'Gender is required']"
              required
              outlined
            ></v-select>
          </v-col>
          <v-col cols="6">
            <!-- <div class="all-text-class text-subtitle-2 mb-2">DATE OF BIRTH</div> -->

            <v-text-field
              v-model="personalInfo.dob"
              solo
              hide-details
              label="Date of Birth (DDMMYY)*"
              type="date"
              class="c-select"
              required
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="ma-0 pa-0">
          <v-col cols="6">
            <!-- <div class="all-text-class text-subtitle-2 mb-2">EMAIL *</div> -->
            <v-form v-model="emailBool" class="bf-text-field">
              <v-text-field
                v-model="personalInfo.email"
                placeholder="Email Address*"
                hide-details
                class="verify elevation-0"
                :rules="emailRules"
                required
                outlined
                :disabled="userInfo?.personalInfo?.isEmailVerified"
                @keydown.enter.prevent="submit"
                @input="isAuthenticationExistForEmail"
              >
                <template #append>
                  <div class="d-flex align-center mt-0">
                    <v-btn
                      on
                      text
                      :disabled="!emailBool"
                      class="mr-n3 mt-n1"
                      v-if="!userInfo?.personalInfo?.isEmailVerified"
                      @click="generateOtp(), (otpDialog = true)"
                      style="color: rgb(0, 0, 0, 0.6) !important"
                    >
                      Verify
                    </v-btn>
                    <!-- text-unset -->

                    <v-row v-if="userInfo?.personalInfo?.isEmailVerified">
                      <v-col class="px-2">
                        <v-img
                          src="../assets/verifiedIcon.png"
                          contain
                          max-width="24"
                        ></v-img>
                      </v-col>
                      <!-- <v-col class="px-0"> </v-col> -->
                    </v-row>
                  </div>
                </template>
              </v-text-field>
              <!-- <div
                class="pt-0 mt-0 mb-5 red--text pl-1"
                v-if="emailBool && !validEmail && !userInfo?.personalInfo?.isEmailVerified"
              >
                Email Id Already Exists
              </div> -->
            </v-form>
          </v-col>
          <v-col cols="6">
            <!-- <div class="all-text-class text-subtitle-2 mb-2">
              PHONE NUMBER *
            </div> -->

            <v-text-field
              v-model="personalInfo.phoneNumber"
              prepend-inner-icon="mdi-phone"
              placeholder="Your 10-digit mobile no.*"
              hide-details
              @input="isAuthenticationExistForPhoneNumber"
              class="verify elevation-0"
              @keypress="isNumber($event)"
              :counter="10"
              prefix="+91"
              maxLength="10"
              :type="
                !userInfo?.personalInfo?.isPhoneVerified ? 'integer' : 'text'
              "
              :rules="[
                (v) => !!v || 'Mobile number is required',
                (v) =>
                  (v && v.length >= 10 && v.length <= 10) ||
                  'Mobile number must be 10 digit',
              ]"
              required
              outlined
              :disabled="userInfo?.personalInfo?.isPhoneVerified"
            >
              <template #append>
                <!-- :disabled="
                                personalInfo.contactInfo.phoneNumber
                                  .length != 10 || validPhone == false
                              " -->
                <div class="d-flex align-center mt-0">
                  <v-btn
                    on
                    text
                    max-height="35"
                    class="mt-n1 mr-n3"
                    v-if="
                      !userInfo?.personalInfo?.isPhoneVerified && usingPhone
                    "
                    @click="generatePhoneOtp()"
                    style="color: rgb(0, 0, 0, 0.6) !important"
                  >
                    verify
                  </v-btn>

                  <v-row v-if="userInfo?.personalInfo?.isPhoneVerified">
                    <v-col class="px-2">
                      <v-img
                        src="../assets/verifiedIcon.png"
                        contain
                        max-width="24"
                      ></v-img>
                    </v-col>

                    <!-- <v-col class="px-0"> </v-col> -->
                  </v-row>
                </div>
              </template>
            </v-text-field>
            <!-- <div class="pt-0 mt-0 mb-5 red--text pl-1" v-if="false">
              Phone Number Already Exists
            </div> -->
          </v-col>
        </v-row>

        <v-row class="ma-0 pa-0">
          <v-col cols="6">
            <!-- <div class="all-text-class text-subtitle-2 mb-2">OBJECTIVE *</div> -->

            <v-select
              :items="objectives"
              class="c-select"
              v-model="objective"
              item-text="name"
              label="Objective*"
              solo
              return-object
              hide-details
              :rules="[(v) => !!v.name || 'Objective is required']"
              required
              @change="getRelateExams"
              outlined
            ></v-select>
          </v-col>

          <v-col cols="6" v-if="this.objective.pathway == 'Exam Preparation'">
            <!-- <div class="all-text-class text-subtitle-2 mb-2">EXAM *</div> -->

            <v-select
              v-model="selectedExam"
              solo
              hide-details
              label="Exam*"
              item-text="name"
              :item-value="
                (e) => {
                  return e;
                }
              "
              class="c-select"
              :items="reletedExams"
              @change="getExam"
              required
              outlined
            >
              <v-divider slot="prepend-item" class="mt-2" />
            </v-select>
          </v-col>

          <v-col cols="6" v-if="this.objective.pathway == 'School Grades'">
            <!-- <div class="all-text-class text-subtitle-2 mb-2">GRADE *</div> -->

            <div v-if="this.objective.pathway == 'School Grades'">
              <v-select
                v-model="selectedGrade"
                solo
                hide-details
                label="Grade*"
                item-text="gradeStream"
                :item-value="
                  (e) => {
                    return e;
                  }
                "
                class="c-select"
                :items="grades"
                @change="getGradeSubjects"
                required
                outlined
              >
                <v-divider slot="prepend-item" class="mt-2" />
              </v-select>
            </div>
          </v-col>
        </v-row>

        <v-row v-if="selectedSubjects.length > 0" class="ma-0 pa-0">
          <v-col cols="5" class="pt-0 pb-0">
            <div class="all-text-class text-subtitle-1 font-weight-bold mt-3">
              Select proficiency level
            </div>
          </v-col>
          <v-col cols="7" class="pt-0 pb-0">
            <!-- This column is intentionally left blank as there is no corresponding input for the heading -->
          </v-col>
        </v-row>

        <v-row
          v-for="(Experties, index) in myExperties"
          :key="index"
          class="mx-1 mt-3"
        >
          <v-col cols="4" class="pt-0">
            <v-text-field
              v-model="Experties.subject.name"
              readonly
              label="Subject Name "
              class="c-select"
              required
              solo
              hide-details
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="8" class="pt-0">
            <v-slider
              v-model="Experties.subject.level"
              :tick-labels="ticksLabels"
              :max="4"
              step="1"
              ticks="always"
              tick-size="4"
              color="#4234df"
              track-color="#BDBDBD"
            ></v-slider>
          </v-col>
        </v-row>
        <div class="pa-5"></div>
        <!-- Conditionally render the submit button -->
      </v-form>
    </div>

    <v-dialog v-model="otpDialog" max-width="450px" class="center">
      <v-card height="100%" elevation="0" class="pb-8">
        <v-card-title class="justify-center text-h5 font-weight-bold">
          {{ usingPhone ? "Verify Your Phone Number" : "Verify Your Email" }}
        </v-card-title>
        <v-card-text class="text-center text-body-1"
          ><span>Enter 6 Digit Code Sent On</span><br /><span>{{
            usingPhone ? "Your Phone Number" : "Your Email Address"
          }}</span></v-card-text
        >

        <div class="d-flex flex-column pl-8 pr-8">
          <v-otp-input length="6" type="number" v-model="otp"></v-otp-input>
          <v-row justify="space-between" class="ma-0 pa-0">
            <v-col class="ma-0 pa-0">
              <v-card-subtitle class="ma-0 pa-0"
                >0{{ Math.floor(time / 60) }}:
                <span v-if="time % 60 < 10">0</span
                >{{ time % 60 }}</v-card-subtitle
              >
            </v-col>
            <v-col class="ma-0 pa-0">
              <v-card-subtitle class="ma-0 pa-0 text-end">
                {{ otp.length }}/6</v-card-subtitle
              >
            </v-col>
          </v-row>
        </div>

        <v-card-text class="text-center">
          <v-btn
            class="primary--text cursor"
            on
            text
            elevation="0"
            :disabled="!resendBool"
            @click="usingPhone ? generatePhoneOtp() : generateOtp()"
          >
            RESEND OTP
          </v-btn>
        </v-card-text>
        <v-card-title class="justify-center">
          <v-btn
            color="secondary"
            class="textcolor--text"
            large
            width="90%"
            height="40"
            @click="validateOTP"
            :disabled="otp.length != 6"
          >
            VERIFY
          </v-btn>
        </v-card-title>
      </v-card>
    </v-dialog>
    <v-row class="row-btn-1 mt-6 mb-2">
      <v-btn
        @click="saveDetails"
        class="submit-class-first secondary white--text"
      >
        <span v-if="isLoading">Loading...</span>
        <span v-else>Submit</span>
      </v-btn>
    </v-row>
  </div>
</template>
<script>
import GovernmentJobController from "@/controllers/GovernmentJobController";
import CompetitiveExamController from "@/controllers/CompetitiveExamController";
import ObjectiveController from "@/controllers/ObjectiveController";
import ExamController from "@/controllers/ExamController";

import UserController from "@/controllers/UserController";
import GradeController from "@/controllers/GradeController";
import AuthService from "@/services/AuthService";
import AcademicController from "@/controllers/AcademicController";

import { storage } from "../firebase";
import {
  getDownloadURL,
  uploadBytes,
  ref as storageRef,
} from "firebase/storage";

export default {
  name: "ProfileCreate",
  data() {
    return {
      selectedGrade: {},
      storageUrl: "",
      levels: ["Novice", "Beginner", "Intermediate", "Advanced", "Expert"],
      ticksLabels: ["Novice", "Beginner", "Intermediate", "Advanced", "Expert"],
      loading: false,
      // isAuthenticationExistForEmail: false,
      // isAuthenticationExistForPhoneNumber: false,
      usingPhone: true,
      date: null,
      objectives: [],
      address: "",
      emailBool: false,
      validEmail: false,
      validPhone: false,
      submitClicked: false,
      saved: false,
      email: "",
      otp: "",
      otpDialog: false,
      resendBool: false,
      isExamSelected: false,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail must be valid",
      ],
      personalInfo: {
        firstName: "",
        middleName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        dob: "",
        gender: "",
      },
      dialog: false,
      time: 119,
      isPhoneVerified: false,
      avatar: null,
      reletedExams: [],
      selectedExam: {},
      selectedSubjects: [],

      objective: "",
      grade: {},
      grades: [],
      myExperties: [],
      isLoading: false,
      userInfo: {
        personalInfo: {
          contactInfo: {
            phoneNumber: "",
            email: "",
          },
          isPhoneVerified: false,
          isEmailVerified: false,
        },
      },
    };
  },

  computed: {
    phoneNumberModel() {
      return this.userInfo?.personalInfo?.isPhoneVerified
        ? this.userInfo?.personalInfo?.contactInfo?.phoneNumber
        : "";
    },
  },

  methods: {
    getGradeSubjects() {
      // console.log("grade subject: " + this.selectedGrade);
      this.selectedSubjects = [];
      this.selectedSubjects = this.selectedGrade.subjects;
      this.myExperties = this.selectedSubjects.map((subject) => ({
        subject,
        level: 0,
      }));

      // console.log("grade subject: " + typeof this.myExperties);
    },

    openDatePicker() {
      this.dialog = true;
    },
    async generatePhoneOtp() {
      this.time = 119;
      this.resendBool = false;
      const response = await AuthService.generateOTP({
        phoneNumber: this.personalInfo.phoneNumber,
      });

      if (response) {
        this.otpDialog = true;
      }

      this.isGenerateOtpClicked = true;
      this.otpTimmer();
    },
    otpTimmer() {
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        if (this.time == 0) {
          clearInterval(this.timer);
          this.resendBool = true;
        } else {
          this.time--;
        }
      }, 1000);
    },
    async isAuthenticationExistForPhoneNumber() {
      try {
        if (this.personalInfo.phoneNumber.length === 10) {
          var response = await AuthService.isAuthenticationExist(
            this.personalInfo?.contactInfo?.phoneNumber
          );
          if (response.status === 200) {
            this.validPhone = await response.data.flag;
          }
        } else {
          this.validPhone = false;
        }
      } catch (error) {
        console.error("Error checking authentication for phone number:", error);
      }
    },

    async validateOTP() {
      var res = null;
      if (!this.usingPhone) {
        res = await AuthService.verifySecondaryContact({
          email: this.personalInfo.email,
          otp: this.otp,
          debug: false,
        });
        this.$mixpanel?.track("VerifyOTP", {
          counter_secs_taken: 45,
          otp_status: "Verified",
          screen_name: "EnterOTPEmailScreen",
        });
        this.personalInfo.isEmailVerified = res.flag;
        this.userInfo.personalInfo.isEmailVerified = res.flag;
        this.otpDialog = false;
      } else {
        res = await AuthService.verifySecondaryContact({
          phoneNumber: this.personalInfo.phoneNumber,
          otp: this.otp,
          debug: false,
          user_id: this.userInfo.id,
        });
        this.$mixpanel?.track("VerifyOTP", {
          counter_secs_taken: 45,
          otp_status: "Verified",
          screen_name: "EnterOTPMobileScreen",
        });
        this.personalInfo.isPhoneVerified = res.flag;
        this.userInfo.personalInfo.isPhoneVerified = res.flag;

        this.otpDialog = false;
      }
    },
    async generateOtp() {
      this.usingPhone = false;
      this.resendBool = false;
      this.time = 119;

      await AuthService.generateOTP({
        email: this.personalInfo.email,
      });
      this.isGenerateOtpClicked = true;
      this.otpTimmer();
    },
    async isAuthenticationExistForEmail() {
      if (this.emailBool == true) {
        var responce = await AuthService.isAuthenticationExistEmail(
          this.personalInfo?.contactInfo?.email
        );
        if (responce?.status == 200) {
          this.validEmail = responce.data?.flag;
        }
      } else [(this.validEmail = false)];
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async handleImageUpload(event) {
      // console.log("mbdcjbd");
      const file = event.target.files[0];

      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.avatar = e.target.result;
        };
        reader.readAsDataURL(file);
        const fileName = `${Date.now()}_${file.name}`;
        const storageReff = storageRef(storage, "personProfilePic/" + fileName);
        await uploadBytes(storageReff, file);
        const picUrl = await getDownloadURL(storageReff);
        this.avatar = picUrl;
        const storageUrl = "personProfilePic/" + fileName;
        this.storageUrl = storageUrl;
      }
    },
    logout(){
      this.$router.push({
        path: "/login",
       
      });
    },

    selectAllExams() {
      this.selectedExam = this.reletedExams.map((obj) => obj.name);
    },
    sortByAlphabet(array, key) {
      array.sort(function (a, b) {
        var nameA = a[key].toUpperCase(); // Convert to uppercase to ensure case-insensitive sorting
        var nameB = b[key].toUpperCase();

        if (nameA < nameB) {
          return -1;
        }

        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
      return array;
    },
    async getObjectives() {
      const response = await ObjectiveController.getobjectives();
      if (response.status == 200) {
        if (response.data.objectives.length > 0) {
          const objs = response.data.objectives;
          this.objectives = this.sortByAlphabet(objs, "name");
        }
        // console.log("printing competitiveExams ..", this.competitiveExams);
      } else {
        this.dataTableLoading = false;

        // alert(response.data.error)
        alert("Something went wrong");
      }
    },

    // getAllSubjects() {
    //   this.selectedSubjects = [];
    //   console.log("selectedExam :-----", this.selectedExam);
    //   var selectedSubjects = this.reletedExams[0].selectedSubject;
    //   console.log("selectedSubjects :-----", this.selectedSubjects);

    //   this.selectedSubjects = selectedSubjects.map((obj) => {
    //     return {
    //       subjectName: obj.name,
    //       icon: obj.icon,
    //       primaryColor: obj.primaryColor || "",
    //       secondaryColor: obj.secondaryColor || "",
    //       onColor: obj.onColor || "black",
    //       subjectDuration: "",
    //       noOfChapters: 0,
    //       noOfTests: 0,
    //     };
    //   });
    //   this.isExamSelected = this.selectedSubjects.length > 0;

    //   const subjects = new Set();
    //   for (const sub of this.selectedSubjects) {
    //     subjects.add(sub);
    //   }
    //   // this.reletedExams
    //   //   .filter((item) => this.selectedExam.includes(item.name))
    //   //   .forEach((item) => {
    //   //     item.selectedSubject.forEach((subject) => {
    //   //       subjects.add(subject);
    //   //     });
    //   //   });

    //   this.myExperties = Array.from(subjects).map((subject) => ({
    //     subject,
    //     level: 0,
    //   }));

    //   console.log("hello myExperties", this.myExperties);

    //   console.log("--------selectedExam", this.selectedSubjects.length);
    // },

    // collectSubjects() {
    //   const subjects = new Set();
    //   this.reletedExams
    //     .filter((item) => this.selectedExam.includes(item.name))
    //     .forEach((item) => {
    //       item.selectedSubject.forEach((subject) => {
    //         subjects.add(subject);
    //       });
    //     });

    //   this.myExperties = Array.from(subjects).map((subject) => ({
    //     subject,
    //     level: 0,
    //   }));

    // },
    async getGrades() {
      try {
        const response = await GradeController.getAllGrades();
        if (response?.status === 200) {
          if (response.data?.grades.length > 0) {
            this.grades = response.data?.grades;

            this.grades.forEach(
              (obj) =>
                (obj.gradeStream =
                  obj.stream != "NA" || obj.stream
                    ? obj.name + " - " + obj.stream + ""
                    : obj.name)
            );
            // console.log("priting grades:",this.grades)
          }
        } else {
          alert(response.data.error);
        }
      } catch (error) {
        console.error("Error fetching grades:", error);
      }
    },

    // async getRelateExams() {
    //   this.myExperties = [];
    //   this.reletedExams = [];
    //   this.selectedExam = [];
    //   if (this.objective == "Government Job Exam") {
    //     await this.getGovJobs();
    //   }
    //   if (this.objective == "Competitive Exam") {
    //     await this.getCompetitiveExams();
    //   } else {
    //     this.reletedExams = [];
    //     this.selectedExam = [];
    //   }
    // },

    async getRelateExams() {
      if (this.objective.pathWay == "School Grades") {
        // console.log("getRelateHello");
        return;
      }
      this.reletedExams = this.objective.exams;
      this.selectedExam = [];
      this.myExperties = [];
      this.selectedExam = [];
      this.selectedSubjects = [];
      this.selectedGrade = [];
    },
    async getExam() {
      // console.log("exam item: " + this.selectedExam.id);
      const response = await ExamController.getExam(this.selectedExam.id);
      if (response.status == 200) {
        this.exam = response.data.exam;
        // console.log("&&&&&&&&", this.exam);
        this.myExperties = [];
        this.selectedSubjects = [];

        this.selectedSubjects = this.exam.subjects;
        this.myExperties = this.selectedSubjects.map((subject) => ({
          subject,
          level: 0,
        }));
      }
    },
    async getCompetitiveExams() {
      const response = await CompetitiveExamController.getExams();
      if (response?.status == 200) {
        if (response?.data?.exams?.length > 0) {
          this.reletedExams = response.data.exams;
          // console.log("relatedExams :", this.reletedExams);
        }
      }
    },
    async getGovJobs() {
      const response = await GovernmentJobController.getGovtJobs();
      if (response?.status == 200) {
        if (response.data.govtjobs?.length > 0) {
          this.reletedExams = response.data.govtjobs;
          // console.log("relatedExams :", this.reletedExams);
        }
      }
      // console.log("relatedExams2 :", this.reletedExams);
    },
    async academics() {
      const response = await AcademicController.getExams();
      if (response?.status == 200) {
        if (response.data.exams?.length > 0) {
          this.reletedExams = response.data.exams;
          // console.log("relatedExams :", this.reletedExams);
        }
      }
    },
    // async getCompetitiveExams() {
    //   try {
    //     const response = await CompetitiveExamController.getExams();
    //     if (response?.status === 200) {
    //       if (response?.data?.exams?.length > 0) {
    //         this.reletedExams = response.data?.exams;
    //       }
    //     }
    //   } catch (error) {
    //     console.error("Error fetching competitive exams:", error);
    //   }
    // },

    async saveDetails() {
      this.isLoading = true;

      try {
        if (!this.$refs.profile.validate()) return;

        const modifiedMyExperties = this.myExperties.map((item) => ({
          ...item,
          level: this.getLevelString(item.level),
        }));
        // console.log("seleted grade", this.selectedGrade);
        const formData = {
          personalCreds: this.personalInfo,
          grade: this.selectedGrade?.name || "NA",
          stream: this.selectedGrade?.stream || "NA",
          objective: this.objective.name,
          pathway: this.objective.pathway,
          selectedExam: this.selectedExam?.name || "NA",
          myExperties: modifiedMyExperties,
          profilePic: this.avatar,
          storageUrl: this.storageUrl,
        };

        const userId = AuthService.getUserId();
        const response = await UserController.updateUserInfo(userId, formData);
        // console.log(response);
        if (response?.status === 200) {
          this.$router.push("/");
        } else if (response?.status === 409) {
          this.$router.push("/notfound");
        } else {
          alert("Something went wrong");
        }
      } catch (error) {
        console.error("An unexpected error occurred:", error);
      } finally {
        this.isLoading = false;
      }
    },

    getLevelString(level) {
      switch (level) {
        case 0:
          return "Novice";
        case 1:
          return "Beginner";
        case 2:
          return "Intermediate";
        case 3:
          return "Advanced";
        case 4:
          return "Expert";
        default:
          return ""; // Handle other cases as needed
      }
    },

    // async getGovJobs() {
    //   try {
    //     const response = await GovernmentJobController.getGovtJobs();
    //     if (response?.status === 200) {
    //       if (response.data.govtjobs?.length > 0) {
    //         this.reletedExams = response.data.govtjobs;
    //       }
    //     }
    //   } catch (error) {
    //     // Handle the error
    //     console.error("Error fetching government jobs:", error);
    //   }
    // },

    async getUserInfoo() {
      try {
        const response = await UserController.getUserInfo();
        if (response?.status === 200) {
          this.userInfo = response?.data?.data;
          if (this.userInfo.personalInfo.isPhoneVerified) {
            this.personalInfo.phoneNumber =
              this.userInfo.personalInfo.contactInfo.phoneNumber;
          } else if (this.userInfo.personalInfo.isEmailVerified) {
            this.personalInfo.email =
              this.userInfo.personalInfo.contactInfo.email;
          }
        } else {
          // this.logout();
        }
      } catch (error) {
        console.error("An error occurred:", error);
        // this.logout();
      }
    },
  },
  created() {
    this.getUserInfoo();
    this.getObjectives();
    this.getGrades();
  },
};
</script>
<style scoped>
.row {
  display: flex;
  flex-wrap: wrap;
  flex: unset !important;
  margin: -12px;
}

.v-input {
  align-items: flex-start;
  display: flex;
  flex: 1 1 auto;
  font-size: 16px;
  letter-spacing: normal;
  max-width: 100%;
  text-align: left;
  height: 50px;
}
</style>