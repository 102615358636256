// AuthService.js

import axios from "axios";
import jwtDecode from "jwt-decode";
import AuthService from '../services/AuthService'

const TOKEN_KEY = "jwt";
const instance = axios.create({
  baseURL: process.env.VUE_APP_AUTH_API_URL,
});
// const instance = axios.create({
//   baseURL: 'http://localhost:3000/v1/'
// });





export default {
  generateOTP: async function (data) {
    try {   
      const response = await instance.post("generate-otp", data);
      if (response.status == 200) {
        return response.data.flag;
      }
    } catch (error) {
      if (error.response.status === 404) {
        alert(error.response.data.error);
        return error.response.success;
      } else {
        alert(error.response.data.error);
        return error.response.success;
      }
    }
  },

  isAuthenticationExist: async function (phoneNumber) {
    try {
      const response = await instance.post("authenticationExist", {
        phoneNumber,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },
  isAuthenticationExistEmail: async function (email) {
    try {
      const response = await instance.post("authenticationExist", { email });
      return response;
    } catch (error) {
      return error.response;
    }
  },

  async validateOTP(data) {
    try {
 
      const response = await instance.post("validate-otp", data);  
      if (response.status == 200) {
        const token = response.data.token;
        localStorage.setItem("USER_ID", response.data.userId);
        localStorage.setItem(TOKEN_KEY, token);
        return response.data;
      } else {
        return response.data;
      }
    } catch (error) {
      alert(error.response.data.result);
    }
  },

  async validateLoginOtpFunction(data) {
    try {
      // Send OTP validation request for login
      const response = await instance.post("validate-login-otp", data);
      // console.log("priting res:",response.data);
      if (response.status == 200) {
        // Extract token and user information from response
        const token = response.data.token;
        localStorage.setItem("USER_ID", response.data.userId);
        localStorage.setItem(TOKEN_KEY, token);

        // Optionally, handle additional login success logic here
        return response.data;
      } else {
        // Handle unsuccessful OTP validation
        return response.data;
      }
    } catch (error) {
      // Handle errors (e.g., show error message)
      alert(error.response.data.result);
    }
  },  

  async verifySecondaryContact(data) {
    try {  
      const response = await instance.post("verifySecondaryContact", data,
      {
        headers: {
          Authorization: AuthService.getToken(),
        },
      }
      );

      if (response.status == 200) {
        return response.data;
      } else {
        return response.data;
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  },

  logout: function () {

    localStorage.removeItem(TOKEN_KEY);
  },
  getUser: function () {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      const decoded = jwtDecode(token);    
      return decoded.user;
    }
    return null;
  },
  loginViaGoogleAuth: async function (email) {

    try {
        const response = await instance.get('/loginViaGoogleAuth?email='+email ,{
            headers: {
                'Authorization': AuthService.getToken()
            }
        })
        const token = response.data.token;
        localStorage.setItem("USER_ID", response.data.userId);
        localStorage.setItem(TOKEN_KEY, token);
        return response;
    } catch (error) {
        return error.response;
    }
},
  getToken: function () {
    return localStorage.getItem(TOKEN_KEY);
  },
  getUserId: function(){
    return localStorage.getItem('USER_ID');

  },
  isAuthenticated: function () {
    // console.log("TOKEN :", TOKEN_KEY);
    const token = localStorage.getItem(TOKEN_KEY);
    // console.log("token :", token);
    if ((!token) || token=='undefined' || token=='null'){
      return false;
    }
    return token && !this.isTokenExpired(token);
  },


  isTokenExpired: function (token) {
    if(token){
      const decoded = jwtDecode(token);
      const expirationDate = new Date(decoded.exp * 1000);
      return expirationDate < new Date();
    }
    else {
      return true;
    }
  },
};
