<template>
    <div class="vh-100">
      <HeaderView/>
      <div class="w-100 content-height d-flex flex-row white">
        <v-card class="corDetFirDivStart elevation-0">
    
   
       <SubTopicTestScreenView/>
        </v-card>
        <v-card class="corDetSecDivStart elevation-0">
          <SideNavCourses />
        </v-card>
  
    
      </div>
    </div>
  </template>
  
  
  
    
    <script>
  import SideNavCourses from "../components/SideNavCoursesView.vue";
  import HeaderView from "../components/HeaderView.vue";
  import SubTopicTestScreenView from "../components/SubTopicTestScreenView.vue";


  export default {
    name: "CourseConsumptionView",
    components: {
      SideNavCourses,
      HeaderView,
      SubTopicTestScreenView
  
    },
    data() {
      return {
       
  
      };
    },

  
  };
  </script>
  
