<template>
    <v-card width="100%" min-height="76px"  max-height="76px" class="elevation-0 relative d-flex rounded-xl">
      <ChangeSubjectView class="absolute mr-n2 " />
    </v-card>
  </template>
  
  <style scoped>
  </style>
  <script>
  import ChangeSubjectView from "./ChangeSubjectView.vue"
  export default {
    name: "ChangingSubject",
    components: {
      ChangeSubjectView
    },
    data(){
       return {
         }
    }
  }
  </script>
  