<template>
  <!-- fixed-top -->
  <div class="homePage fixed-top relative d-flex flex-row">
    <div class="d-flex flex-row w-100">
      <!-- leftnav card -->
      <v-card class="leftSide rounded-0">
        <NavDrawer />
      </v-card>
      <!-- home page main -->
      <v-card class="homeMain overflow-y-scroll m-n8 rounded-0 elevation-0">
        <!-- top nav bar card -->
        <v-card width="100%" color="" class="elevation-0 mt-2 rounded-0 pr-6">
          <AppBar />
        </v-card>
        <v-card class="bannerCard z-index-2 rounded-xl transparent d-flex flex-column px-8 elevation-0 rounded-0 px-">
          <div class="text-h6 mt-n10 font-weight-500 mb-5">
            👋 Hi
            <span v-if="$store.state.UserInfo?.personalCreds?.firstName">{{
              this.$store.state.UserInfo?.personalCreds?.firstName +
              " " +
              this.$store.state.UserInfo?.personalCreds?.lastName
            }}</span>
            <span v-else>User</span>, happy learning
          </div>

          <v-container v-if="isLoading">
            <v-skeleton-loader :loading="loading" height="240" type="image, list-item-two-line">
            </v-skeleton-loader>
          </v-container>
          <div v-else>
            <v-row no-gutters>
              <v-col cols="12" md="8" class="pr-2">
                <!-- Adjusted width to 8 for 70% -->
                <v-card color="#8B81FF"
                  class="d-flex flex-row justify-space-between align-center w-100 h100 rounded-xxl">
                  <div class="white--text fit-content py-6 px-9">
                    <div class="text-h3">{{ welcomeMessage }}</div>
                    <!-- <div class="text-h3">Welcome</div> -->
                    <div>
                      <span class="text-subtitle-1 font-weight-medium">
                        Journey through excellence, one lesson a day, on our
                        cutting-edge platform!
                      </span>
                    </div>
                  </div>
                  <img src="../assets/girlwithpad.svg" class="mr-10" height="100%" width="auto" />
                </v-card>
              </v-col>
              <v-col cols="12" md="4" class="pl-2">
                <!-- Adjusted width to 4 for 30% -->
                <v-card color="#F0EFFE" class="d-flex flex-column justify-space-between w-100 h100 rounded-xxl pa-4">
                  <!-- Content for the second card -->
                  <div>
                    <div class="text-h6 hide1470 font-weight-bold">Course Progress</div>
                    <div class="text-h6 show1470 text-center font-weight-bold">Course Progress</div>

                  </div>
                  <v-row class="hide1470">
                    <v-col cols="12" sm="6">              
                      <div class="section-content">                     
                        <DonutProgressVue />
                      </div>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <div class="section-content overflow-y" style="max-height: 12.5rem;">
                        <div v-for="(subject, index) in subjects" :key="index"
                          class="d-flex w-100 align-center flex-row mt-5">
                          <img class="" :src="subject?.icon" alt="" height="25px" width="25px" />
                          <div class="d-flex flex-column w-100 ml-2">
                            <div class="d-flex flex-row justify-space-between">
                              <div class="">
                                <span class="text-caption font-weight-bold ">{{
                                    subject.name
                                  }}</span>
                              </div>
                              <div class="">
                                <span class="text-subtitle-2 font-weight-bold ">{{ calculatePercentage(subject)
                                  }}%</span>
                              </div>
                            </div>

                            <v-progress-linear :value="calculatePercentage(subject)" background-color="#C4C3C3"
                              color="#31D09A" class="rounded-xl" height="5" width="1" rounded>
                            </v-progress-linear>
                          </div>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="show1470 mt-0">
                    <v-col cols="12" sm="12" class="pt-0">
                      <div class="section-content ">                     
                        <DonutProgressVue />
                      </div>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <div class="pt-4">
            </div>
          </div>
        </v-card>

        <v-card
          class="subjectsCard z-index-2 transparent mt-4 elevation-0 px-8 rounded-0 d-flex flex-column justify-center">
          <h5 class="text-h5 font-weight-600">
            {{ homeData?.courseName }} Subjects
          </h5>
          <v-container v-if="isLoading" class="bottom-course">
            <v-row>
              <v-col cols="12" md="3">
                <v-skeleton-loader class="mx-auto border" max-width="400" type="card"></v-skeleton-loader>
              </v-col>

              <v-col cols="12" md="3">
                <v-skeleton-loader class="mx-auto border" max-width="400" type="card"></v-skeleton-loader>
              </v-col>
              <v-col cols="12" md="3">
                <v-skeleton-loader class="mx-auto border" max-width="400" type="card"></v-skeleton-loader>
              </v-col>
              <v-col cols="12" md="3">
                <v-skeleton-loader class="mx-auto border" max-width="400" type="card"></v-skeleton-loader>
              </v-col>
            </v-row>
          </v-container>

          <div class="d-flex flex-row overflow-x px-1">
            <v-card v-for="(subject, n) in homeData?.subjects" :key="n"
              class=" rounded-xl subject elevation-0 my-2 mr-4">
              <subject-card :subject="subject" :index="n" />
            </v-card>
          </div>
        </v-card>
        <div class="d-flex relative flex-row pl-8 mt-4 w-100 mb-8"></div>
      </v-card>
    </div>
  </div>
</template>


<script>
import AppBar from "@/components/AppBar.vue";
import AuthService from "../services/AuthService";
import CourseConsumptionController from "@/controllers/CourseConsumptionController";
import SubjectCard from "@/components/SubjectCard.vue";
import DonutProgressVue from "@/components/DonutProgress.vue";
export default {
  name: "HomeView",
  components: {
    AppBar,
    SubjectCard,
    DonutProgressVue,
  },
  data() {
    return {
      homeData: [],
      screenWidth: window.innerWidth,

      loading: false,
      isLoading: true,
      valueDeterminate: 50,
      subjects: [],
    };
  },
  computed: {
    progressPercentage() {
      // if (this.homeData.subtopicCount === 0) return 0;

      const percentage =
        ((this.homeData?.completedSubtopics || 0) /
          (this.homeData.subtopicCount || 1)) *
        100;
      const formattedPercentage = parseFloat(percentage.toFixed(2));
      // console.log("Progress Percentage:", formattedPercentage);
      return formattedPercentage;
    },
    welcomeMessage() {
      return this.$store.state.isCourseEnroll ? 'Welcome Back!' : 'Welcome!';
    }
  },

  methods: {
    async getHomeMenu() {
      try {
        const response = await CourseConsumptionController.getEnrolledCourses();
        if (response?.status == 401 || response?.status == 440) {
          AuthService.logout();
        } else if (response?.status === 200) {
          this.homeData = response?.data?.data[0];
          this.subjects = response?.data?.data[0]?.subjects || [];
        } else {
          alert(response.data.error);
        }
      } catch (error) {
        console.error("Error fetching home data:", error);
      } finally {
        this.isLoading = false;
      }
    },



    calculatePercentage(subject) {
      // Calculate completion percentage for the subject
      const completedSubtopics = subject.completedSubtopics || 0;
      const subtopicCount = subject.subtopicCount || 1;
      return ((completedSubtopics / subtopicCount) * 100).toFixed(1); // Round to nearest integer
    },
  },
  mounted() {
    this.getHomeMenu();
    // this.getUserInfo();
  },
};
</script>
<style scoped>
.subject-container {
  display: flex;
  align-items: center;
  /* Align items vertically */
  justify-content: space-between;
  /* Space evenly between child elements */
  padding: 10px 0;
  /* flex-direction: row; */
  /* align-items: center; */
}

.subject-info {
  display: flex;
  align-items: center;
  /* margin-right: 20px; */
}

.subject-image {
  width: 50px;
  /* Adjust image size as needed */
  height: 50px;
  border-radius: 50%;
  /* Assuming a circular image */
  margin-right: 10px;
  /* Adjust spacing between image and text */
}

.subject-details {
  margin-right: 10px;
  /* Space between image and text */
}

.subject-name {
  font-weight: bold;
  margin-bottom: 5px;
  /* Space between name and percentage */
}

.percentage {
  color: #888;
}

.progress-bar {
  flex: 1;
  /* Take up remaining space */
  margin-left: 20px;
  /* Adjust spacing between subject info and progress bar */
}

.progress {
  height: 10px;
  /* Adjust height of the progress bar */
  width: 80%;
  /* Example width, adjust as needed */
  background-color: #42a5f5;
  /* Color of the progress */
}
</style>
