<template>
  <div class="userInfoCardd mt-1 ml-3 fit-content">
    <v-list color="transparent py-0 mb-2">
      <v-list-item class="pa-0">
        <div class="mt-3 ml-2">
          <img
            width="40px"
            height="auto"
            :src="subjects[this.$route.query.subjectId]?.icon"
          />
        </div>

        <v-list-item-content class="py-0">
          <v-list-item-title class="text-subtitle-1 font-weight-medium mt-1">
            <span class="ml-3 newShadeBlack--text">{{
              subjects[this.$route.query.subjectId]?.name
            }}</span>
          </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action class="ml-0 my-0">
          <v-btn icon @click="toggleDropdown">
            <v-icon class="newShadeBlack--text mt-1">mdi-chevron-down</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>

    <!-- Dropdown content -->
    <div v-if="dropdownOpen" class="w-100 black--text text-subtitle-1 font-weight-medium py-2 px-2 text-capitalize" ref="dropdown">
      <v-divider class="mb-2"></v-divider>
      <div v-for="(subject, index) in subjects" :key="index">
        <div
          v-if="$route.query.subjectId != index"
          @click="changeSubject(subject.indexing, subject.name)"
          class="container90 cursor"
        >
          <img width="40px" :src="subject?.icon" alt="Image" class="image" />

          <span class="text newShadeBlack--text">{{
            subject?.name
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <style scoped>
</style>
  <script>
export default {
  name: "userCard",
  data() {
    return {
      dropdownOpen: false,
      subjects: [],
      dropDownIconClicked: false,
    };
  },

  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  watch: {
    "$route.query": function routerwatch() {
      this.getSubjectColor();
    },
  },
  methods: {
    closeDropDown(){
    },

    toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen; // Toggle dropdown state
      this.dropDownIconClicked=true;
      if (this.dropdownOpen) {
        document.addEventListener("click", this.handleClickOutside);
      } else {
        document.removeEventListener("click", this.handleClickOutside);
      }
    },

    handleClickOutside(event) {
      if (this.$refs.dropdown && !this.$refs.dropdown.contains(event.target) && this.dropDownIconClicked===false){       
        this.dropdownOpen = false;      
        document.removeEventListener("click", this.handleClickOutside);
      }
      this.dropDownIconClicked=false;
    },

    async getSubjectColor() {
      try {
        var path = this.$route.path;
        if (path.includes("/course") || path.includes("test")) {
          var subjects = this.$store.state.courseData[0].subjects;
          var secondaryColor =
            subjects[this.$route.query.subjectId]?.primaryColor || "#5a4efa";
          var primaryColor =
            subjects[this.$route.query.subjectId]?.secondaryColor || "#dcd9ff";
          var onColor =
            subjects[this.$route.query.subjectId]?.onColor || "black";
            var ctaColor =
            subjects[this.$route.query.subjectId].ctaColor || '#5a4efa';

          document.documentElement.style.setProperty(
            "--subject-primary",
            primaryColor
          );
          document.documentElement.style.setProperty(
            "--subject-secondary",
            secondaryColor
          ); 
          document.documentElement.style.setProperty(
            "--subject-onColor",
            onColor
          );
          document.documentElement.style.setProperty(
            "--subject-cta",
            ctaColor
          );
        } else {
          document.documentElement.style.setProperty(
            "--subject-primary",
            "#5a4efa"
          );
          document.documentElement.style.setProperty(
            "--subject-secondary",
            "#dcd9ff"
          );
          document.documentElement.style.setProperty(
            "--subject-onColor",
            "black"
          );
          document.documentElement.style.setProperty(
            "--subject-cta",
            "#5a4efa"
          );
        
        }
      } catch (error) {
        console.log(error)
      }
    },

    changeSubject(index, subject) {
      this.$router.push({
        path: "/course",
        query: {
          subjectId: index,
          subject: subject, // Pass subjectId as a parameter to the '/course' route
        },
      });
      this.dropdownOpen = false;
    },

    async getSubjectData() {
      const courseData = await this.$store.dispatch("getCourseData");
      this.subjects = courseData[0].subjects;
      this.getSubjectColor();
    },
  },
  mounted() {
    this.getSubjectData();   
    document.addEventListener("click", this.handleClickOutside);
  },
};
</script>
  <style scoped>
.rounded-circle {
  border-radius: 50%;
}
.v-list-item {
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  letter-spacing: normal;
  max-height: 45px;
  outline: none;
  padding: 0 16px;
  position: relative;
  text-decoration: none;
}
.dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: red;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 999; 
  padding: 10px;
}

.text {
  margin-left: 15px;
}
</style>