<template>
  <div class="sub-unit-screen pb-0 mt-0">
    <div>
      <div v-if="isLoading" class="loader-container">
        <img
          src="../assets/loader-eazily.gif"
          alt="Loading..."
          class="loader-img"
        />
      </div>
      <div class="text-subtitle-1 content-name ml-3 mt-2 mb-2 cursor">
        <v-icon @click="goToRoute('homeView', {})" class="unitss" size="20">mdi-home</v-icon>
     
        <div class="font-weight-medium">
          > <span class="unitss mr-1">{{ subject }}</span> >
          <span class="unitss mr-1" @click="goToRoute('NoUnitSelected', { subjectId: $route.query?.subjectId, subject: $route.query?.subject })">Units</span> >
          <span class="unitss ml-1 mr-1">Chapters</span> >
          <span class="unitss ml-1 mr-1" @click="goToRoute('Topics', $route.query)">Topics</span> >
          <span class="unitss ml-1 mr-1" @click="goToRoute('SubTopics', $route.query)">Sub Topics</span> >
          <span class="topicss">Brain Booster</span>
        </div>
      </div>
      <div class="w-100 pl-4">
        <PolygonStepperViewVue />
      </div>
      <div class="ml-2 pl-2 mt-4 mb-4 d-flex">
        <div class="text-h5 text-center d-flex align-center font-weight-medium">
          {{ name }}
        </div>
        <div class="image-containert-bb mt-1 cursor mr-4" @click="openPdfDialog(read)">
          <img src="../assets/fullScreen.svg" />
        </div>
      </div>   

      <div v-if="pdfDialog" fullscreen style="margin: 0; padding: 0">
        <v-card style="margin: 0; padding: 0" elevation="0">
          <v-card-text
            style="
              margin: 0;
              padding: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
            "
          >
            <!-- Container for the PDF viewer -->
            <div
              id="pdfContainer"
              style="width: 100%; height: calc(100vh - 340px); margin: 0px 16px"
            >
              <!-- Embedded PDF -->
              <embed
                 v-if="read"
                :src="read + '#toolbar=0'"
                type="application/pdf"
                style="width: 100%; height: 100%"
                id="pdfEmbed"
              />
              <div id="xyzdfa"></div>
              <!-- Full-screen button -->
            </div>
          </v-card-text>
        </v-card>
      </div>

      <v-dialog
        v-if="pdffDialog"
        v-model="pdffDialog"
        fullscreen
        style="margin: 0; padding: 0"
      >
        <v-card style="margin: 0; padding: 0">
          <v-toolbar dark style="color: black; background-color: #ececec">
            <v-btn icon @click="closePdfDialog">
              <v-icon style="color: black">mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title class="ml-2 title-of-pdf">
              {{ name }}
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text
            style="
              margin: 0;
              padding: 0;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            <div
              id="pdf-container"
              style="width: 100%; height: calc(100vh); margin: 0px 16px"
            >
            <embed
                :src="read + '#toolbar=0'"
                type="application/pdf"
                style="width: 100%; height: 100%"
                id="pdfEmbed"
              />
          
          
          </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <div class="button-container-sub-topic pl-4">
        <v-btn
        @click="goToRoute('content', $route.query)"
          class="white onColor-T elevation-0 button-sub-unit onColorBorder rounded-xl"
          >BACK</v-btn
        >
        <v-btn
        @click="goToRoute('test', $route.query)"
          class="white--text onColor-B elevation-0 button-sub-unit rounded-xl"
          >NEXT</v-btn
        >
      </div>
    </div>
  </div>
</template>
        <script>
import PolygonStepperViewVue from "./PolygonStepperView.vue";
import CourseConsumptionController from "@/controllers/CourseConsumptionController";
import { myMixin } from '../myMixin.js';


export default {
  name: "SubUnitBB",
  mixins: [myMixin],
  components: {
    PolygonStepperViewVue,
  },
  data() {
    return {

      BB: {},
      pdfDialog: true,
      subject: "",
      read: "",
      pdfUrl: "",
      pdffDialog: false,
      name: "",
      isLoading: true,
    };
  },
  methods: {
  
    async getSubTopicData() {
  try {
    var payload = this.getRouterQuery();
    const response = await CourseConsumptionController.getTopicData(
      payload,
      "BB"
    );
    if (response?.status === 200) {
      this.BB = response?.data?.data;
      console.log("printig bb:",this.BB);
      this.read = response?.data?.data.url;
      this.name = response?.data?.data.name;
      this.updateCompleteStatus('bb')
      this.isLoading = false;
    }
    else{
      this.isLoading = false;
      this.updatePlayer('Brain booster not available.', 'xyzdfa')
    }
  } catch (error) {
    console.error('Error fetching sub-topic data:', error);    
  }
},
    closePdfDialog() {
      this.pdffDialog = false;
      this.pdfUrl = "";
    },
    openPdfDialog(pdfUrl) {
      this.pdfUrl = pdfUrl;
      this.pdffDialog = true;
    },
  
  },
  mounted() {
    this.subject = this.$route.query.subject;
    this.getSubTopicData()

  },
};
</script>
<style scoped>

.onColorBorder {
  border: 1px solid var(--subject-onColor) !important;
}
</style>
