<template>
  <!-- fixed-top -->
  <div class="w-100 fixed-top relative d-flex flex-row white">
    <div class="d-flex flex-row w-100">
      <!-- leftnav card -->
      <v-card class="leftSide rounded-0">
        <NavDrawer />
      </v-card>
      <!-- home page main -->
      <v-card class="homeMain overflow-y-scroll m-n8 rounded-0 elevation-0">
        <!-- top nav bar card -->
        <v-card width="100%" color="" class="elevation-0 rounded-0 pr-6">
          <AppBar />
        </v-card>

        <v-card
          class="bannerCard z-index-2 rounded-xl transparent d-flex flex-column px-8 elevation-0 rounded-0 px-"
        >
          <div class="text-h5 mt-n9 ml-n3 font-weight-medium mb-3">Reports and Analysis</div>
          <v-card
            class="shadingColor d-flex flex-row justify-space-between align-center w-100 h100 rounded-xxl"
          >
            <div class="white--text fit-content pa-8">
              <h3 class="text-h3">
                Welcome Back!
              </h3>
              <div class="">
                <span class="text-subtitle-1 font-weight-medium"
                  >Take the world’s best course and learn something new
                  everyday.</span
                >
              </div>
              <!-- <v-btn
                height="38px"
                width="235px"
                class="mt-4 elevation-0 white rounded-xl primary--text text-subtitle-1 font-weight-medium text-capitalize"
                >Explore courses</v-btn
              > -->
            </div>
            <img src="../assets/girlwithpad.svg" height="100%" width="auto" />
          </v-card>
        </v-card>
        <div class="text-h5 mt-6 ml-10 font-weight-medium">My Reports</div>
        <div class="customdivb Surfaceshade3 mt-5 rounded-xl">
          <v-row>
            <v-col cols="1" class="mt-6 ml-4">
              <v-img src="../assets/text-book.svg" max-width="34"></v-img>
            </v-col>
            <v-col cols="8">
              <div class="font-weight-medium text-subtitle-1 phy">Phyics Test</div>
              <!-- <div>10 questions</div> -->
            </v-col>
            <v-col cols="">
                <v-btn outlined class=" primary--text scores-class  mt-5" >
              View Scores
            </v-btn>
                 </v-col>
          </v-row>
        </div>
        <div class="customdivb Surfaceshade3 mt-5 rounded-xl">
          <v-row>
            <v-col cols="1" class="mt-6 ml-4">
              <v-img src="../assets/text-book.svg" max-width="34"></v-img>
            </v-col>
            <v-col cols="8">
              <div class="font-weight-medium text-subtitle-1 phy">Chemistry Test</div>
              <!-- <div>10 questions</div> -->
            </v-col>
            <v-col cols="">
                <v-btn outlined class=" primary--text scores-class  mt-5" >
              View Scores
            </v-btn>
                 </v-col>
          </v-row>
        </div>
        <div class="customdivb Surfaceshade3 mt-5 rounded-xl">
          <v-row>
            <v-col cols="1" class="mt-6 ml-4">
              <v-img src="../assets/text-book.svg" max-width="34"></v-img>
            </v-col>
            <v-col cols="8">
              <div class="font-weight-medium text-subtitle-1 phy">Maths Test</div>
              <!-- <div>10 questions</div> -->
            </v-col>
            <v-col cols="">
                <v-btn outlined class=" primary--text scores-class  mt-5" >
              View Scores
            </v-btn>
                 </v-col>
          </v-row>
        </div>
      </v-card>
    </div>
  </div>
</template>
<script>
import AppBar from "@/components/AppBar.vue";

export default {
  name: "ReportsAnalysis",
  components: {
    AppBar,
  },
  data() {
    return {};
  },
};
</script>
<style>
.customdivb {
  display: flex;
  justify-content: space-between;
  width: 94%;
  height: 84px;
  margin: 0 auto; /* To center the div */
}
.phy{
  margin-top: 4%;
}
.scores-class{
  border-radius: 10px;
  border: 1px solid #99999A;
}
</style>
