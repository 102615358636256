<template>
  <div class="customdivc pa-0">
  
      <img
      src="../assets/photo5.svg"
      class="vector12 cursor "
      alt="Icon for Unit"
      @click="backToUnit()"
    />
  
    
    <div class="content-container">
      <div class="text-body-2 unit-heading pdpcolor ">
        Unit {{ indexing + 1 }}
      </div>
      <p class="unit-name black--text text-h6">{{ this.unit.unitName }}</p>
    </div>
    <div class="scrollable-content w-100">
      <v-expansion-panels class="w-90 ml-14">
        <v-expansion-panel
          v-for="(item, i) in this.unit.chapters"
          :key="i"
          class=" rounded-panel mb-4"
        >
          <v-expansion-panel-header class="font-weight-bold ">
            <div class="contenttr-row ml-8 mt-2">
              <img
                src="../assets/Vector.svg"
                class="mr-2"
                alt="Icon for Unit"
              />
              <span>Chapter {{ i + 1 }}</span>
            </div>
            <span class="pdpcolor ml-2 mt-2 setsandrep">
              {{ item.chapterName }}
            </span></v-expansion-panel-header
          >
          <v-expansion-panel-content class=" expansion-class mb-4">
            <div v-for="(j, topicIndex) in item.topics" :key="topicIndex">
              {{ topicIndex + 1 }}. <b>{{ j.topicName }}</b>
              <!-- Loop for subtopics inside the current topic -->
              <div
                v-for="(subtopic, subtopicIndex) in j.subTopics"
                :key="subtopicIndex"
               
              >
                &nbsp;&nbsp;&nbsp; {{ topicIndex + 1 }}.{{ subtopicIndex + 1 }}
                {{ subtopic.subTopicName }}
              </div>
              <div class="mb-2"></div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>
<script>
export default {
  props: ["unit", "indexing"],

  data() {
    return {};
  },
  methods: {
    backToUnit() {
      // console.log("palu")
      this.$emit("closeUnitDetails")
    },
  },
  mounted() {
    // console.log("...", this.unit);
    // console.log("mm",this.indexing)
  },
};
</script>
  

<style scoped>
.v-expansion-panels > *:first-child {
  /* border-top-left-radius: 10px; */
  /* border-top-right-radius: 10px; */
  border-radius: 16px;
}
.v-expansion-panels > *:last-child {
  /* border-top-left-radius: inherit; */
  /* border-top-right-radius: inherit; */
  border-radius: 16px;
}
.v-expansion-panel-header {
  align-items: center;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  display: flex;
  font-size: 0.9375rem;
  line-height: 1;
  min-height: 60px;
  outline: none;
  padding: 16px 24px;
  position: relative;
  transition: 0.3s min-height cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}
.contenttr-row {
  display: flex;
  align-items: center;
  width: 53px;
    min-width: 53px;
    max-width: 110px;
}
.vector14 {
  position: relative;
  top: 50px;
  right: 45%;
  cursor: pointer ;
}

.vector12{
  position: relative;
  right: 45%;
  top: 50px;
  cursor: pointer ;
  z-index: 33;
}

.v-expansion-panel:not(:last-child).v-expansion-panel--active[data-v-4b53b31e] {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(
    .v-expansion-panels--tile
  )
  > .v-expansion-panel--active {
  border-radius: 16px;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(
    .v-expansion-panels--tile
  )
  > .v-expansion-panel--active
  + .v-expansion-panel {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(
    .v-expansion-panels--tile
  )
  > .v-expansion-panel--next-active {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}
</style>
  