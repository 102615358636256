import { render, staticRenderFns } from "./SubTopicPdf.vue?vue&type=template&id=e5377ce0&scoped=true"
import script from "./SubTopicPdf.vue?vue&type=script&lang=js"
export * from "./SubTopicPdf.vue?vue&type=script&lang=js"
import style0 from "./SubTopicPdf.vue?vue&type=style&index=0&id=e5377ce0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e5377ce0",
  null
  
)

export default component.exports