<template>
  <v-card width="100%" min-height="76px"  max-height="76px" class="elevation-0 relative d-flex justify-end">
    <UserCard class="absolute mr-n2 " />
  </v-card>
</template>

<style scoped>
</style>
<script>
import UserCard from "./UserCard.vue"
export default {
  name: "AppBar",
  components: {
    UserCard
  },
}
</script>
