<template>
  <div class="donut-chart-container">
    <GChart
      class="chart"
      type="PieChart"
      :data="chartData"
      :options="chartOptions"
    />
    <div class="ml-2 chart-overlay">
      <span v-if="chartData.length > 1">{{ completionPercentage }}%</span>
    </div>
  </div>
</template>

<script>
import { GChart } from "vue-google-charts/legacy";
import CourseConsumptionController from "@/controllers/CourseConsumptionController"; // Adjust the import based on your project structure

export default {
  name: "DonutProgressVue",

  components: {
    GChart,
  },

  data() {
    return {
      chartData: [["Status", "Value"]], // Default grey chart data
      chartOptions: {
        legend: {
          position: "none",
        },
        chartArea: {
          left: "4%",
          right: 0,
          height: "80%",
          width: "100%",
        },
        pieHole: 0.6, // Making it a donut chart
        backgroundColor: 'transparent',
        pieSliceBorderColor: 'none', // Remove the outline from the slices
        pieSliceText: 'none', // Remove the text from the middle of the donut chart
      },
      completionPercentage: 0, // Initial completion percentage
    };
  },

  created() {
    this.getHomeMenu();
  },

  methods: {
    async getHomeMenu() {
      try {
        const response = await CourseConsumptionController.getEnrolledCourses();
        // console.log("API Response:", response.data.data);
        if (response.status === 401 || response.status === 440) {
          // AuthService.logout();
        } else if (response.status === 200) {
          this.processData(response.data.data[0]);
        } else {
          alert(response.data.error);
        }
      } catch (error) {
        console.error("Error fetching home data:", error);
      } finally {
        this.isLoading = false;
      }
    },

    processData(data) {
      // console.log("Data:", data); // Print the data array

      // const { completedSubtopics, subtopicCount } = data;
      // console.log("chk1:", data.completedSubtopics);
      // console.log("chk2:", data.subtopicCount);

      const completionPercentage = (data.completedSubtopics / data.subtopicCount) * 100;
      this.completionPercentage = completionPercentage.toFixed(1); // Update the completion percentage
      // console.log(`Completion Percentage: ${completionPercentage}`); // Print the completion percentage

      let chartData = [
        ["Status", "Value"],
        ["Completed", completionPercentage],
        ["Remaining", 100 - completionPercentage]
      ];
      
      let colors = ["#4234DF", "#DCDBDB"];

      // If the completion percentage is 0, show the grey chart
      if (completionPercentage === 0) {
        chartData = [
          ["Status", "Value"],
          ["Empty", 100]
        ];
        colors = ["#DCDBDB"]; // Grey color
      }

      this.chartOptions.colors = colors;
      this.chartData = chartData;
    }
  },
};
</script>

<style scoped>
.donut-chart-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; /* Ensure the container is the reference for the overlay */
}

.chart {
  width: 100%;
  height: 100%;
}

.chart-overlay {
  position: absolute; /* Position the overlay absolutely within the container */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Center the overlay */
  font-size: 24px;
  font-weight: bold;
  color: #000;
}
</style>
