<template>
  
    <div class="curriculum-container h100">
      <div class="curriculum-container fit-content px-1 row" v-if="!selectedUnit">
        <div
          v-for="(unit, index) in this.selectedSubject?.units"
          :key="index"
          @click="showUnitDetails(index)"
          class="curriculum-box col-sm-4"
        >
          <span class="text-caption pdpcolor capitalize ">
            Unit {{ index + 1 }}
          </span>
          <div class="text-subtitle-1 font-weight-medium">
            {{ unit.unitName }}
          </div>

          <div class="content-row">
            <img src="../assets/bookslogo.svg" alt="Icon for Unit" />
            <span class="text-body-2 color-chapter"
              >{{ unit.chapters.length }} Chapters</span
            >
          </div>
        </div>
      </div>

      <!-- Dynamic component rendering based on selected unit -->
      <!-- <v-dialog v-model="isUnitSelected" width="auto"> -->
      <!-- <p>dsjklfa</p> -->

      <!-- </v-dialog> -->
      <div v-if="selectedUnit" class="unit-details">
        <UnitDetails
          :unit="selectedUnit"
          :indexing="indexing"
          @closeUnitDetails="selectedUnit = null"
        />
      </div>
    </div>

</template>
  
  <script>
import UnitDetails from "@/components/UnitDetails.vue";

export default {
  props: ["selectedSubject"],
  data() {
    return {
      isUnitSelected: true,
      selectedUnit: null,
      unitsDisplaying: [],
      indexing: null,
    };
  },
  methods: {
    showUnitDetails(index) {
      // console.log("rama ",index);
      this.indexing = index;
      // this.selectedUnit = this.selectedSubject.units.find((unit) => unit.id === unitId);
      this.selectedUnit = this.selectedSubject.units[index];
      // console.log("rama1:",this.selectedUnit);
    },
    closeUnitDetails() {
      this.selectedUnit = null;
    },
  },
  components: {
    UnitDetails,
  },
  mounted() {
    // this.unitsDisplaying = this.selectedSubject.units ;
    // console.log("Ritik here",this.unitsDisplaying);
    // console.log("palvi here..:",this.selectedSubject.units);
  },
  watch: {
    selectedSubject(newValue) {
      console.log("selected sub,", newValue);
      // this.unitsDisplaying = this.selectedSubject.units ;
    },
  },
};
</script>
  

  <style scoped>
.curriculum-container {
  display: flex;
  flex-wrap: wrap;
}
.regular-content {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  color: #fff; /* Adjust text color */
}

.curriculum-box {
  width: calc(100% - 20px);
  /* height: 181px; */
  /* background: linear-gradient(to bottom, #ebe9ff 0%, #b0a9ff 100%); */
  /* background-color: rgba(240, 239, 254, 1); */
  background-color: var(--subject-secondary);

  border-radius: 16px;
  margin-top: 3%;
  cursor: pointer;
  flex-basis: calc(33.33% - 20px);
  margin: 1rem 10px;
  padding: 1.5rem 26px;
  box-shadow: 0 0 5px rgba(96, 96, 96, 0.5);
}

.content-row {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.content-row img {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.curriculum-box span {
  font-size: 1em;
}

.unit-details {
  width: 100%;
  /* padding: 16px; */
  box-sizing: border-box;
  /* margin-top: 3%; */
  border-radius: 8px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}
</style>
  