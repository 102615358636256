<template>
    <div>
      <div class="text-subtitle-1 content-name ml-3 mb-2 cursor">
        <!-- <img src="../assets/photo7.svg" @click="home" class="size-lock mt-1 mr-1" /> -->
        <v-icon  @click="home" class="unitss" size="20">mdi-home</v-icon>
        <div class="font-weight-medium">
          > <span class="unitss mr-1">{{$route.query.subject}}</span> >
          <span class="ml-1 mr-1 topicss">Units</span>
        </div>
      </div>
      <div class="centered-container">
        <div
        width="100%"
        class="d-flex flex-column align-center justify-center empty-box"
        height="100%"
      >
        <img width="105px" height="105px" src="../assets/doodles.svg" />
        <div class="text-h5 font-weight-medium ml-6 mt-4">Select A Unit</div>
      </div>
      </div>
     
    </div>
  </template>
   
  <script>
  export default {
    name: "NoUnitSelected",
    data() {
      return {
        subject: ""
      };
    },
    methods: {
     home() {
        this.$router.push({
          name: "homeView",
         
        });
      },
    },
    mounted() {
     this.subject = this.$route.query.subject;
     // console.log("hoo",this.subject);
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.onResize);
    },
  };
  </script>
   
  <style scoped>
  .fixedBottom {
    position: sticky !important;
    bottom: 0 !important;
    width: 100%;
  }
  .centered-container {
     position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .content-name {
    display: flex;
  }
  .size-lock {
    width: 18px;
    height: 18px;
    /* color: red; */
  }
  .unitss {
    /* color: #E9B121; */
    /* color: rgba(128, 91, 0, 0.87); */
    color: var(--subject-onColor) !important;
  }
  .v-application--is-ltr .v-list-item__icon:first-child {
    margin-right: unset;
  }
  .empty-box{
  }
  .text {
    color: white;
  }
  .v-application .grey--text.text--lighten-2 {
    overflow: hidden;
  }
  .no-ripple .v-ripple {
    display: none !important;
  }
  .drawerIcon {
    overflow: hidden;
  }
  .v-list-group
    .v-list-group__header
    .v-list-item__icon.v-list-group__header__append-icon {
    margin-bottom: 16px;
  }
  .maspad {
    padding-right: 60px;
  }
  .hideNavigationPanel {
    display: none;
  }
  .v-application .active {
    border-radius: 16px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .navCard {
    height: calc(95% - 56px);
  }
  .topicss {
    color: rgba(0, 0, 0, 0.6);
  }
  </style>