<template>
  <!-- fixed-top -->
  <div class="homePage fixed-top relative d-flex flex-row">
    <div class="d-flex flex-row w-100">
      <!-- leftnav card -->
      <v-card class="leftSide rounded-0">
        <NavDrawer />
      </v-card>
      <!-- home page main -->
      <v-card class="homeMain overflow-y-scroll m-n8 rounded-0 elevation-0">
        <v-card
          height="8.375rem"
          width="100%"
          class="white d-flex rounded-0 flex-row justify-space-between elevation-0"
        >
          <h5 class="text-h5 font-weight-medium mt-9 ml-5">My Profile</h5>
          <UserCard class="mr-4 mt-4" />
        </v-card>

        <v-card
          width="100%"
          class="profileMain white elevation-0 rounded-0 mt-2"
        >
          <div class="w-100">
            <div class="profilePic profileImage">
              <div class="profilePic rounded-pill overflow-hidden">
              

                <img v-if="userInfo.profilePic" class="rounded-circle" width="100%" height="auto" :src="userInfo.profilePic" />
                <img v-else width="100%" height="auto" src="../assets/setupprofile.png" />
                

              </div>
              <div class="editpen">
                <input
                  type="file"
                  class="absolute opicity-0 z-index-2 cursor"
                  id="myfile"
                  name="myfile"
                  @input="handleImageUpload"
                />

                <v-icon
                  size="14"
                  class="cursor primary white--text pa-1 rounded-pill"
                  >mdi-pen
                  <!-- <input
                    type="file"
                    id="file-input"
                    accept="image/*"
                    class="w-100"
                    style="opacity: 0; position: absolute; top: 0"
                    @change="handleImageUpload"
                  /> -->
                </v-icon>
              </div>
            </div>

            <v-list color=" userName transparent elevation-0 py-0 mb-2">
              <v-list-item class="pa-0 elevation-0">
                <!-- <v-list-item-avatar class="ma-0"> -->

                <!-- </v-list-item-avatar> -->
                <!-- text--primary -->
                <v-list-item-content class="py-0 userInformation">
                  <v-list-item-title class="text-subtitle-1 font-weight-medium">
                    <span v-if="$store.state.UserInfo?.personalCreds?.firstName"
                      >{{
                        this.$store.state.UserInfo?.personalCreds?.firstName +
                        " " +
                        this.$store.state.UserInfo?.personalCreds?.lastName
                      }}
                    </span>
                    <span v-else>User</span>
                  </v-list-item-title>
                  <!-- text--secondary -->
                  <v-list-item-subtitle class="caption primary--text">
                    ({{ courseName }})
                    <!-- ({{ this.$store.state.UserInfo?.selectedExam }}) -->
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="ml-0 my-0">
                  <v-btn
                    color="grey lighten-3 rounded-xl elevation-0 primary--text text-subtitle-1 font-weight-medium text-capitalize"
                    @click="toggleEditing"
                  >
                    {{ isEditing ? "Cancel" : "Edit Details" }}
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </div>
          <v-row class="mt-12">
            <v-col cols="4">
              <p
                class="mb-1 text-subtitle-1 font-weight-medium text--secondary"
              >
                First Name
              </p>
              <input
                type="text"
                class="text-field rounded-xl"
                v-model="userInfo.personalCreds.firstName"
                :disabled="!isEditing"
              />
            </v-col>
            <v-col cols="4">
              <p
                class="mb-1 text-subtitle-1 font-weight-medium text--secondary"
              >
                Middle Name
              </p>

              <input
                type="text"
                class="text-field rounded-xl"
                v-model="userInfo.personalCreds.middleName"
                :disabled="!isEditing"
              />
            </v-col>
            <v-col cols="4">
              <p
                class="mb-1 text-subtitle-1 font-weight-medium text--secondary"
              >
                Last Name
              </p>

              <input
                type="text"
                class="text-field rounded-xl"
                v-model="userInfo.personalCreds.lastName"
                :disabled="!isEditing"
              />
            </v-col>
          </v-row>

          <v-row class="">
            <v-col cols="8">
              <p
                class="mb-1 text-subtitle-1 font-weight-medium text--secondary"
              >
                Email
              </p>
              <input
                type="email"
                class="text-field rounded-xl"
                v-model="userInfo.personalCreds.email"
                :disabled="!isEditing"
              />
            </v-col>

            <v-col cols="4">
              <p
                class="mb-1 text-subtitle-1 font-weight-medium text--secondary"
              >
                Phone Number
              </p>

              <input
                type="text"
                class="text-field rounded-xl"
                v-model="userInfo.personalCreds.phoneNumber"
                :disabled="!isEditing"
              />
            </v-col>
          </v-row>

          <v-row class="">
            <v-col cols="4">
              <p
                class="mb-1 text-subtitle-1 font-weight-medium text--secondary"
              >
                City
              </p>
              <input type="text" class="text-field rounded-xl" v-model="userInfo.personalCreds.city"   :disabled="!isEditing" />
            </v-col>
            <v-col cols="4">
              <p
                class="mb-1 text-subtitle-1 font-weight-medium text--secondary"
              >
                State
              </p>

              <input
                type="text"
                class="text-field rounded-xl"
                v-model="userInfo.personalCreds.state"
                :disabled="!isEditing"
              />
            </v-col>
            <v-col cols="4">
              <p
                class="mb-1 text-subtitle-1 font-weight-medium text--secondary"
              >
                ZIP code
              </p>

              <input
                type="text"
                class="text-field rounded-xl"
                v-model="userInfo.personalCreds.zipcode"
                :disabled="!isEditing"
              />
            </v-col>
          </v-row>

          <v-row class="">
            <v-col cols="3">
              <v-btn
                width="100%"
                height="48px"
                class="elevation-0 secondary rounded-xl"
                :disabled="!isEditing"
                @click="saveDetails"
                ><span class="text-subtitle1 font-weight-bold"
                  >save</span
                ></v-btn
              >
            </v-col>
          </v-row>
          <!-- <v-card class="pa-12">
            <input type="text" class="text-field rounded-xl" placeholder="Enter your text here">
          </v-card> -->
        </v-card>
      </v-card>
    </div>
  </div>
</template>


<script>
import UserCard from "@/components/UserCard.vue";
import UserController from "@/controllers/UserController";
import AuthService from "@/services/AuthService";
import { storage } from "../firebase";
import axios from "axios"
import {
  getDownloadURL,
  uploadBytes,
  ref as storageRef,
  deleteObject,
} from "firebase/storage";


export default {
  name: "HomeView",
  components: {
    UserCard,
  },
  data() {
    return {
      storageUrl: "",
      courseName: "",
      userInfo: {
        personalCreds: {
          firstName: "",
          middleName: "",
          lastName: "",
          city: "",
          state: "",
          zipcode: "",
        },
        profilePic: "",
        

      },
      isEditing: false,
      // file: null,
     
    };
  },

  // watch:{
  //   file(newVal, oldVal){
  //     console.log("file Changed :", newVal)
  //   }
  // },

  methods: {
    async isPicValid(url){
      try{
        const res=await axios.get(url);
        if (res?.status!=200){
          this.userInfo.profilePic=""
          return false;
        }
        return true;
      } catch(err){
        // console.log(err)
        this.userInfo.profilePic=""
        return false;
      }
    },

    async getUserInfo() {
      var userData = await this.$store.dispatch("getUserInfo");
      // await this.$store.dispatch("getCourseData");
      this.courseName = localStorage.getItem("courseName");
      // console.log("priting course name:",this.courseName);
      this.storageUrl=userData?.storageUrl ?? ""
      // console.log("userData", userData);
    },
    toggleEditing() {
      this.isEditing = !this.isEditing; // Toggle the editing mode
    },

    async getUserInfoo() {
      try {
        const response = await UserController.getUserInfo();
        if (response.status === 200) {
          this.userInfo = response?.data?.data;
          // console.log("response printing :", this.userInfo);
        } else {
          this.logout();
        }
      } catch (error) {
        console.error("An error occurred:", error);
        this.logout();
      }
    },
    async saveDetails() {
      // Logic to save user details
      try {
        let userId = AuthService.getUserId();
        var response = await UserController.updateUserInfo(
          userId,
          this.userInfo
        );

        if (response.status === 200) {
          console.log("Data saved successfully!");
        } else {
          // console.error("Error saving data:", response.statusText);
        }
      } catch (error) {
        console.error("An unexpected error occurred:", error);
      }
      // Once details are saved, you may want to set isEditing back to false
      this.isEditing = false;
    },
    async handleImageUpload(event) {
      // console.log("chk1");
      const file = event.target.files[0];

      if (file) {
        // Handle the uploaded image file
        // console.log("Uploaded image:", file);

        // If you want to preview the image in the form
        const reader = new FileReader();
        reader.onload = (e) => {
          this.avatar = e.target.result;
        };
        reader.readAsDataURL(file);

        // Create a unique filename for the image
        const fileName = `${Date.now()}_${file.name}`;

        // Get a reference to the Firebase Storage
        // const storageRef = firebase.storage().ref().child('personProfilePic/' + fileName);
        const storageReff = storageRef(storage, "personProfilePic/" + fileName);

        // Upload the file to Firebase Storage
        await uploadBytes(storageReff, file);
        // const uploadTask = storageRef.put(file);
        const picUrl = await getDownloadURL(storageReff);
        await this.deleteStorageFile();
        // console.log("printing ..", picUrl);
        this.avatar = picUrl;
        this.userInfo.profilePic=picUrl
        // const storageUrl="personProfilePic/"+fileName
        // this.storageUrl=storageUrl;
      }
    },

    async deleteStorageFile(){
      try{
        // console.log("deleteStorageEntered", this.storageUrl)
        const fileRef=storageRef(storage, this.storageUrl);
        await deleteObject(fileRef);
      } catch(err){
        console.log(err);
      }
    },

  },


  mounted() {
    this.getUserInfo();
  },
  created() {
    // console.log("hello user");
    this.getUserInfoo();
  },
};
</script>
<style scoped>
/* .rounded-circle {
  border-radius: 5px;
} */
.v-application .rounded-circle {
    border-radius: 5% !important;
}

</style>
