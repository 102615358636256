<template>
  <div class="repUpDivRight">
    <div class="relative d-flex flex-row justify-center align-center w-25" @click="navigateTo('Videos')">
      <div class="pentagon-1 pentagon-h w-100">
        <div class="pentCont"></div>
      </div>
      <div class="pentagon-1 pentagon-s-h white">
        <div class="pentCont"></div>
      </div>
      <div class="pentagon-1 cursor pentagon-s-h" :class="getStep == 1 ? 'subject-secondary' : 'white'">
        <div class="pentCont w-100 d-flex mx-4">
          <b class="mt-3px">Videos</b>
          <v-icon class="subject-onColor-text" v-if="getStep == 1">mdi-dots-horizontal-circle</v-icon>
          <v-icon v-else :class="subTopicData?.videoCompleted ? 'completedColor' : ''">
            <template v-if="subTopicData?.videoCompleted">
              mdi-check-circle
            </template>
            <template v-else> mdi-check-circle-outline </template>
          </v-icon>
        </div>
      </div>


    </div>

    <div class="relative w-25 d-flex flex-row justify-center align-center" @click="navigateTo('PDFs')">
      <div class="pentagon pentagon-h w-100">
        <div class="pentCont">
          <b>PDFs</b>
        </div>
      </div>

      <div class="pentagon-s pentagon-s-h w-100 white">
        <div class="pentCont"></div>
      </div>

      <div class="pentagon-s cursor pentagon-s-h" :class="getStep == 2 ? 'subject-secondary' : 'white'">
        <div class="pentCont w-100 d-flex ml-7 mx-4">
          <b class="mt-3px">PDFs</b>
          <v-icon class="subject-onColor-text" v-if="getStep == 2">mdi-dots-horizontal-circle</v-icon>

          <v-icon v-else :class="subTopicData?.readCompleted ? 'completedColor' : ''" class=""><template
              v-if="subTopicData?.readCompleted">
              mdi-check-circle
            </template>
            <template v-else> mdi-check-circle-outline </template></v-icon>
        </div>
      </div>
    </div>

    <div class="relative w-25 d-flex flex-row justify-center align-center" @click="navigateTo('BrainBooster')">
      <div class="pentagon pentagon-h w-100">
        <div class="pentCont"></div>
      </div>

      <div class="pentagon pentagon-s pentagon-s-h w-100 white">
        <div class="pentCont"></div>
      </div>

      <div class="pentagon-s cursor pentagon-s-h" :class="getStep == 3 ? 'subject-secondary' : 'white'">
        <div class="pentCont ml-7 w-100 d-flex mx-4">
          <b class="mt-3px">Brain Booster</b>
          <v-icon class="subject-onColor-text" v-if="getStep == 3">mdi-dots-horizontal-circle</v-icon>

          <v-icon v-else :class="subTopicData?.bbCompleted ? 'completedColor' : ''" class="">
            <template v-if="subTopicData?.bbCompleted">
              mdi-check-circle
            </template>
            <template v-else> mdi-check-circle-outline </template></v-icon>
        </div>
      </div>
    </div>

    <div class="relative w-25 d-flex flex-row justify-center align-center" @click="navigateTo('Test')">
      <div class="pentagon-2 pentagon-h w-100">
        <div class="pentCont"></div>
      </div>

      <div class="pentagon-2 pentagon-s pentagon-s-h w-100 white">
        <div class="pentCont"></div>
      </div>

      <div class="pentagon-2 cursor pentagon-s-h" :class="getStep == 4 ? 'subject-secondary' : 'white'">
        <div class="pentCont ml-7 mr-7 w-100 d-flex">
          <b class="mt-3px">Test</b>
          <v-icon class="subject-onColor-text" v-if="getStep == 4">mdi-dots-horizontal-circle</v-icon>

          <!-- subject-secondary-text -->
          <v-icon v-else :class="subTopicData?.testCompleted ? 'completedColor' : ''" class=""><template
              v-if="subTopicData?.testCompleted">
              mdi-check-circle
            </template>
            <template v-else> mdi-check-circle-outline </template></v-icon>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CourseConsumptionController from "@/controllers/CourseConsumptionController";

export default {
  name: "UnitView",
  components: {},

  data() {
    return {
      subTopicData: [],
    };
  },
  computed: {
    getStep() {
      var name = this.$route.name;
      switch (name) {
        case "Video":
          return 1;
        case "content":
          return 2;
        case "BrainBooster":
          return 3;
        case "test":
          return 4;
        case "TestScreenView":
          return 4;
        case "PostTestReportShowView":
          return 4;
      }
      return 1;
    },
  },
  methods: {
    async getTopicData() {
      var subjectId = this.$route.query?.subjectId || 0;
      var chapterId = this.$route.query?.chapterId || 0;
      var unitId = this.$route.query?.unitId || 0;
      var topicId = this.$route.query?.topicId || 0;
      var subTopicId = this.$route.query?.subTopicId || 0;

      var courseId = this.$route.query.course;

      try {
        const response = await CourseConsumptionController.getCourse(courseId);
        const courseData = response?.data?.data;
        this.$store.state.courseData[0] = courseData;
        this.subTopicData =
          courseData.subjects[subjectId].units[unitId].chapters[
            chapterId
          ].topics[topicId].subTopics[subTopicId];
      } catch (err) {
        console.log(err);
      }
    },
    
    navigateTo(pageName) {
  const pastQuery = this.$route.query;
  if (this.subTopicData?.testCompleted && pageName === "Test") { 
    this.$router.push({
      path: "/post-test-report",
      query: pastQuery,
    });
    return;
  }

  const routes = {
    "Videos": { name: "Video", check: 'video' },
    "PDFs": { name: "content", check: 'pdf' },
    "BrainBooster": { name: "BrainBooster", check: 'brainBooster' },
    "Test": { name: "test" }
  };

  const route = routes[pageName];
  if (route && !this.$route.path.includes(route.check)) {
    this.$router.push({
      name: route.name,
      query: pastQuery,
    });
  }
}

  },
  mounted() {
    this.getTopicData();
  },
};
</script>
