import axios from 'axios'
import AuthService from '@/services/AuthService';
const instance = axios.create({
    baseURL: process.env.VUE_APP_USERS_API_URL
});
// const instance = axios.create({
//     baseURL: 'http://localhost:3000/v1/'
// });

export default {




    getUserInfo: async function () {
        const userId = AuthService.getUserId()
        try {
            const response = await instance.get('/user-info?userId=' + userId, {
                headers: {
                    'Authorization': AuthService.getToken()
                }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },
    saveUserInfo: async function (data) {
        let userId = AuthService.getUserId()
        try {
            const response = await instance.post('personal-info?userId=' + userId, data,
                {
                    headers: {
                        'Authorization': AuthService.getToken()
                    }
                }
            )
            return response;
        } catch (error) {
            return error.response;
        }
    },


    enrollProgram: async function (data) {
        let userId = AuthService.getUserId()
        try {
            const response = await instance.post('enroll-program?userId=' + userId, data,
                {
                    headers: {
                        'Authorization': AuthService.getToken()
                    }
                }
            )
            return response;
        } catch (error) {
            return error.response;
        }
    },

    updateUserInfo: async function (userId, data) {
        try {
            const response = await instance.patch(`update-user-info?userId=${userId}`, data, {
                headers: {
                    'Authorization': AuthService.getToken()
                }
            });
            return response;
        } catch (error) {
            return error.response;
        }
    },


}