<template>
    <div class="app-bar white">
      <img class="logo" src="../assets/doo.png" alt="Logo" />
      <!-- <UserCard/> -->
      <div class="changesubject pa-0">    <ChangeSubjectViewVue/></div>
     
   
      <v-card width="100%" color="" class="elevation-0 app-bar-class rounded-0 pr-6">
          <AppBar />
        </v-card>
    </div>
  </template>
  
  <script>

import AppBar from "./AppBar.vue";
import ChangeSubjectViewVue from './ChangingSubjectBar.vue';

  export default {
    components: {
        AppBar,
        ChangeSubjectViewVue
    }
  }
  </script>
  
  <style scoped>
  .app-bar {
    height: 75px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    border-bottom: 2px solid #B8B8B8 !important;
  }
  
  .logo {
    height: 55px; /* Adjust the height of the logo */
  }
  .app-bar-class{
    margin-top: -10px;
  }
  .changesubject{
    margin-left: 60px;
    margin-top: 10px;
  }
  </style>
  