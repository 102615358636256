<template>
  <!-- fixed-top -->
  <div class="homePage fixed-top relative d-flex flex-row">
    <div class="d-flex flex-row w-100">
      <!-- leftnav card -->
      <v-card class="leftSide rounded-0">
        <NavDrawer />
      </v-card>
      <!-- home page main -->
      <v-card class="homeMain overflow-y-scroll m-n8 rounded-0 elevation-0">
        <v-card
          class="d-flex flex-row align-center justify-space-between elevation-0"
        >
          <div class="text-h5 font-weight-medium ml-5 mt-4">Ask your Doubts</div>
          <div class="d-flex flex-row align-center">
            <v-btn
              height="32px"
              width="164px"
              class="rounded mr-12 disabled-border primary"
              outlined
              @click="openDoubtDialog"
            >
              <span class="white--text text-subtitle-2 font-weight-medium">
                + Post your doubt
              </span>
            </v-btn>
            <UserCard class="mr-4 mt-4" />
          </div>
        </v-card>

        <div class="w-100 px-8 mt-8 pb-8">
          <!-- doughtcard -->
          <v-card
            width="100%"
            class="rounded-xxl doughtCard px-12 py-6 mt-8 elevation-0"
            v-for="i in 2"
            :key="i"
          >
            <div class="d-flex flex-row justify-space-between">
              <div class="text-subtitle-1 font-weight-medium">Question 1</div>
              <div class="">
                <v-btn
                  height="25px"
                  width="104px"
                  class="rounded primary white--text elevation-0 mr-8"
                >
                  <span class="text-button font-weight-medium">Edit</span>
                  <v-icon size="20" class="ml-2">mdi-pencil</v-icon></v-btn
                >
                <v-btn
                  height="25px"
                  width="104px"
                  class="rounded primary white--text elevation-0"
                >
                  <span class="text-button font-weight-medium">Delete</span>
                  <v-icon size="20" class="ml-2"
                    >mdi-trash-can-outline</v-icon
                  ></v-btn
                >
              </div>
            </div>

            <!-- question card -->
            <div class="w-100 text--secondary text-body-1">
              Solve the following system of equations:<br />
              2x+3y=7<br />
              4x - y=5<br />
              Options:<br />
              A x=1, y=2<br />
              B x=2, y=1<br />
              Cx=3, y=1<br />
              Dx=1, y=3<br />
            </div>

            <div class="text-caption font-weight-bold mt-4">
              Answered by
              <span class="primary--text">Ayaan Dutt, 2 hours ago</span>
            </div>
            <v-divider class="mt-2"></v-divider>
            <!-- ansewer card -->

            <div v-if="showAnswer" class="d-flex flex-column">
              <h6 class="text-subtitle-1 black--text font-weight-regular mt-2">
                Solution:
              </h6>
              <div class="w-100 text--secondary text-body-1">
                We can solve this system of equations using either the
                substitution or elimination method.<br />
                Let's use the elimination method:<br />
                Multiply the second equation by 3 to make the coefficients of y
                in both equations equal:<br />
                2x+3y=7<br />
                12x - 3y=15<br />
                Now, add the two equations to eliminate y:<br />
                (2x+3y)+(12x - 3y)=7+15<br />
                14x=22<br />
                x=7/11<br />
                Now substitute the value of x back into one of the original
                equations, let's use the first one:<br />
                2(11/7)+3y=7<br />
                y= 22/7<br />
                Therefore, the solution is x=11/7 and y=21/13, which is not
                among the given options. However, you can simplify the fractions
                and check for the closest match among the options.<br />
              </div>
            </div>

            <div class="mt-4 d-flex flex-row justify-space-between">
              <div>
                <v-btn
                  height="42"
                  outlined
                  class="disabed-border rounded-xl mr-8"
                  @click="showAnswer = !showAnswer"
                >
                  <span class="text--secondary">
                    <v-icon class="mr-2">mdi-message-badge-outline</v-icon>
                    <span
                      class="text-subtitle-2 font-weight-medium doubts--text"
                    >
                      Answer 2
                    </span>
                  </span></v-btn
                >
                <v-btn outlined height="42" class="disabed-border rounded-xl"
                  ><span class="text--secondary"
                    ><v-icon class="mr-2">mdi-arrow-up-bold-outline</v-icon>
                    <span
                      class="text-subtitle-2 font-weight-medium doubts--text"
                    >
                      Upvote 5
                    </span>
                  </span>
                </v-btn>
              </div>

              <v-btn icon><v-icon>mdi-share-variant-outline</v-icon></v-btn>
            </div>
          </v-card>
        </div>
      </v-card>
    </div>
    <v-dialog v-model="doubtDialog" width="769" height="370" >
      <v-card>
        <v-card-title class="d-flex justify-space-between align-center">
          <div></div>
          <span class="text-h6 ">Post Your Doubt</span>
          <v-btn icon @click="doubtDialog = false" >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <div class="text-body-1 black--text mb-2">Your Doubt</div>
              </v-col>
            </v-row>
            <ckeditor
              v-model="doubts"
              :config="editorConfigTestInstructions"
              class="m-ckeditor"
              :rules="[(v) => !!v || 'Pre-Test Instruction is required']"
              required
              style="height: 200px;"
            >
            </ckeditor>
            <div class="d-flex justify-center">
              <v-btn
                height="48px"
                width="257px"
                class="rounded mr-12 disabled-border primary mt-3"
                outlined
                @click="submitButton"
              >
                <span class="white--text text-subtitle-2 font-weight-medium">
                  Submit
                </span>
              </v-btn>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  
  <script>

import UserCard from "@/components/UserCard.vue";
import CourseConsumptionController from "@/controllers/CourseConsumptionController";
// import { VSkeletonLoader } from "vuetify/lib";
// import ProgressBar from "vuejs-progress-bar";
// import GraphView from "../components/GraphView.vue";

export default {
  name: "HomeView",
  components: {

    UserCard,
  },
  data() {
    return {
      showAnswer: false,
      doubtDialog: false,
      editorConfigTestInstructions: {
        extraPlugins: "ckeditor_wiris",
      },
      doubts: "",
    };
  },

  methods: {
    openDoubtDialog() {
      this.doubtDialog = true;
    },
    async submitButton() {
      // console.log("myExperties2 :", this.myExperties);
      const formData = {
        Doubtquestion: this.doubts,
      
      };
      // let userId = AuthService.getUserId();
      try {
        const response = await CourseConsumptionController.submitUserInfo(formData);

        if (response.status === 200) {
          // console.log("Data saved successfully!");
          this.doubts = "";
      this.doubtDialog = false;
        
        } else {
          console.error("Error saving data:", response.statusText);
        }
      } catch (error) {
        console.error("An unexpected error occurred:", error);
      }
    },
  },
  mounted() {},
};
</script>
  