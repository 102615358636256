<template>
  <!-- fixed-top -->
  <div class="homePage fixed-top relative d-flex flex-row">
    <div class="d-flex flex-row w-100">
      <!-- leftnav card -->
      <v-card class="leftSide rounded-0">
        <NavDrawer />
      </v-card>
      <!-- home page main -->
      <v-card class="homeMain overflow-y-scroll m-n8 rounded-0 elevation-0">
        <v-card
          width="100%"
          height="76px"
          class="d-flex rounded-0 flex-row justify-space-between relative elevation-0"
        >
          <h5 class="align-end mt-n3 d-flex text-h5 font-weight-medium ml-5">
            Leaderboard
          </h5>
          <!-- <div class="relative"> -->
          <UserCard style="top: 0px; right: 0px" class="mr-4 mt-4 absolute" />
          <!-- </div> -->
        </v-card>

        <div class="align-end d-flex flex-row justify-center leaders-card">
          <v-card
            width="66%"
            height="66%"
            class="elevation-0 align-end d-flex flex-row"
          >
            <v-card
              width="33.3%"
              class="blue d-flex Surfaceshade3 rounded-0 rounded-l-xl flex-column justify-space-around pt-4 pb-1 align-center elevation-0 relative"
              height="70%"
            >
              <div class="profilePic leadderImage">
                <div class="profilePic rounded-pill overflow-hidden">
                  <img width="100%" height="auto" src="../assets/userpic.jpg" />
                </div>
              </div>
              <h4 class="text-h4 font-weight-600 primary--text">2</h4>
              <div>
                <p class="text-subtitle-2 mb-0 font-weight-medium">Rohit Malhotra</p>
                <p
                  class="text-subtitle-1 mb-0 text-center font-weight-bold mt-0 light-blue--text"
                >
                  1847
                </p>
              </div>
            </v-card>
            <v-card
              width="33.3%"
              class="green d-flex rounded-t-xl rounded-0 topper flex-column flex-column justify-space-around pt-4 pb-1 align-center elevation-0 relative"
              height="100%"
            >
              <div class="profilePic d-flex justify-center leadderImage">
                <div class="profilePic rounded-pill overflow-hidden">
                  <img width="100%" height="auto" src="../assets/userpic.jpg" />
                </div>
                <img
                  width="80%"
                  class="leaderCrown"
                  height="auto"
                  src="../assets/crown.svg"
                />
              </div>
              <h4 class="text-h4 font-weight-600 primary--text">1</h4>

              <div>
                <p class="text-subtitle-2 font-weight-medium mb-0">Ritik Verma</p>
                <p
                  class="text-center text-subtitle-1 font-weight-bold mt-0 orange--text"
                >
                  1847
                </p>
              </div>
            </v-card>
            <v-card
              width="33.3%"
              class="Surfaceshade3 rounded-r-xl rounded-0 d-flex flex-column justify-space-around pt-4 pb-1 align-center elevation-0 relative"
              height="70%"
            >
              <div class="profilePic leadderImage">
                <div class="profilePic rounded-pill overflow-hidden">
                  <img width="100%" height="auto" src="../assets/userpic.jpg" />
                </div>
              </div>
              <h4 class="text-h4 font-weight-600 primary--text">3</h4>

              <div>
                <p class="text-subtitle-2 font-weight-medium mb-0">Cyril Matthew</p>
                <p
                  class="text-center text-subtitle-1 font-weight-bold mt-0 mb-0 green--text"
                >
                  1847
                </p>
              </div>
            </v-card>
          </v-card>
        </div>
        <!-- leader ranking cards -->

        <div class="w-100 leaderListCard">
          <v-card
            width="100%"
            id="myScrolll"
            class="leaderRankingCard Surfaceshade3 rounded-xxl elevation-0 px-8"
          >
            <!-- <div class="pa-8"> -->

            <div
              v-for="i in 8"
              :key="i"
              class="d-flex flex-row leaderrow rounded-0"
            >
              <h4
                class="text-h5 primary--text font-weight-bold mr-8 d-flex align-center"
              >
                {{ i + 3 }}
              </h4>
              <v-row>
                <v-col cols="6" class="d-flex align-center">
                  <img width="50px" height="auto" src="../assets/userpic.svg" />
                  <span class="ml-6 text-subtitle-2 font-weight-medium"
                    >Jack</span
                  >
                </v-col>
                <v-col cols="6" class="d-flex pr-10 align-center justify-end">
                  <div class="d-flex mr-12 pr-8 flex-column w-fit-content mt-2">
                    <div class="w-fit-content text-subtitle-2 font-weight-bold">
                      1134
                    </div>
                    <v-icon class="green--text mt-n2" size="28"
                      >mdi-menu-up</v-icon
                    >
                  </div>
                </v-col>
              </v-row>
            </div>
            <!-- </div> -->
          </v-card>
        </div>
      </v-card>
    </div>
  </div>
</template>
 
 
 <script>

import UserCard from "@/components/UserCard.vue";
// import { VSkeletonLoader } from "vuetify/lib";
// import ProgressBar from "vuejs-progress-bar";
// import GraphView from "../components/GraphView.vue";

export default {
  name: "HomeView",
  components: {
    UserCard,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
 