import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import VueCountryCode from "vue-country-code-select";
import NavDrawer from './components/NavDrawer.vue';
import store from './store';
import  './firebase.js';
import CKEditor from "ckeditor4-vue"

Vue.use(VueCountryCode);
Vue.use(CKEditor)
Vue.component('NavDrawer', NavDrawer);

Vue.config.productionTip = false
new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
