<template>
  <div class="sub-topic-instruction-screen pb-0 mt-0">
    <div>
      <div class="text-subtitle-1 d-flex ml-3 mb-2 cursor">
        <v-icon @click="goToRoute('homeView', {})" class="unitss" size="20"
          >mdi-home</v-icon
        >

        <div class="font-weight-medium">
          > <span class="unitss mr-1">{{ subject }}</span> >
          <span
            class="unitss mr-1"
            @click="
              goToRoute('NoUnitSelected', {
                subjectId: $route.query?.subjectId,
                subject: $route.query?.subject,
              })
            "
            >Units</span
          >
          >
          <span class="unitss ml-1 mr-1">Chapters</span> >
          <span
            class="unitss ml-1 mr-1"
            @click="goToRoute('Topics', $route.query)"
            >Topics</span
          >
          >
          <span
            class="unitss ml-1 mr-1"
            @click="goToRoute('SubTopics', $route.query)"
            >Sub Topics</span
          >
          >
          <span class="topicss">Test</span>
        </div>
      </div>
      <div class="pl-4 w-100">
        <PolygonStepperViewVue />
      </div>
      <div v-if="noQuestion">
        <div class="question-container ml-4 mr-5">Questions not Available</div>
        <div class="ml-4 mt-4 d-flex flex-row">
          <v-btn
            @click="goToRoute('BrainBooster', $route.query)"
            class="white onColor-T onColorBorder elevation-0 rounded-lg button-sub-unit"
            >BACK</v-btn
          >
          <v-btn
            @click="goToRouterByName('Video', 'next')"
            class="mustardd rounded-lg white--text elevation-0 button"
          >
            GO TO NEXT SUB TOPIC
          </v-btn>
        </div>
      </div>

      <div v-else class="d-flex justify-center align-center">
        <div class="test-instructions-subtopic pa-2">
          <div class="text-h5 text-center mt-1 font-weight-medium">
            Exercise Instructions
          </div>
          <div class="text-h6 text-center mt-3 mb-1">
            Please read the following instructions carefully
          </div>
          <div
            v-if="courseData?.length > 0"
            class="custom-color-text ml-5 mt-2 mb-2"
            v-html="courseData[0]?.testinstruction"
          ></div>
          <div class="d-flex justify-center align-center">
            <v-btn
              @click="startTesting"
              class="white--text onColor-B rounded-lg elevation-0 mb-2"
              height="55px"
              width="250px"
            >
              START TEST
            </v-btn>
          </div>
        </div>
      </div>

      <div
        v-if="isFullScreen"
        class="upper-divs"
        :class="{ 'full-screen': isFullScreen }"
      >
        <div v-if="isFullScreen" class="mt-5">
          <img class="logo-test-screen" src="../assets/doo.png" alt="Logo" />
          <!-- <div class="exit-fullscreen-btn cursor" @click="exitFullScreen">
            <img src="../assets/exitFUllScreen.svg" />
          </div> -->
        </div>

        <!-- <button class="exit-fullscreen-btn" @click="exitFullScreen">Exit Full Screen</button> -->
        <div class="w-40 d-flex justify-center mb-4 step-progress-style">
          <StepProgressBarUnlock
            class="step-progress"
            :selectedQuestion="selectedQuestion"
            :questions="questions"
          />
        </div>

        <div class="flex-container spacingforques">
          <div class="cursor mt-1" @click="openDialog">
            <img src="../assets/crossIcon.svg" />
          </div>
          <div
            v-if="questions.length > 0"
            class="text-h5 ml-3 font-weight-medium"
          >
            Question {{ this.selectedQuestion + 1 }}
          </div>
        </div>

        <div v-if="questions.length > 0" class="test-instructions">
          <!-- <button
            class="triangle-btn backButton-test-screen"
            v-if="selectedQuestion > 0"
            @click="selectedQuestion--"
          ></button> -->

          <div
            v-html="questions[this.selectedQuestion]?.questionStatement"
            class="text-subtitle-1 heelo ma-4 questionStatement"
          ></div>

          <!-- Display image or video if available -->
          <div
            v-if="questions[this.selectedQuestion]?.questionAssetUrl !== 'NA'"
            class="ma-4"
          >
            <template v-if="isImageLoaded">
              <img
                :src="questions[this.selectedQuestion]?.questionAssetUrl"
                alt="Question Image"
                width="100%"
                height="auto"
              />
            </template>
            <template v-else>
              <video controls width="100%" height="auto">
                <source
                  :src="questions[this.selectedQuestion]?.questionAssetUrl"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
            </template>
          </div>
        </div>

        <div class="margin-bottom-fixing">
          <!-- <div class="option-container">
            <v-radio-group
              hide-details
              v-model="questions[selectedQuestion].myAnswer"
            >
             
              <div class="row">
                <div
                  v-for="(option, index) in questions[
                    selectedQuestion
                  ].questionOptions.slice(0, 2)"
                  :key="index"
                  @click="handleParentClick(index)"
                  id="parent"
                  class="col cursor abc-1 d-flex align-center mb-4 pa-4"
                >
                  <v-tooltip top :content="option.optionValue">
                    <template v-slot:activator="{ on, attrs }">
                      <v-radio
                        color="var(--subject-onColor)"
                        v-bind="attrs"
                        id="child"
                        :value="
                          questions[selectedQuestion].questionOptions[index]
                            .optionKey
                        "
                        class="mt-2"
                        @click="setOption(option.optionKey)"
                        v-on="shouldApplyHandlers(option.optionValue) ? on : {}"
                      >
                        <template v-slot:label>
                          <div v-if="shouldApplyHandlers(option.optionValue)" class="text-container">
                            {{ option.optionValue }}
                          </div>
                          <div
                            v-else
                            class="questionStatement"
                            v-html="option.optionValue"
                          ></div>
                        </template>
                        
                      </v-radio>
                    </template>
                    <div class="font-14 tooltip-content">
                      {{ option.optionValue }}
                    </div>
                  </v-tooltip>
                </div>
              </div>
           
              <div class="row">
                <div
                  v-for="(option, index) in questions[
                    selectedQuestion
                  ].questionOptions.slice(2, 4)"
                  :key="index"
                  @click="handleParentClick(index + 2)"
                  id="parent"
                  class="col cursor abc-1 d-flex align-center mb-4 pa-4"
                >
                  <v-tooltip top :content="option.optionValue">
                    <template v-slot:activator="{ on, attrs }">
                      <v-radio
                        color="var(--subject-onColor)"
                        v-bind="attrs"
                        id="child"
                        :value="
                          questions[selectedQuestion].questionOptions[index + 2]
                            .optionKey
                        "
                        class="mt-2"
                        @click="setOption(option.optionKey)"
                        v-on="shouldApplyHandlers(option.optionValue) ? on : {}"
                      >
                        <template v-slot:label>
                          <div v-if="shouldApplyHandlers(option.optionValue)" class="text-container">
                            {{ option.optionValue }}
                          </div>
                          <div
                            v-else
                            class="questionStatement"
                            v-html="option.optionValue"
                          ></div>
                        </template>
                      </v-radio>
                    </template>
                    <div class="font-14 tooltip-content">
                      {{ option.optionValue }}
                    </div>
                  </v-tooltip>
                </div>
              </div>
            </v-radio-group>
          </div> -->

          <div class="option-container">
            <v-radio-group
              hide-details
              v-model="questions[selectedQuestion].myAnswer"
            >
              <!-- First Row -->
              <div class="row">
                <div
                  v-for="(option, index) in questions[
                    selectedQuestion
                  ].questionOptions.slice(0, 2)"
                  :key="index"
                  @click="handleParentClick(index)"
                  id="parent"
                  class="col cursor abc-1 d-flex align-center mb-4 pa-4"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-radio
                        color="var(--subject-onColor)"
                        v-bind="attrs"
                        id="child"
                        :value="
                          questions[selectedQuestion].questionOptions[index]
                            .optionKey
                        "
                        class="mt-2"
                        @click="setOption(option.optionKey)"
                        v-on="shouldApplyHandlers(option.optionValue) ? on : {}"
                      >
                        <template v-slot:label>
                          <!-- Check if the option value is an image URL -->
                          <div v-if="isImageUrl(option.optionValue)">
                            <img
                              :src="option.optionValue"
                              alt="Option Image"
                              width="70px"
                              height="50px"
                              @click.stop="showImage(option.optionValue)"
                              class="clickable-image mt-1"
                            />
                          </div>
                          <!-- Otherwise, display text -->
                          <div v-else class="text-container">
                            {{ option.optionValue }}
                          </div>
                        </template>
                      </v-radio>
                    </template>

                    <!-- Show tooltip message based on whether it's an image -->
                    <div class="font-14 tooltip-content">
                      {{
                        isImageUrl(option.optionValue)
                          ? "Click on the image to preview it"
                          : option.optionValue
                      }}
                    </div>
                  </v-tooltip>
                </div>
              </div>

              <!-- Second Row -->
              <div class="row">
                <div
                  v-for="(option, index) in questions[
                    selectedQuestion
                  ].questionOptions.slice(2, 4)"
                  :key="index"
                  @click="handleParentClick(index + 2)"
                  id="parent"
                  class="col cursor abc-1 d-flex align-center mb-4 pa-4"
                >
                  <v-tooltip top >
                    <template v-slot:activator="{ on, attrs }">
                      <v-radio
                        color="var(--subject-onColor)"
                        v-bind="attrs"
                        id="child"
                        :value="
                          questions[selectedQuestion].questionOptions[index + 2]
                            .optionKey
                        "
                        class="mt-2"
                        @click="setOption(option.optionKey)"
                       v-on="shouldApplyHandlers(option.optionValue) ? on : {}"
                      >
                        <template v-slot:label>
                          <!-- Check if the option value is an image URL -->
                          <div v-if="isImageUrl(option.optionValue)">
                            <img
                              :src="option.optionValue"
                              alt="Option Image"
                              width="70px"
                              height="50px"
                              @click.stop="showImage(option.optionValue)"
                              class="clickable-image mt-1"
                            />
                          </div>
                          <!-- Otherwise, display text -->
                          <div v-else class="text-container">
                            {{ option.optionValue }}
                          </div>
                        </template>
                      </v-radio>
                    </template>
                    <!-- Show tooltip message based on whether it's an image -->
      <div class="font-14 tooltip-content">
        {{ isImageUrl(option.optionValue) ? 'Click on the image to preview it' : option.optionValue }}
      </div>
                  </v-tooltip>
                </div>
              </div>
            </v-radio-group>

            <!-- Image Dialog -->
            <v-dialog v-model="dialoging" max-width="500px">
              <v-card>
                <v-card-title class="headline">Image Preview</v-card-title>
                <v-card-text>
                  <img
                    :src="dialogImageUrl"
                    alt="Enlarged Option Image"
                    width="100%"
                  />
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    color="var(--subject-onColor)"
                    text
                    @click="dialoging = false"
                    >Close</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>

          <div class="mb-1">
            <v-btn
              v-if="selectedQuestion > 0"
              @click="selectedQuestion--"
              class="previous-class"
              height="48px"
              width="190px"
            >
              Previous
            </v-btn>

            <v-btn
              v-if="selectedQuestion < questions.length - 1"
              @click="
                selectedQuestion++;
                currentAnswerSelected = false;
              "
              class="color-selected"
              :class="{
                'color-selected':
                  questions[selectedQuestion].myAnswer.length > 0,
              }"
              :disabled="
                currentAnswerSelected === false &&
                questions[selectedQuestion].myAnswer?.length === 0
              "
              style="border-radius: 10px; margin-left: 15px"
              height="48px"
              width="190px"
            >
              NEXT
            </v-btn>
            <v-btn
              v-if="selectedQuestion < questions.length - 1"
              @click="selectedQuestion++"
              class="submit-button-test-screen elevation-0"
              height="48px"
              width="190px"
            >
              Skip >>
            </v-btn>
            <v-btn
              @click="testpage"
              :class="{ 'color-selected': row1 != null }"
              class="color-selected submit-button-test"
              style="border-radius: 10px"
              height="48px"
              width="190px"
            >
              Submit Test
            </v-btn>
          </div>
        </div>

        <!-- Dialog box -->
        <v-dialog v-model="dialogVisible" max-width="400" persistent>
          <v-card class="dialog-card">
            <v-card-title class="text-center center-class"
              >Exit Test</v-card-title
            >
            <v-card-text class="text-h5 text-center">
              Do you want to quit the test?
            </v-card-text>
            <v-card-actions class="justify-center">
              <v-btn class="no-class" @click="closeDialog">No</v-btn>
              <v-btn class="color-selected yes-class" @click="exitTest"
                >Yes</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import PolygonStepperViewVue from "./PolygonStepperView.vue";
import { myMixin } from "../myMixin.js";
import CourseConsumptionController from "@/controllers/CourseConsumptionController";
import StepProgressBarUnlock from "./StepProgressBarUnlock.vue";

export default {
  name: "InstructionPage",
  mixins: [myMixin],
  components: {
    PolygonStepperViewVue,
    StepProgressBarUnlock,
  },
  data() {
    return {
      subject: "",
      course: [],
      noQuestion: false,
      courseData: [],
      isFullScreen: false,
      selectedQuestion: 0,
      questions: [],
      isLoading: 0,
      row1: null,
      row2: null,
      isImageLoaded: false,
      dialogVisible: false,
      currentAnswerSelected: false,
      dialoging: false,
      dialogImageUrl: "",
    };
  },
  watch: {
    selectedQuestion() {
      this.checkIfImageLoaded();
    },

    questions(val) {
      console.log("val :", val);
    },
  },

  computed: {
    //   isOptionSelected() {
    //   const myAnswer = this.questions[this.selectedQuestion].myAnswer;
    //   console.log("Is Option Selected:", myAnswer.length > 0);
    //   return myAnswer.length > 0;
    // }
  },

  methods: {
    handleParentClick(index) {
      const radioButton = this.$el.querySelectorAll("#parent #child")[index];
      if (radioButton) {
        radioButton.click();
      }
    },
    isImageUrl(value) {
      // Check if the value is a valid image URL (you can expand the check based on your needs)
      return value.match(/\.(jpeg|jpg|gif|png|svg)$/) != null;
    },
    showImage(imageUrl) {
      // Open the dialog and set the image URL
      this.dialogImageUrl = imageUrl;
      this.dialoging = true;
    },
    async getSubTopicData() {
      try {
        const response = await CourseConsumptionController.getEnrolledCourses();
        if (response.status === 200) {
          this.courseData = response.data.data;
        }
      } catch (error) {
        // Handle the error
        console.error("Error fetching sub-topic data:", error);
      }
    },
    setOption(value) {
      this.questions[this.selectedQuestion].myAnswer = value;
      this.currentAnswerSelected = true;
      // console.log("this.questions2 :", this.questions, value);
    },
    openDialog() {
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
    // openDialog() {
    //   this.dialogVisible = true;
    // },
    // closeDialog() {
    //   this.dialogVisible = false;
    // },
    shouldApplyHandlers(optionValue) {
      return !optionValue.includes("<p>");
    },
    truncateText(text, limit) {
      if (text.length > limit) {
        return text.substring(0, limit) + "...";
      }
      return text;
    },
    async testpage() {
      // await new Promise((resolve) => setTimeout(resolve, 1000));
      var payload = this.getRouterQuery();
      // this.$router.push("/post-test-report");
      var payload2 = { ...payload, userResponses: this.questions };
      const responce =
        await CourseConsumptionController.submitSubTopicTestQuestion(payload2);
      if (responce.status == 200) {
        var pastQuery = this.$route.query;
        this.updateCompleteStatus("test");
        this.$router.push({
          name: "PostTestReportShowView",
          query: pastQuery,
        });
      } else {
        alert("Something went wrong");
      }
      this.exitFullScreen();
    },

    async getSubTopicDataQuestions() {
      try {
        var payload = this.getRouterQuery();
        const response = await CourseConsumptionController.getTopicQuestions(
          payload
        );
        if (response.status === 200) {
          this.questions = response?.data?.data || [];

          this.isLoading = false;
          this.questions.forEach((obj) => {
            obj.myAnswer = [];
          });
        } else {
          this.noQuestion = true;
        }
      } catch (error) {
        // Handle the error
        console.error("Error fetching sub-topic questions:", error);
      }
    },

    checkIfImageLoaded() {
      // console.log("rfbfrj");
      const img = new Image();
      // console.log("chk1:",img);
      img.src = this.questions[this.selectedQuestion]?.questionAssetUrl;
      img.onload = () => {
        this.isImageLoaded = true;
      };
      img.onerror = () => {
        this.isImageLoaded = false;
      };
    },

    toggleFullScreen() {
      // Toggle full-screen mode
      // this.isFullScreen = !!document.fullscreenElement;
      if (!this.isFullScreen) {
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen();
        } else if (document.documentElement.msRequestFullscreen) {
          document.documentElement.msRequestFullscreen();
        }
        this.isFullScreen = true;
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
        this.isFullScreen = false;
      }

      // If entering full-screen mode, request full-screen
      // if (this.isFullScreen) {
      //   const element = document.querySelector(".upper-divs");
      //   if (element.requestFullscreen) {
      //     element.requestFullscreen();
      //   } else if (element.mozRequestFullScreen) {
      //     element.mozRequestFullScreen();
      //   } else if (element.webkitRequestFullscreen) {
      //     element.webkitRequestFullscreen();
      //   } else if (element.msRequestFullscreen) {
      //     element.msRequestFullscreen();
      //   }
      // } else {
      //   // If exiting full-screen mode, exit full-screen
      //   if (document.exitFullscreen) {
      //     document.exitFullscreen();
      //   } else if (document.mozCancelFullScreen) {
      //     document.mozCancelFullScreen();
      //   } else if (document.webkitExitFullscreen) {
      //     document.webkitExitFullscreen();
      //   } else if (document.msExitFullscreen) {
      //     document.msExitFullscreen();
      //   }
      // }
    },

    async exitTest() {
      this.exitFullScreen();
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // var pastQuery = this.$route.query;
      // this.$router.push({
      //   name: "BrainBooster",
      //   query: pastQuery,
      // });
    },

    // toggleFullScreen() {
    //   // Toggle full-screen mode
    //   this.isFullScreen = !this.isFullScreen;

    //   // If entering full-screen mode, request full-screen
    //   if (this.isFullScreen) {
    //     const element = document.querySelector(".upper-divs");
    //     if (element.requestFullscreen) {
    //       element.requestFullscreen();
    //     } else if (element.mozRequestFullScreen) {
    //       element.mozRequestFullScreen();
    //     } else if (element.webkitRequestFullscreen) {
    //       element.webkitRequestFullscreen();
    //     } else if (element.msRequestFullscreen) {
    //       element.msRequestFullscreen();
    //     }
    //   } else {
    //     // If exiting full-screen mode, exit full-screen
    //     if (document.exitFullscreen) {
    //       document.exitFullscreen();
    //     } else if (document.mozCancelFullScreen) {
    //       document.mozCancelFullScreen();
    //     } else if (document.webkitExitFullscreen) {
    //       document.webkitExitFullscreen();
    //     } else if (document.msExitFullscreen) {
    //       document.msExitFullscreen();
    //     }
    //   }
    // },
    startTesting() {
      this.dialog = true;
      this.toggleFullScreen();
      // this.$nextTick(() => {
      //   this.toggleFullScreen();
      // });
    },

    exitFullScreen() {
      this.isFullScreen = false;
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    },
  },

  mounted() {
    this.subject = this.$route.query.subject;
    // console.log("test instruction", this.$store.state.courseData[0]);

    this.getSubTopicData();
    this.getSubTopicDataQuestions();
  },
};
</script>
<style scoped>
.onColorBorder {
  border: 1px solid var(--subject-onColor) !important;
}

.theme--light.v-btn.v-btn--has-bg {
  background-color: #9e9e9e;
}

.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 4px;
}

.text-container {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Show only 2 lines */
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  max-height: 3em; /* Adjust based on your line height */
}
.dialog-card {
  width: 400px;
  height: 200px;
}

.center-class {
  justify-content: center !important;
}

.text-center {
  text-align: center;
}

.abc-1 {
  height: 58px;
  /* width: calc(80% - 20px); */
  width: 650px;
  max-width: 650px;
  background-color: #d5d5d5bf;
  align-content: center;
  border-radius: 16px;
  /* justify-content: center; */
  /* margin-left: 30px; */
}

.upper-divs {
  /* background-color: #00e668; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.spacingforques {
  margin-left: 5%;
}

.option {
  width: 390px;
  height: 58px;
  background-color: #606060;
  margin-bottom: 10px;
  /* Add some spacing between options if needed */
}

.margin-bottom-fixing {
  margin-top: 5% !important;
  /* border: 1px solid red; */
  width: 1320px;
  max-width: 1320px;
  height: 200px;
  margin: 0 auto;
  position: relative;
  /* margin-left: 17%; */
}

.row {
  display: flex;
}

.flex-container {
  display: flex;
  align-items: center;
  /* Align items vertically */
}

/* .col {
  flex: 1;
  margin-right: 80px;
  
} */
.col {
  flex: 1;
  margin-right: 28px;
  margin-left: 28px;
}

/* .col:not(:first-child) {
  margin-right: 57px;
  margin-left: 10px;
} */

.step-progress {
  position: inherit !important;
  width: 80%;
}

.heelo {
  text-align: center;
  margin-top: 15px;
}

.step-progress-style {
  position: relative;
  /* Change to relative position */
  left: 51%;
  /* Center horizontally */
  transform: translateX(-50%);
  /* Center horizontally */
}

.button-container {
  display: flex;
}

.test-instructions {
  position: relative;
  width: 90%;
  /* Adjust the width as needed for responsiveness */
  max-width: 1500px;
  /* Limit the maximum width to 900px */
  border-radius: 4px;
  box-shadow: 0px 1px 4px 0px #666666;
  margin: 20px auto;
  height: 30%;
  max-height: 30%;
}
</style>
