import Vue from 'vue'
import Vuex from 'vuex'
import CourseConsumptionController from '@/controllers/CourseConsumptionController'
import UserController from '@/controllers/UserController'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    mini: true,
    graphLevels: [],
    graphDataSet: [],
    courseData: null,
    LOs: [],
    UserInfo: null,
    topNavData: {
      Headder: 'Module 01',
      subHeadder: "Understanding Growth and Development",
      module: null,
      unit: null,
      subUnit: null,
      last: 1,
    },
    isCourseEnroll: false,
    expectedCourseEndDate: "",
  },
  mutations: {
    SET_COURSE_DATA(state, newData) {
      state.courseData = newData;
      // console.log("Updated courseData________", newData);
      localStorage.setItem("courseName", newData[0]?.courseName);
    },
    SET_USER_DATA(state, newData) {
      state.UserInfo = newData;
    },
    setEnrollmentStatus(state, status) {
      state.isCourseEnroll = status;
    }
  },
  actions: {
    async getCourseData({ commit }) {
      try {
        const response = await CourseConsumptionController.getEnrolledCourses();
        if (response.status === 200) {
          const data = response.data.data;
          commit('SET_COURSE_DATA', data);
          return data;
        }
      } catch (error) {
        console.error("Error in getCourseData:", error);
        // Handle error if needed
      }
    },
    async getUserInfo({ commit }) {
      try {
        const response = await UserController.getUserInfo();
        // console.log("bn:",response);
        if (response.status === 200) {
          const data = response.data.data;
          commit('SET_USER_DATA', data);
          return data;
        }
        return { flag: false };
      } catch (error) {
        console.error("Error in getUserInfo:", error);
        return { flag: false };
      }
    },
    async fetchEnrollmentStatus({ commit }) {
      try {
        const response = await UserController.getUserInfo();
        // console.log("API Response:", response);
        if (response.status === 200) {
          const data = response.data.data;
          commit('setEnrollmentStatus', data.isCourseEnroll);
          // commit('SET_USER_DATA', data);
          return data;
        }
        return { flag: false };
      } catch (error) {
        console.error("Error in getUserInfo:", error);
        return { flag: false };
      }
     
    }
  },
  plugins: [createPersistedState()],
})
