<template>
  <div class="description-page mt-2">
    <div class="description-box">
      <div class="pdpColor text-h6 ml-5 mt-2">Everything You Should Know</div>
     <div class="text-body-1 pa-5"> {{ CourseDescription }}</div>
      <!-- Your content for the description page goes here -->
    
      <!-- <p class="text-body-1 pa-5">
        In this course, Lorem ipsum will cover Engineering Mathematics for IIT
        JEE 2023/2024. All the topics will be explained in detail along with
        Previous Year IIT JEE Questions and Techniques and would be helpful for
        aspirants preparing for the JEE 2023. Learners at any stage of their
        preparations will be benefited from the course. The course will be
        delivered in English and notes will be provided in English
        In this course, Lorem ipsum will cover Engineering Mathematics for IIT
        JEE 2023/2024. All the topics will be explained in detail along with details.
      
      </p> -->

      <!-- {{ subjectDescription }} -->
    </div>
  </div>
</template>
  
  <script>
export default {
  props: ["CourseDescription"],
  mounted() {
    // console.log("Course Description:", this.CourseDescription);
  }
  // name: "DescriptionPage",
};
</script>
  
  <style scoped>
.description-page {
  display: flex;
  justify-content: center;
  align-items: center;
}

.description-box {
  width: 100%;
  /* height: 250px; */
  background-color: var(--subject-secondary);
  padding: 10px;
  box-sizing: border-box;

  border-radius: 20px;
}

.description-box h2 {
  font-size: 1.5em;
  margin-bottom: 8px;
}

.description-box p {
  font-size: 1em;
}
</style>
  