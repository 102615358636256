<template>
  <div class="containerelement-ps">
    <div class="left-pane-ps Surfaceshade3">
      <img
        src="../assets/Png.png"
        alt="Image on top"
        class="image-on-eazilyy-ps"
      />
      <div class="pt-16 pl-8 pr-8 text-center" v-if="!isGenerateOtpClicked">
        <div class="text-start" style="max-width: 470px; margin: 0 auto">
          <v-card-title
            class="text-h4 px-0 mt-7 black--text font-weight-medium pt-0"
          >
            {{ isLoginMode ? "Welcome Back" : "Get Started" }}
          </v-card-title>
          <v-card-subtitle
            class="o-87 text-subtitle-1 sign-in-ps jstify-start px-0 pt-0"
          >
            {{
              isLoginMode
                ? "Login to unlock your world of learning!"
                : "Let’s create your account!"
            }}
          </v-card-subtitle>
        </div>

        <div
          class="rounded-xl phoneNo-ps mb-6"
          v-if="usingPhone"
          style="margin: 0 auto"
          @click="clickonTextField"
        >
          <div
            @click="
              (e) => {
                e.preventDefault;
              }
            "
          >
            <vue-country-code
              class="mr-2 ml-2"
              :preferredCountries="['IN']"
              @onSelect="onSelect"
            ></vue-country-code>
          </div>
          <span class="textColor7--text mr-2"> +{{ this.dialCode }} </span>
          <span class="line-after"></span>
          <input
            type="text"
            placeholder="Your Mobile Number"
            v-model="phoneNumber"
            maxlength="10"
            ref="phoneInput"
            @keypress="isNumber($event)"
            class="myinput-ps w-100"
          />
        </div>
        <v-form
          elevation-0
          v-model="valid"
          v-if="!usingPhone"
          class="text-center"
          style="max-width: 470px; margin: 0 auto"
        >
          <span elevation-0>
            <v-text-field
              elevation-0
              label="Your Email address"
              :rules="emailRules"
              class="rounded-xl"
              placeholder="Enter Email Id"
              v-model="email"
              outlined
            ></v-text-field>
          </span>
        </v-form>

        <v-row justify="center">
          <v-btn
            v-if="usingPhone"
            color="secondary"
            @click="generatePhoneOtp"
            class="accentOn2--text px-0 mt-2 sendotpclass-ps"
            large
            width="472px"
            height="48px"
            :disabled="phoneNumber.length != 10 || disablePhoneOtp === true"
          >
            Send OTP
          </v-btn>
          <v-btn
            v-if="!usingPhone"
            color="secondary"
            class="accentOn2--text px-0 py-0 sendotpclass-ps"
            large
            width="472px"
            height="48px"
            @click="generateOtp"
            :disabled="!isEmailValid"
          >
            Send OTP
          </v-btn>

          <v-card-text class="pa-0 mt-5 py-4 px-7">
            <v-row align="center">
              <v-divider class="custom-divider-ps"></v-divider>
              <div class="mx-4" style="color: #8a8b8c">or login with</div>
              <v-divider class="custom-divider-ps"></v-divider>
            </v-row>
          </v-card-text>

          <div class="button-container">
            <v-btn
              v-if="usingPhone"
              text
              color="primarryOn"
              class="primary1--text px-4 mt-2 py-4 emailbutton-ps"
              width="234px"
              height="56px"
              @click="switchLoginMethod(false)"
            >
              <v-icon>mdi-email</v-icon>
              <span style="margin-left: 8px">Email</span>
            </v-btn>

            <v-btn
              v-if="!usingPhone"
              text
              color="primarryOn"
              class="primary1--text mt-2 px-0 py-4 emailbutton-ps"
              width="234px"
              height="56px"
              @click="switchLoginMethod(true)"
            >
              <v-icon>mdi-phone</v-icon>
              <span style="margin-left: 8px"> Phone Number</span>
            </v-btn>

            <v-btn
              :loading="googleAuthLoading"
              text
              @click="googleLogin"
              color="primarryOn"
              class="primary1--text mt-2 px-4 py-4 emailbutton-ps btn-space"
              height="56px"
              width="234px"
            >
              <div class="d-flex align-center">
                <v-img
                  src="../assets/gooogle.svg"
                  alt="bell icon"
                  class="o-87"
                ></v-img>
                <span style="margin-left: 8px">Google</span>
              </div>
            </v-btn>
          </div>
        </v-row>
        <div class="mt-9">
          <span class="account-color">{{
            isLoginMode ? "Don’t have an account?" : "Already have an account?"
          }}</span>
          <span class="red--text cursor" @click="toggleMode">{{
            isLoginMode ? " Sign up" : " Login"
          }}</span>
        </div>
        <!-- <div class="mt-9">
          <span class="account-color">Don’t have an account?</span
          ><span class="red--text"> Sign up</span>
        </div> -->
      </div>
      <v-card
        v-if="isGenerateOtpClicked"
        height="390px"
        width="607px"
        align="center"
        justify="center"
        style="
          border: 3px solid #7d6bf2 !important;
          background-color: transparent;
        "
        class="pa-8 mx-auto text-center main-card-ps"
      >
        <v-card-title class="ma-0 pa-0">
          <v-icon @click="backFromOTP" color="black"> mdi-arrow-left </v-icon>
        </v-card-title>
        <v-card-title class="justify-center text-h4 font-weight-medium pt-0">
          {{ usingPhone ? "Enter OTP" : "Enter OTP" }}
        </v-card-title>
        <v-card-text class="text-center text-body-1"
          ><span>A 6 digit code has been sent to </span>
          {{ usingPhone ? "" : "your Email address" }}
          <span v-if="usingPhone" class="phoneNumberr-ps"
            >+91 {{ phoneNumber }}</span
          >
        </v-card-text>
        <v-row justify="center">
          <v-col cols="10 pa-0 ma-0">
            <v-otp-input length="6" type="number" v-model="otp"></v-otp-input>

            <v-row justify="space-between" class="ma-0 pa-0">
              <v-col class="ma-0 pa-0">
                <v-card-subtitle class="ma-0 pa-0 text-start">
                  {{ otp.length }}/6</v-card-subtitle
                >
              </v-col>
              <v-col class="ma-0 pa-0">
                <v-card-subtitle class="ma-0 pa-0 text-end black--text"
                  >0{{ Math.floor(time / 60) }}:
                  <span v-if="time % 60 < 10">0</span
                  >{{ time % 60 }}</v-card-subtitle
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-card-title class="justify-center">
          <v-btn
            :disabled="otp.length < 6"
            color="secondary"
            class="accentOn2--text verifying-otp-ps mt-5"
            large
            width="90%"
            height="48"
            @click="validateOTP"
          >
            VERIFY OTP
          </v-btn>
        </v-card-title>

        <v-card-text class="text-center">
          <v-btn
            v-if="usingPhone"
            text
            class="secondary--text pl-4"
            @click="generatePhoneOtp"
            :disabled="resendBool"
            >RESEND OTP</v-btn
          >
          <v-btn
            v-else
            text
            class="secondary--text pl-4"
            @click="generateOtp"
            :disabled="resendBool"
            >RESEND OTP</v-btn
          >
        </v-card-text>
      </v-card>
    </div>
    <div class="right-pane-ps relative d-flex justify-center align-center">
      <img src="../assets/background.svg" alt="Profile Image" />
      <img
        src="../assets/loginIcon.svg"
        class="loginRightImage"
        alt="Profile Image"
      />
    </div>
  </div>
</template>

<script>
import AuthService from "../services/AuthService";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
const provider = new GoogleAuthProvider();

export default {
  name: "LoginView",
  components: {},
  computed: {
    isEmailValid() {
      // console.log("email=", this.email);

      // Your email validation logic goes here
      return this.email.trim() !== "" && this.validateEmail(this.email); // Example validation
    },
  },
  data() {
    return {
      usingPhone: true,
      googleAuthLoading: false,
      phoneNumber: "",
      isGenerateOtpClicked: false,
      dialCode: "",
      countryName: "",
      countryIso2: "",
      valid: false,
      otp: "",
      time: 59,
      isLoginMode: true,
      resendBool: false,
      disablePhoneOtp: false,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail must be valid",
      ],
      email: "",
    };
  },
  methods: {
    clickonTextField() {
      this.$refs.phoneInput.focus();
    },

    routerSwitch(data) {
      console.log("dehc",data);
      if (!this.isLoginMode) {
        this.$router.push("/setUpProfileView");
      } else {
        console.log("priting isCourseEnroll",data);
        console.log("priting isCourseFound",data.isCourseFound);

        if (data.isCourseEnroll) {
          if (data.isCourseFound) {
            this.$router.push("/"); 
          } else {
            this.$router.push("/notfound"); 
          }
        } else {
          this.$router.push("/errorPageView"); // Redirect to error page view
        }
      }
    },
    async loginViaGoogleAuth(email) {
      const res = await AuthService.loginViaGoogleAuth(email);
      return res;
    },
    toggleMode() {
      this.isLoginMode = !this.isLoginMode;
    },

    googleLogin() {
      this.googleAuthLoading = true;
      const auth = getAuth();
      auth.languageCode = "it";
      signInWithPopup(auth, provider)
        .then(async (result) => {
          // const credential = GoogleAuthProvider.credentialFromResult(result);
          // const token = credential.accessToken;
          const user = result.user;
          await new Promise((resolve) => setTimeout(resolve, 10000));
          // waiting for a trigger to create user document
          const response = await this.loginViaGoogleAuth(user.email);
          if (response.status == 200) {
            this.googleAuthLoading = false;
            this.routerSwitch(response.data);
          }
          this.googleAuthLoading = false;

          return;
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          const credential = GoogleAuthProvider.credentialFromError(error);
          console.error("error thrown", errorCode, errorMessage, credential);
        });
    },

    onSelect({ name, iso2, dialCode }) {
      this.countryName = name;
      this.countryIso2 = iso2;
      this.dialCode = dialCode;
    },

    switchLoginMethod(isUsingPhone) {
      this.usingPhone = isUsingPhone;
      this.email = "";
      this.phoneNumber = "";
    },
    otpTimmer() {
      clearInterval(this.timer);
      this.resendBool = true;
      this.timer = setInterval(() => {
        if (this.time == 0) {
          clearInterval(this.timer);
          this.resendBool = false;
        } else {
          this.time--;
        }
      }, 1000);
    },
    async generateOtp() {
      try {
        this.disableEmailOtp = true;
        this.time = 59;
        const status = await AuthService.generateOTP({
          email: this.email,
        });

        if (status) {
          this.isGenerateOtpClicked = true;
          localStorage.setItem("email", this.email);
        }
        this.otpTimmer();
      } catch (err) {
        alert("Something went wrong. Please try again");
        this.disableEmailOtp = false;
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode < 48 || charCode > 57) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    // sign up
    async validateSignUpOTP() {
      try {
        const requestData = this.usingPhone
          ? { phoneNumber: this.phoneNumber, otp: this.otp }
          : { email: this.email, otp: this.otp };

        const res = await AuthService.validateOTP(requestData);
        if (res.flag) {
          this.routerSwitch(res);
        }
      } catch (err) {
        console.error(err);
      }
    },
    // login
    async validateLoginOTP() {
      try {
        const requestData = this.usingPhone
          ? { phoneNumber: this.phoneNumber, otp: this.otp }
          : { email: this.email, otp: this.otp };

        const res = await AuthService.validateLoginOtpFunction(requestData);

        if (res.flag) {
          this.routerSwitch({
            ...res,
          });
        } else {
          this.routerSwitch({
            ...res,
          });
        }
      } catch (err) {
        console.error(err);
      }
    },

    async validateOTP() {
      if (this.isLoginMode) {
        await this.validateLoginOTP();
      } else {
        await this.validateSignUpOTP();
      }
    },

    async generatePhoneOtp() {
      try {
        this.disablePhoneOtp = true;
        this.time = 59;
        this.otp = "";
        const status = await AuthService.generateOTP({
          phoneNumber: this.phoneNumber,
        });

        if (status) {
          this.isGenerateOtpClicked = true;
          localStorage.setItem("phoneNumber", this.phoneNumber);
        }
        this.otpTimmer();
      } catch (err) {
        alert("Something went wrong. Please try again.");
        this.disablePhoneOtp = false;
      }
    },
    backFromOTP() {
      this.otp = "";
      this.isGenerateOtpClicked = false;
      this.disablePhoneOtp = false;
      this.isEmailValid = true;
    },
    validateEmail(email) {
      var tempemail = email;
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(tempemail);
    },
  },
  created() {
    if (this.$route.query.isLoginMode === "false") {
      this.isLoginMode = false;
    }
  },
};
</script>
  
  <style>
</style>
  