import axios from "axios";
import AuthService from "@/services/AuthService";

const instance = axios.create({
  baseURL: process.env.VUE_APP_COURSE,
});

// const instance = axios.create({
//     baseURL: 'http://localhost:3000/v1/'
// });




export default {
  

  getEnrolledCourses: async function () {
    var userId = AuthService.getUserId()
    try {
      const response = await instance.get(
         `enrolled-courses?userId=${userId}`,
        {
          headers: {
            Authorization: AuthService.getToken(),
          },
        }
      );
    
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getCourse: async function (courseId) {
    try {
      var userId = AuthService.getUserId()
      const response = await instance.post(
        `course-consumption?userId=${userId}`,
        {   
          courseId: courseId,
        },
        {
          headers: {
            Authorization: AuthService.getToken(),
          },
        }
      );

    
      return response;
    } catch (error) {
      return error.response;
    }
  },

  submitUserInfo: async function (data) {
    try {
      const response = await instance.post('doubts', data);
      return response;
    } catch (error) {
      return error.response;
    }
  },
 




  async updateCompleteFlag(data) {
    try {
    var userId = AuthService.getUserId()
    data.userId = userId;
      return await instance.patch(`complete-flag?userId=${userId}`,data, {
        headers: {
          Authorization: AuthService.getToken(),
        },
      });
    } catch (error) {
      return error.response;
    }
  },





  async getTopicData(data,content) {
    try {
    var userId = AuthService.getUserId()
      return await instance.post(`sub-topic-data/${content}?userId=${userId}`,data, {
        headers: {
          Authorization: AuthService.getToken(),
        },
      });
    } catch (error) {
      return error.response;
    }
  },
  async getTopicQuestions(data) {
    try {
    var userId = AuthService.getUserId()
      return await instance.post(`sub-topic-question/?userId=${userId}`,data, {
        headers: {
          Authorization: AuthService.getToken(),
        },
      });
    } catch (error) {
      return error.response;
    }
  },
  submitSubTopicTestQuestion: async function (data) {
    var userId = AuthService.getUserId()
    try {
      const response = await instance.post(
         `submit-subTopic-questions?userId=${userId}`,data,
        {
          headers: {
            Authorization: AuthService.getToken(),
          },
        }
      );
    
      return response;
    } catch (error) {
      return error.response;
    }
  },
  getSubTopicReport: async function (data) {
    var userId = AuthService.getUserId()
    try {
      const response = await instance.post(
         `get-subTopic-test-report?userId=${userId}`,data,
        {
          headers: {
            Authorization: AuthService.getToken(),
          },
        }
      );
    
      return response;
    } catch (error) {
      return error.response;
    }
  },
  async getSubUnitVideos(data) {
   
    try {
    var userId = AuthService.getUserId()
      return await instance.post(`sub-unit-videos?userId=${userId}`,data, {
        headers: {
          Authorization: AuthService.getToken(),
        },
      });
    } catch (error) {
      return error.response;
    }
  },

};


