<template>
  <div class="sub-unit-screen pb-0 mt-0">
    <div>
      <div v-if="topicDatalength == 0" class="loader-container">
        <img
          src="../assets/loader-eazily.gif"
          alt="Loading..."
          class="loader-img"
        />
      </div>

      <div class="text-subtitle-1 content-name ml-3 mb-2 cursor">
        <!-- <img src="../assets/photo7.svg"  @click="home" class="size-lock mt-1 mr-1" /> -->
        <v-icon @click="home" class="unitss" size="20">mdi-home</v-icon>

        <div class="font-weight-medium">
          > <span class="unitss ml-1 mr-1">{{ subject }}</span> >
          <span class="unitss mr-1" @click="menu">Units</span> >
          <span class="unitss ml-1 mr-1">Chapters</span> >
          <span class="unitss ml-1 mr-1" @click="gotoTopics">Topics</span> >
          <span class="topicss">Sub Topics</span>
        </div>
      </div>

      <div class="text-h6 font-weight-medium ml-2 pl-2">
        Topic {{ this.$route.query.topicId + 1 }}
      </div>

      <div class="text-h5 font-weight-medium ml-2 pl-2 mb-6">
        {{ this.topicData.topicName }}
      </div>

      <div class="subunit-cards">
        <div class="d-flex flex-wrap w-100">
          <div
            v-for="(subTopic, index) in topicData.subTopics"
            :key="index"
            class="subUnitCard col-sm-4"
          >
            <!-- Common content for the rest of the cards -->
            <div
              class="w-100 h-100"
              style="height: 100% !important; position: relative"
            >
              <div class="content-name">
                <!-- <img
                    src="../assets/bookeys.svg"
                    class=" size-lock mt-1"
                  /> -->
                <div class="text-caption mt-1 font-weight-medium">
                  SUB TOPIC {{ index + 1 }}
                </div>
              </div>
              <div class="font-weight-medium text-h6 mb-2 text-container" >
                {{ subTopic.subTopicName }}
                <!-- {{ truncateText(subTopic.subTopicName, 32) }} -->
                <!-- {{ subTopic.subTopicName }} -->
              </div>




              <div class="w-100  d-flex justify-space-between  " style="height: 60px"> 
                <div :class="{'border-check-box': !subTopic?.videoCompleted}" class="rounded-circle custom-stepper-circle " >
                  <span class="text-body-2 text--secondary" style="position: absolute; bottom: -26px;">
                    Videos
                  </span>

                  <img style="width: 28px !important;" v-if="subTopic?.videoCompleted" src="../assets/tick.svg" width="32">
                  <span class="d-flex justify-center align-center" v-else style=" color: #444141de;" >1</span>
                </div>
                <div  class="mt-3 custom-devider"></div>
                <div :class="{'border-check-box': !subTopic?.readCompleted}" class="rounded-circle text-center d-flex justify-center text-center align-center custom-stepper-circle " style="">
                  <span class="text-body-2 text--secondary" style="position: absolute; bottom: -26px;">
                    PDFs
                  </span>

                  <img style="width: 28px !important;" v-if="subTopic?.readCompleted"  src="../assets/tick.svg" width="32">

                  <span class="d-flex justify-center text-center text--secondary align-center" v-else >2</span>
               

                </div>
                <div  class="mt-3 custom-devider"></div>
                <div :class="{'border-check-box': !subTopic?.bbCompleted}" class="rounded-circle text-center d-flex justify-center  custom-stepper-circle " >
                  <span class="text-center text-body-2 text--secondary" style="position: absolute; bottom: -48px;">
                    Brain Booster
                  </span>

                  <img  style="width: 28px !important;" v-if="subTopic?.bbCompleted" src="../assets/tick.svg" width="32">

                  <span class="d-flex justify-center align-center" v-else  style="color: #444141de;" >3</span>

                </div>
                <div   class="mt-3 custom-devider"></div>
                <div :class="{'border-check-box': !subTopic?.testCompleted}" class="rounded-circle text-center d-flex justify-center custom-stepper-circle " >
                  <span class="text-body-2 text--secondary" style="position: absolute; bottom: -26px;">
                    Test
                  </span>

                  <img  v-if="subTopic?.testCompleted"  src="../assets/tick.svg" width="32">

                  <span v-else class="d-flex justify-center align-center"  style="color: #444141de;" >4</span>

                </div>              
              </div>
              <div class="">
                <v-btn
                  @click="videosconsume(subTopic.subTopicName, index)"
                  text
                  class="elevation-0 text-subtitle-2 subUnitBtn mt-3"
                  >WATCH VIDEO</v-btn
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import CourseConsumptionController from '@/controllers/CourseConsumptionController';


export default {
  name: "UnitView",
  // props: ["subUnits"],
  data() {
    return {
      subUnitsData: [],
      subUnits: [],
      topics: [],
      topicId: 0,
      courseId: "",
      showPreTestReport: false,
      circleStepStaus: [],
      topicData: {},
      subject: "",
      topicDatalength: 0,
    };
  },
  methods: {
   

    async getSubtopicData() {
  try {
    var courseId = this.$route.query.course;
    const response = await CourseConsumptionController.getCourse(courseId);
    return response.data.data;
  } catch (error) {
    // Handle the error
    console.error('Error fetching subtopic data:', error);
   
  }
},



    home() {
      this.$router.push({
        name: "homeView",
      });
    },
    menu() {
      this.$router.push({
        name: "NoUnitSelected",
        query: {
          subjectId: this.$route.query.subjectId,
          subject: this.$route.query.subject,
        },
      });
    },
    truncateText(text, limit) {
      if (text.length > limit) {
        return text.substring(0, limit) + "...";
      }
      return text;
    },
    gotoTopics() {
      var pastQuery = this.$route.query;

      this.$router.push({
        name: "Topics",
        query: pastQuery,
      });
    },
    async getTopicData() {
      var subjectId = this.$route.query.subjectId;
      var chapterId = this.$route.query.chapterId;
      var unitId = this.$route.query.unitId;
      var topicId = this.$route.query.topicId;

      const courseData = await this.getSubtopicData()

      this.courseId = this.$route.query.course
      // console.log("subject",courseData.subjects,courseData.subjects[subjectId].subjectName)
      this.topicData =
         courseData.subjects[subjectId].units[unitId].chapters[
          chapterId
        ].topics[topicId];
        const topicDatalength = Object.keys(this.topicData).length;
        this.topicDatalength=topicDatalength;  
    },
    videosconsume(name, index) {
      var pastQuery = this.$route.query;
      pastQuery.subTopic = name;
      pastQuery.subTopicId = index;
      pastQuery.course = this.courseId;
      this.$router.push({
        name: "Video",
        query: pastQuery,
      });
    },
  },
  computed: {},
  mounted() {
    this.getSubtopicData()
    this.getTopicData();
    this.subject = this.$route.query.subject;
  },
};
</script>
  <style scoped>


.start-activity {
  color: var(--user-facing-color-text-text-low, #a3a3a3);
  /* font-family: Graphik; */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  text-transform: uppercase;
}
.text-container {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /* Show only 2 lines */
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 3em; /* Adjust based on your line height */
  line-height: 1.5em; /* Adjust based on your line height */
  word-break: break-word; /* Break long words */
}

.step-progress {
  position: inherit !important;
  width: 90%;
}

.step-progress-style {
  position: absolute;
  top: 120px;
}



.content-last {
  display: flex;
  position: absolute !important;
  bottom: 12px !important;
  right: 20px;
}

.v-application .white {
  background-color: rgba(0, 0, 0, 0.04) !important;
  /* border-color: rgba(0, 0, 0, 0.04); */
}

.v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  min-width: 64px;
  padding: 0 0px;
}

.v-stepper--alt-labels .v-stepper__step {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-basis: 0px;
}
.size-lock {
  width: 18px;
  height: 18px;
  /* color: red; */
}
.stepper-vd {
  color: #666666;
  font-size: 14px;
}

.subunit-cards {
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  /* gap: 18px; */
  margin-left: 10px;
  /* grid-template-columns: auto auto auto auto; */
  /* grid-template-columns: repeat(4, 1fr); */
}

.subUnitCard {
  /* height: 156px; */
  /* width: 22.2rem; */
  /* width: calc(100% - 20px); */

  background-color: var(--subject-secondary) !important;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  cursor: pointer;
  flex-basis: calc(33.33% - 20px);
  margin: 10px;
  /* background: #fff; */
  /* margin-left: 30px; */
  /* margin-right: 30px; */
}
.green-circle {
  background-color: green;
}
.theme--light.v-stepper .v-stepper__step--complete .v-stepper__label {
  color: rgba(0, 0, 0, 0.4);
}
.theme--light.v-stepper .v-stepper__header .v-divider {
  border-color: rgba(0, 0, 0, 0.5);
}

.orange-circle {
  background-color: orange;
}

.topicss {
  color: rgba(0, 0, 0, 0.6);
}
.red-circle {
  background-color: red;
}

.subUnitCard-first {
  height: 240px;
  width: 21.2rem;
  /* border-radius: 16px; */
  border: 0.5px solid var(--user-facing-color-primary-primary-2, #b4e4e9);
  background: var(--user-facing-color-surface-surface, #f0effe);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.08);
}

.v-application .white {
  background-color: rgba(0, 0, 0, 0.04);
}

.dot {
  width: 14px;
  height: 14px;
  /* background-color: red; */
  background-color: #c4c4c4;
  border-radius: 99px;
  margin-left: 6px;
  margin-top: 6px;
}
.hello {
  background-color: transparent;
}

/* .dot-completed:before {
    border-color: rgba(6, 194, 112, 0.80);
    background: rgba(6, 194, 112, 0.80);
    color: black;
  }
  
  .dot-completed:after {
    background: rgba(6, 194, 112, 0.80);
  }
  
  .dot:before {
    border-color: rgba(255, 149, 1, 0.80);
    background: rgba(255, 149, 1, 0.80);
    color: black;
  }
  
  .dot:after {
    background: rgba(255, 149, 1, 0.80);
  }
  
  .dot:not(.dot-completed):before {
    background: #e0e0e0;
    border-color: #e0e0e0;
    color: black;
  }
  
  .dot:not(.dot-completed):after {
    background: #e0e0e0;
    border-color: #e0e0e0;
    color: black;
  }
   */

.subUnitBtn {
  /* color: var(--user-facing-color-text-text-low, #a3a3a3);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    margin-left: -10px; */
  height: 36px;
  width: 151px;
  border-radius: 4px;
  border: 1px solid var(--subject-onColor);
  color: var(--subject-onColor) !important;
  /* background-color: var(--subject-primary) !important; */
}



.subUnitBtn-again {
  color: var(--user-facing-color-primary-primary-on, #005b64);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-left: -10px;
}

.v-chip {
  background-color: #e0e0e0 !important;
  color: #212121 !important;
}

.active {
  background-color: rgba(6, 194, 112, 0.8) !important;
}

/* .theme--light.v-stepper .v-stepper__step--complete .v-stepper__label {
    color: rgba(0, 0, 0, 0.87);
} */

.random12 {
  color: rgba(0, 0, 0, 0.6);
}
.inactive {
  background-color: rgba(255, 149, 1, 0.8) !important;
}

/* .progressbar li.active:before {
    border-color: rgba(6, 194, 112, 0.80);
    background: rgba(6, 194, 112, 0.80) !important;
    color: black;
  }
  
  .progressbar li.active:after {
    background: rgba(6, 194, 112, 0.80) !important;
  }
  
  .progressbar li.inactive:before {
    border-color: rgba(255, 149, 1, 0.80);
    background: rgba(255, 149, 1, 0.80);
    color: black;
  }
  
  .progressbar li.inactive:after {
    background: rgba(255, 149, 1, 0.80);
  }
  
  .progressbar li:not(.active):not(.inactive):before {
    background: #e0e0e0;
    border-color: #e0e0e0;
    color: black;
  }
  
  .progressbar li:not(.active):not(.inactive):after {
    background: #e0e0e0;
  } */

.v-stepper--alt-labels .v-stepper__header .v-divider {
  margin: 13.5px -67px 0;
  align-self: flex-start;
}
</style>