import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import {getFirestore} from "firebase/firestore";



const firebaseConfig = {
  apiKey: "AIzaSyDLAXjDYjW6dEZHkv4oi3SQ8zuFoCjJDic",
  authDomain: "eazily-lms-staging.firebaseapp.com",
  projectId: "eazily-lms-staging",
  storageBucket: "eazily-lms-staging.appspot.com",
  messagingSenderId: "819079937788",
  appId: "1:819079937788:web:88ee85146c8b277331a179",
  measurementId: "G-EVTVS762L8"
};

const app = initializeApp(firebaseConfig);
const db=getFirestore(app);

const storage=getStorage(app);
export {db,storage};

// Initialize Firebase
//  initializeApp(firebaseConfig);