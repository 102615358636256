<template>
  <div>
    <v-row>
      <!-- Overall Rating and Write a Review Section -->
      <v-col cols="12" md="4">
        <v-card class="pa-4 firstcard mt-1" outlined>
          <v-card-title class="d-flex justify-center mt-n5">
            Overall Rating
          </v-card-title>
          <v-card-subtitle class="d-flex justify-center mt-1">
            <span class="ml-2 mt-1" style="font-size: 3.5rem">4.5</span>
            <v-rating
              :value="4.5"
              class="mt-n3 ml-4"
              half-increments
              readonly
              color="amber"
            ></v-rating>
          </v-card-subtitle>
          <v-card-subtitle
            class="d-flex justify-center mt-n10"
            style="margin-left: 7rem"
          >
            Based on 275 ratings
          </v-card-subtitle>
          <div
            v-for="(rating, index) in ratings"
            :key="index"
            class="rating-bar-container"
          >
            <div class="rating-label">{{ index + 1 }}</div>
            <div class="rating-bar-wrapper">
              <v-progress-linear
                :value="rating.value"
                height="8"
                color="var(--subject-primary)"
                class="rating-bar"
                rounded
                background-color="#D9D9D9"
              ></v-progress-linear>
            </div>
            <div class="rating-count">{{ rating.count }}</div>
          </div>
        </v-card>

        <v-card class="pa-3 mt-4 firstcard" outlined style="height: calc(100vh - 640px); min-height: 180px !important">
          <v-card-title class="d-flex justify-center mt-n5" style="color: #595959">
            Review this subject
          </v-card-title>
          <v-card-subtitle class="d-flex justify-center " style="color: #636362">
            Rate now and share your experience with others
          </v-card-subtitle>
          <div class="d-flex justify-center my-4">
            <v-rating
              v-model="userRating"
              color="amber"
              size="30"
              background-color="grey"
              class="mt-n4"
            ></v-rating>
          </div>
          <div class="center-button-container">
            <v-btn
              color="var(--subject-secondary)"
              @click="writeReview"
              class="btn-writereview mt-n2"
            >
              Write a Review
            </v-btn>
          </div>
        </v-card>
      </v-col>

      <!-- Reviews Section -->
      <v-col cols="12" md="8">
        <v-card class="pa-4 firstcard secondcard mt-1" outlined style="overflow: hidden;">
          <v-row>
            <v-col>
              <span
                ><span
                  class="text-subtitle-1 font-weight-bold mr-2 reviewscolor"
                  >Reviews</span
                ><span style="color: #595959">150</span></span
              >
            </v-col>
            <v-col class="d-flex justify-end">
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text v-bind="attrs" v-on="on" class="no-bg-btn">
                    Sort by: Newest
                    <v-icon right>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>Oldest</v-list-item>
                  <v-list-item>Newest</v-list-item>
                </v-list>
              </v-menu>
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text v-bind="attrs" v-on="on" class="no-bg-btn">
                    Sort by: 5 star
                    <v-icon right>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item>5 star</v-list-item>
                  <v-list-item>4 star</v-list-item>
                  <v-list-item>3 star</v-list-item>
                  <v-list-item>2 star</v-list-item>
                  <v-list-item>1 star</v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>

          <!-- <v-divider></v-divider> -->
          <v-list style="max-height: 100%; overflow-y: auto;" class="newcard" >
            <v-list-item
              v-for="(review, index) in reviews"
              :key="index"
              class="py-4"
            >
              <div class="avatar-name-wrapper">
                <v-list-item-avatar class="avatar-wrapper">
                  <v-img src="../assets/man.svg" class="avatar-image"></v-img>
                </v-list-item-avatar>
                <!-- Section 1: Image, Name, and Course -->
                <div class="profile-section">
                  <div class="profile-info">
                    <v-list-item-title class="name">{{
                      review.name
                    }}</v-list-item-title>
                    <v-list-item-subtitle class="course">{{
                      review.course
                    }}</v-list-item-subtitle>
                  </div>
                </div>
                <!-- <span class="name">Palvi</span> -->
              </div>

              <v-list-item-content class="content-wrapper">
                <!-- Section 2: Ratings, Date, Comments, and Thumbs Up/Down -->
                <div class="review-section">
                  <div class="ratings-date">
                    <v-rating
                      :value="review.rating"
                      half-increments
                      readonly
                      dense
                      color="amber"
                      background-color="amber"
                    ></v-rating>
                    <v-list-item-subtitle class="date">{{
                      review.date
                    }}</v-list-item-subtitle>
                  </div>
                  <v-list-item-subtitle class="comment">{{
                    review.comment
                  }}</v-list-item-subtitle>
                  <div class="thumbs-section">
                    <v-icon small class="thumbs-icon">mdi-thumb-up</v-icon>
                    <v-icon small class="thumbs-icon">mdi-thumb-down</v-icon>
                  </div>
                </div>
              </v-list-item-content>

              <!-- <v-divider></v-divider> -->
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialog -->
    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title class="text-subtitle-1 mb-2"
          >Rate this subject</v-card-title
        >
        <v-card-text>
          <v-rating
            v-model="rating"
            half-increments
            hover
            class="mb-2"
            color="amber"
            size="30"
            background-color="grey"
          ></v-rating>
          <div class="text-subtitle-1 mt-1 black--text font-weight-bold mb-4">Write your Review (Optional)</div>
          <v-textarea v-model="review" label="Label" row-height="25"
          rows="4" outlined class="custom-textarea"></v-textarea>
        
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="canceldialog" text @click="dialog = false"
            >Cancel</v-btn
          >
          <v-btn class="submitdialog" @click="submitReview">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      ratings: [
        { value: 54.54, count: 150 },
        { value: 18.18, count: 50 },
        { value: 7.27, count: 20 },
        { value: 1.82, count: 5 },
      ],
      sortOptions: ["Newest", "Oldest"],
      filterOptions: ["5 star", "4 star", "3 star", "2 star", "1 star"],
      selectedSort: "Newest",
      selectedFilter: "5 star",
      reviews: [
        {
          name: "Martin Leo",
          course: "IIT JEE",
          rating: 4.5,
          date: "24-10-2023",
          comment:
            "All the topics will be explained in detail along with Previous Year IIT JEE Questions and Techniques",
          avatar: "path/to/avatar1.jpg",
        },
        {
          name: "Nidhi",
          course: "IIT JEE",
          rating: 5,
          date: "24-10-2023",
          comment:
            "All the topics will be explained in detail along with Previous Year IIT JEE Questions and Techniques",
          avatar: "path/to/avatar2.jpg",
        },
        {
          name: "Mary",
          course: "IIT JEE",
          rating: 5,
          date: "24-10-2023",
          comment:
            "All the topics will be explained in detail along with Previous Year IIT JEE Questions and Techniques",
          avatar: "path/to/avatar3.jpg",
        },
        {
          name: "Nidhi",
          course: "IIT JEE",
          rating: 5,
          date: "24-10-2023",
          comment:
            "All the topics will be explained in detail along with Previous Year IIT JEE Questions and Techniques",
          avatar: "path/to/avatar2.jpg",
        },
        {
          name: "Mary",
          course: "IIT JEE",
          rating: 5,
          date: "24-10-2023",
          comment:
            "All the topics will be explained in detail along with Previous Year IIT JEE Questions and Techniques",
          avatar: "path/to/avatar3.jpg",
        },
      ],
      userRating: 0,
      dialog: false,
    };
  },
  methods: {
    writeReview() {
      this.dialog = true;
    },
    submitReview() {
      // Handle the review submission logic here
      this.dialog = false;
    },
  },
};
</script>
  
  <style scoped>
.text-center {
  text-align: center;
  font-size: 0.75rem;
}
.custom-textarea {
 /* Adjust the height as needed */
}

.firstcard {
  /* background-color: rgb(244, 166, 166); */

  background-color: var(--subject-secondary);
  border-radius: 16px;
}
.newcard {
  background-color: transparent;
  /* background-color: var(--subject-secondary); */
  /* background-color: rgb(244, 166, 166); */
}
.canceldialog {
  border: 1px solid var(--subject-onColor);
  color: var(--subject-onColor);
}
.submitdialog {
  background-color: var(--subject-onColor) !important;
  color: white;
  /* border: 1px solid var(--subject-onColor); */
}
.secondcard {
  height: calc(100vh - 380px);
}
.rating-bar-container {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  justify-content: space-between;
}
.rating-label {
  /* width: 20px; */
  /* text-align: right; */
  margin-right: 10px;
}
.rating-bar-wrapper {
  flex: 1;
  margin: 0 10px;
}
.rating-bar {
  width: 100%;
}
.rating-count {
  min-width: 50px;
  text-align: right;
}
.center-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* Adjust height as needed */
}

.btn-writereview {
  width: 247px;
  height: 48px;
  border-radius: 10px;
  display: flex;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect */
  /* justify-content: center; */
  /* align-items: center; */
}
.avatar-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar-name-wrapper {
  display: flex;
  align-items: center;
  width: 150px;
}
.reviewscolor {
  color: rgba(89, 89, 89, 1) !important;
}

.avatar-image {
  width: 70px; /* Adjust size as needed */
  height: 70px; /* Adjust size as needed */
  border-radius: 50%; /* Circle shape */
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 80px;
  flex: 1;
}

.profile-section {
  display: flex;
  align-items: center;
  flex: 1;
}

.profile-info {
  display: flex;
  flex-direction: column;
}

.name {
  font-weight: bold;
}

.course {
  color: #858494 !important; /* Light gray color for subtitle */
  font-weight: 500 !important;
}

.review-section {
  margin-top: 8px; /* Space above the review section */
}

.ratings-date {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.date {
  color: rgba(51, 51, 51, 0.63) !important;
  margin-left: 16px;
  /* Space between rating and date */
}


.comment {
  margin-top: 8px; 
  color: #343a40;
  /* word-wrap: break-word; */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2em; /* Adjust line height if needed */
  max-height: 2.4em; /* 1.2em * 2 lines */
}


.thumbs-section {
  display: flex;
  align-items: center;
  margin-top: 8px; /* Space above thumbs section */
}

.thumbs-icon {
  margin-right: 8px; /* Space between thumbs icons */
}
</style>
  