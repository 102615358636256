<template>
  <v-app>
    <v-main class="background">
      <router-view> </router-view>
    </v-main>
  </v-app>
</template>
<script type="text/javascript" src="https://www.gstatic.com/charts/loader.js"></script>
<script type="text/javascript" rel="script" src="https://cdn.ckeditor.com/4.14.0/standard/ckeditor.js"></script>
<script>

import AppBar from "../src/components/AppBar.vue";
CKEDITOR.plugins.addExternal('ckeditor_wiris', 'https://www.wiris.net/demo/plugins/ckeditor/', 'plugin.js');
import './style.css';

export default {
  name: 'App',
  components: {
    AppBar
  },
  data: () => ({
    //
  }),
  mounted() {
    google.charts.load('current', {
      packages: ['corechart']
    });
},

};
</script>
