<template>
    <div class="error-page white" fluid>
      <div>
        <v-col cols="12" class="logo-container">
          <img src="../assets/Png.png" alt="Eazily Logo" class="logo">
        </v-col>
    </div>
      <div justify="center" align="center" class="error-content">
        <v-col cols="12" md="6" class="text-center">
          <div class="text-h4 mb-4">Error</div>
          <div class="text-h5 mb-4">User Not Found</div>
          <p class="error-message">
            The Mobile number/Email you entered is not registered. Please Sign up to continue.
          </p>
          <v-btn outlined color="secondary" class="mr-4 round" @click="cancel"  width="154px"
          height="48px">
            CANCEL
          </v-btn>
          <v-btn color="secondary" @click="signUp"  width="154px" class="round"
          height="48px">
            SIGN UP
          </v-btn>
        </v-col>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    methods: {
      cancel() {
        // Implement your cancel logic here
        this.$router.push("/login");
        // console.log('Cancel button clicked');
      },
      signUp() {
        // Implement your sign-up logic here
        // this.$router.push("/login");
        this.$router.push({ 
        path: '/login', 
        query: { isLoginMode: 'false' } 
      });
        // console.log('Sign Up button clicked');
      }
    }
  }
  </script>
  
  <style scoped>
  .error-page {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .logo-container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    /* padding: 20px; */
  }
  
  .logo {
    width: 150px;
  }
  
  .error-content {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -4rem;
  }
  
  .error-title {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .error-subtitle {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
  
  .error-message {
    font-size: 1rem;
    color: #666;
    margin-bottom: 30px;
  }
  .round{
    border-radius: 10px;
  }
  </style>
  