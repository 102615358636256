import axios from "axios";
import AuthService from "@/services/AuthService";
const instance = axios.create({
  baseURL: process.env.VUE_APP_MASTERS_API_URL,
});

// const instance = axios.create({
//   baseURL: 'http://localhost:3000/v1/'
// });
export default {


  getobjectives: async function () {
    try {
      var userId = AuthService.getUserId()
      const response = await instance.get(`objectives-wa?userId=${userId}`,{
        headers: {
          Authorization: AuthService.getToken(),
        },
      });
      return response;
    } catch (error) {
      return error.response;
    }
  },

 
};
