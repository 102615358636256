// myMixin.js



import CourseConsumptionController from "@/controllers/CourseConsumptionController";

export const myMixin = {
   data() {
     return {
       count: 0
     };
   },
   methods: {

    goToRouterByName(name, metaData) {
      var pastQuery = this.$route.query
      var query = pastQuery
      if (name == 'NoUnitSelected') {
        query = {}
        query.subjectId = pastQuery.subjectId;
        query.subject = pastQuery.subject
      }

      if (metaData == 'next') {
        query = this.goToNextSubTopicQuery()
      }

      this.$router.push({
        name: name,
        query: query,
      });
    },
    getNextTopicQuery(subjectId, unitId, chapterId, topicId) {

      var topicData = this.$store.state.courseData[0].subjects[subjectId].units[unitId].chapters[
        chapterId
      ].topics[(parseInt(topicId) + 1)];
      var subTopicData = this.$store.state.courseData[0].subjects[subjectId].units[unitId].chapters[
        chapterId
      ].topics[(parseInt(topicId) + 1)].subTopics[0];


      return {
        subTopicId: 0,
        subTopic: subTopicData.subTopicName,
        topicId: (parseInt(topicId) + 1),
        topic: topicData.topicName
      }
    },


    getNextsubTopicQuery(subjectId, unitId, chapterId, topicId, subTopicId) {
      var subTopicData = this.$store.state.courseData[0].subjects[subjectId].units[unitId].chapters[
        chapterId
      ].topics[topicId].subTopics[(parseInt(subTopicId) + 1)];

      return {
        subTopicId: (parseInt(subTopicId) + 1),
        subTopic: subTopicData.subTopicName,
      }
    },

    getNextChapterQuery(subjectId, unitId, chapterId) {
      var chapterData = this.$store.state.courseData[0].subjects[subjectId].units[unitId].chapters[(parseInt(chapterId) + 1)]
      var topicData = this.$store.state.courseData[0].subjects[subjectId].units[unitId].chapters[(parseInt(chapterId) + 1)].topics[0]
      var subTopicData = this.$store.state.courseData[0].subjects[subjectId].units[unitId].chapters[(parseInt(chapterId) + 1)].topics[0].subTopics[0]

      return {
        subTopic: subTopicData.subTopicName,
        subTopicId: 0,
        topic: topicData.topicName,
        topicId: 0,
        chapterId: (parseInt(chapterId) + 1),
        chapter: chapterData.chapterName
      }
    },

    getNextUnitQuery(subjectId, unitId) {
      var unitData = this.$store.state.courseData[0].subjects[subjectId].units[(parseInt(unitId) + 1)]
      var chapterData = this.$store.state.courseData[0].subjects[subjectId].units[(parseInt(unitId) + 1)].chapters[0]
      var topicData = this.$store.state.courseData[0].subjects[subjectId].units[(parseInt(unitId) + 1)].chapters[0].topics[0]
      var subTopicData = this.$store.state.courseData[0].subjects[subjectId].units[(parseInt(unitId) + 1)].chapters[0].topics[0].subTopics[0]
      return {
        subTopic: subTopicData.subTopicName,
        subTopicId: 0,
        topic: topicData.topicName,
        topicId: 0,
        chapterId: 0,
        chapter: chapterData.chapterName,
        unitId: (parseInt(unitId) + 1),
        unit: unitData.unitName,
      }
    },


    goToNextSubTopicQuery() {
      var pastQuery = this.$route.query
      var subjectId = pastQuery.subjectId;
      var chapterId = pastQuery.chapterId;
      var unitId = pastQuery.unitId;
      var topicId = pastQuery.topicId;
      var subTopicId = pastQuery.subTopicId;
      var units = this.$store.state.courseData[0].subjects[subjectId].units
      var chapters = this.$store.state.courseData[0].subjects[subjectId].units[unitId].chapters

      var topicData = this.$store.state.courseData[0].subjects[subjectId].units[unitId].chapters[
        chapterId
      ].topics[topicId];
      var Topics = this.$store.state.courseData[0].subjects[subjectId].units[unitId].chapters[
        chapterId
      ].topics

      if (topicData?.subTopics?.length > (subTopicId + 1)) {
        Object.assign(pastQuery, this.getNextsubTopicQuery(subjectId, unitId, chapterId, topicId, subTopicId));
      }
      else if (Topics.length > (topicId + 1)) {
        Object.assign(pastQuery, this.getNextTopicQuery(subjectId, unitId, chapterId, topicId));
      }
      else if (chapters.length > (chapterId + 1)) {
        Object.assign(pastQuery, this.getNextChapterQuery(subjectId, unitId, chapterId));
      }
      else if (units.length > (unitId + 1)) {
        Object.assign(pastQuery, this.getNextUnitQuery(subjectId, unitId));
      }
      else {
        alert("Change Completed")
      }

      return pastQuery
    },


    updatePlayer(msg,id) {
      const playerElement = document.getElementById(id);
      if (playerElement) {
        playerElement.classList.add('player-container');
        playerElement.textContent = msg;
      }
    },
formatTime(minutes) {
      const hours = Math.floor(minutes / 60);
      const mins = minutes % 60;
      
      let result = '';
      
      if (hours > 0) {
          result += hours + ' hrs';
      }
      
      if (mins > 0) {
          result += (hours > 0 ? ' ' : '') + mins + ' min';
      }
      
      return result;
  },

     goToRoute(pageName, query) {
       this.$router.push({
         name: pageName,
         query: query,
       });
     },
     sortByAlphabet(array, key) {
      array.sort(function (a, b) {
        var nameA = a[key].toUpperCase(); // Convert to uppercase to ensure case-insensitive sorting
        var nameB = b[key].toUpperCase();

        if (nameA < nameB) {
          return -1;
        }

        if (nameA > nameB) {
          return 1;
        }

        return 0;
      });
      return array;
    },
     getRouterQuery() {
      var routerQuery = this.$route.query;
      var payload = {
        unitId: routerQuery.unit,
        chapterId: routerQuery.chapter,
        subjectId: routerQuery.subject,
        subTopicId: routerQuery.subTopic,
        topicId: routerQuery.topic,
        courseId: routerQuery.course,
      };
      return payload;
    },



    async updateCompleteStatus(type) {
      var payload = this.getRouterQuery()
      payload.type = type;
      payload.isCompleted = true;
        await CourseConsumptionController.updateCompleteFlag(
        payload
      );
    },

    subTopicDataFromStore() {  
      const { subjectId, chapterId, unitId, topicId, subTopicId } = this.$route.query;
      const subTopicData = this.$store.state.courseData[0]?.subjects?.[subjectId]?.units?.[unitId]?.chapters?.[chapterId]?.topics?.[topicId]?.subTopics?.[subTopicId];
      return subTopicData
    },






   }
 };
 