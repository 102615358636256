<template>
  <div class="sub-unit-screen pb-0 mt-0">
    <div style="height: calc(100vh - 75px); width: 100%; position: fixed;bottom: 0px; z-index: 4" class="mt-10 w-100" v-if="showReport">
      <test-report @goTotopic="subtopics" @goNext="goToRouterByName('Video', 'next' )" :reportData="reportData" @close-dialog="showReport = false" />
      </div>

    <div>
      <div v-if="postReportDataLength == 0" class="loader-container">
        <img src="../assets/loader-eazily.gif" alt="Loading..." class="loader-img" />
      </div>
      <div class="text-subtitle-1 content-name ml-3 mb-2 cursor">
        <v-icon @click="home" class="unitss" size="20">mdi-home</v-icon>

        <div class="font-weight-medium">
          > <span class="unitss mr-1">{{ subject }}</span> >
          <span class="unitss mr-1" @click="menu">Units</span> >
          <span class="unitss ml-1 mr-1">Chapters</span> >
          <span class="unitss ml-1 mr-1" @click="goToTopics">Topics</span> >
          <span class="unitss ml-1 mr-1" @click="subtopics">Sub Topics</span> >
          <span class="topicss">Test</span>
        </div>
      </div>
      <div class="w-100 pl-4">
        <PolygonStepperViewVue />
      </div>
      <v-card outlined class="rounded-xl center-div pa-12 d-flex flex-column justify-center align-center">

        <div class="text-h5 font-weight-medium ">You’ve completed the exercise.</div>
        <div class="text-h5 font-weight-regular ">Your test score is:</div>
        <v-card outlined width="100%" class="rounded-xl mt-4 d-flex flex-row" height="200px">
          <v-card width="25%" height="100%" class="elevation-0 pl-2 rounded-xl">
            <sub-topic-score-donut-chart
              v-if="reportData.totalQuestion > 0"
              :correct="reportData.currentAnswer"
              :incorrect="reportData.WrongAnswer"
              :skipped="reportData.skippedQuestions"
              :total="reportData.totalQuestion"
            />
          </v-card>
          <div style="width: calc(100% - 25%);" class="d-flex pa-4 h-100 justify-center flex-column">
            <div class="d-flex flex-column justify-start">
              <div class="d-flex justify-between align-center">
                <v-progress-linear
                  :value="(reportData.currentAnswer / reportData.totalQuestion) * 100"
                  background-color="#C4C3C3"
                  color="green"
                  class="rounded-xl"
                  height="8"
                  width="1"
                  rounded
                ></v-progress-linear>
                <div class="caption-count">{{ reportData.currentAnswer }}</div>
              </div>
              <div class="text-start text-body-2 text--secondary">
                Correct Answers
              </div>
            </div>

            <div class="d-flex mt-4 flex-column justify-start">
              <div class="d-flex justify-between align-center">
                <v-progress-linear
                  :value="(reportData.WrongAnswer / reportData.totalQuestion) * 100"
                  background-color="#C4C3C3"
                  color="red"
                  class="rounded-xl"
                  height="8"
                  width="1"
                  rounded
                ></v-progress-linear>
                <div class="caption-count">{{ reportData.WrongAnswer }}</div>

              </div>
              <div class="text-start text-body-2 text--secondary">
                Incorrect Answers
              </div>
            </div>

            <div class="d-flex mt-4 flex-column justify-start">
              <div class="d-flex justify-between align-center">
               
                <v-progress-linear
                  :value="(reportData.skippedQuestions / reportData.totalQuestion) * 100"
                  background-color="#C4C3C3"
                  color="grey"
                  class="rounded-xl"
                  height="8"
                  width="1"
                  rounded
                ></v-progress-linear>
                <div class="caption-count">{{ reportData.skippedQuestions }}</div>
              </div>
              <div class="text-start text-body-2 text--secondary">
                Not Answered
              </div>
            </div>
          </div>
        </v-card>

        <div class="w-100 d-flex justify-center mt-4">
          <v-btn
            class="mustardd onColor-T mr-4 white elevation-0 button rounded-lg mr-3"
            @click="showReport = true"
            style="border: 1px solid var(--subject-onColor) !important;"
          >
            VIEW DETAILED REPORT
          </v-btn>
          <v-btn
            @click="goToRouterByName('Video','next')"
            class="mustardd ml-4 rounded-lg white--text elevation-0 button  "
          >
            GO TO NEXT SUB TOPIC
          </v-btn>
        </div>
      </v-card>
    </div>
  </div>
</template>


<script>
import PolygonStepperViewVue from "./PolygonStepperView.vue";
import CourseConsumptionController from "@/controllers/CourseConsumptionController";
import SubTopicScoreDonutChart from './SubTopicScoreDonutChart.vue';
import TestReport from '@/views/TestReport.vue';
import { myMixin } from "../myMixin";
export default {
  name: "UnitView",
  mixins: [myMixin],
  components: {
    PolygonStepperViewVue,
    SubTopicScoreDonutChart,
    TestReport,

  },
  data() {
    return {
      subject: "",
      showReport: false,
      postReportDataLength: 0,
      reportData: {},
    };
  },
  methods: {
   
   


   


    
  

    async getSubTopicReport() {
      try {
        var payload = this.getRouterQuery();
        const response = await CourseConsumptionController.getSubTopicReport(
          payload
        );
        if (response.status === 200) {
          this.reportData = response?.data?.data;
          const postReportDataLength = Object.keys(this.reportData).length;
          this.postReportDataLength = postReportDataLength;
          this.DataLoading = false;
        }
      } catch (error) {
        // Handle the error
        console.error('Error fetching sub-topic report:', error);

      }
    },
    viewReport() {
      var payload = this.getRouterQuery();
      this.$router.push({
        name: "TestReport",
        query: payload,
      });
    },

    menu() {
      this.$router.push({
        name: "NoUnitSelected",
        query: {
          subjectId: this.$route.query.subjectId,
          subject: this.$route.query.subject,
        },
      });
    },
    home() {
      this.$router.push({
        name: "homeView",
      });
    },
    subtopics() {
      // this.$router.push("/subtopics");
      var pastQuery = this.$route.query;

      this.$router.push({
        name: "SubTopics",
        query: pastQuery,
      });
    },
    goToTopics() {
      var pastQuery = this.$route.query;

      this.$router.push({
        name: "Topics",
        query: pastQuery,
      });
    },
  },
  mounted() {
    this.subject = this.$route.query.subject;
    this.getSubTopicReport();
    // console.log("hoo",this.subject);
  },

  computed: {
    getResult() {
      return (
        (this.reportData?.currentAnswer / this.reportData?.totalQuestion) *
        100
      ).toFixed(2);
    },
  },
};
</script>
<style scoped>
.sub-unit-screen {
  width: 100%;
  height: calc(90vh - 30px);
  overflow-y: scroll;
}

.skip {
  background-color: white !important;
  box-shadow: none;
}

.theme--light.v-btn.v-btn--has-bg {
  background-color: #9e9e9e;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.containery {
  display: flex;
  justify-content: center;
  align-items: center;
}

.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 4px;
}

.another-class {
  display: flex;
}

.option {
  width: 390px;
  height: 58px;
  background-color: #606060;
  margin-bottom: 10px;
}

.status-margin {
  margin-left: 20%;
}

.step-progress {
  position: inherit !important;
  width: 80%;
}

.heelo {
  text-align: center;
  margin-top: 30px;
}

.button {

  height: 48px !important;
  width: 234px !important;
}

.content-last {
  display: flex;
  position: absolute !important;
  bottom: 12px !important;
  right: 20px;
}

.size-lock {
  width: 18px;
  height: 18px;
  /* color: red; */
}

.center-div {
  position: relative;
  width: 70%;
  max-width: 700px;

  margin: 20px auto;
  align-content: center;
  text-align: center;
}

.caption-count {
  font-size: 0.75rem; /* Caption size */
  text-align: center;
  width: 50px;
}
</style>
