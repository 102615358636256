<template>
  <div class="sub-unit-video-screen pb-0 mt-0">
    <div>
      <div v-if="isLoading" class="loader-container">
        <img src="../assets/loader-eazily.gif" alt="Loading..." class="loader-img" />
      </div>

      <div class="text-subtitle-1 content-name ml-3 mt-2 mb-2 cursor">
        <v-icon @click="goToRoute('homeView', {})" class="unitss" size="20">mdi-home</v-icon>

        <div class="font-weight-medium cursor">
          > <span class="unitss mr-1">{{ subject }}</span> >
          <span class="unitss mr-1"
            @click="goToRoute('NoUnitSelected', { subjectId: $route.query?.subjectId, subject: $route.query?.subject })">Units</span>
          <span class="unitss ml-1 mr-1">Chapters</span> >
          <span class="unitss ml-1 mr-1" @click="goToRoute('Topics', $route.query)">Topics</span> >
          <span class="unitss ml-1 mr-1" @click="goToRoute('SubTopics', $route.query)">Sub Topics</span>
          >
          <span class="topicss">Videos</span>
        </div>
      </div>
      <v-card width="100%" height="50px" class="pl-4 elevation-0 ">
        <PolygonStepperViewVue />
      </v-card>
      <!-- <div class="text-h5 font-weight-medium ml-2 pl-2 mb-2">
        {{ video?.name }}
      </div> -->

      <div class="pa-4 mt-4 py-0 d-flex flex-column w-100 classing-overflow">
        <div ref="videoBox" class="videoBox onColorBorder-1" style="position: relative;">
          <div id="player" class="w-100 h-100"></div>

          <div  @mouseenter="showControls" @mousemove="onMouseMove"
            class="d-flex flex-column  justify-center align-center"
            style="width: 100%; height: 55px;position: absolute;top: 0px;z-index: 10">

            <div v-if="controlsVisible || showLayer || !isPlaying  " class="custom-controls white black--text h-100  h-100  w-100 ">
              <div
                class="w-100 py-2 text-h6   d-flex flex-column align-start justify-center px-4 h-100 subject-secondary">
                {{ video?.name }}
              </div>
            </div>
          </div>



          <div v-if="videoAvailable" id="changeBGblue rounded-xl py-2" @click="togglePlayPause"
            class="w-100 d-flex flex-row flex-column justify-center align-center" @mouseenter="showControls"
            @mousemove="onMouseMove"
            style="width: 100%;height: calc(100% - 110px);position: absolute;top: 60px; z-index: 999;">
              <v-card  @click="togglePlayPause" v-if="showLayer || !player" color="white" class="rounded-pill elevation-0">
                <v-icon 
                size="80" color="red">mdi-play</v-icon>
              </v-card>
          </div>



          <div @mouseenter="showControls" @mousemove="onMouseMove" class=""
            style="width: 100%; height: 55px;position: absolute;bottom: 0px;z-index: 10">

            <div v-if="controlsVisible || showLayer || !isPlaying " style="position: absolute;bottom: 0px;"
              class="custom-controls white  d-flex flex-column align-start h-100  w-100 ">
              <div class="w-100 py-2 px-4  subject-secondary h-100 ">
                <input type="range" class="w-100 mb-n2" min="0" :max="videoDuration" v-model="watchedDuration"
                  @input="seekVideo" />
                <div class="d-flex mt-n2 flex-row justify-space-between align-center w-100">
                  <div class="ml-n2">

                    <span class="cursor"  @click="togglePlayPause">
                      <v-icon class="ma-0 pa-0"  color="black" v-if="!showLayer"
                      size="28">mdi-pause</v-icon>
                    <v-icon class="ma-0 pa-0" color="black" v-else
                      size="28">mdi-play</v-icon>
                    </span>
                  
                      
                      
                      
                      
                      <span class="black--text caption ml-2 ">{{ formatTime(watchedDuration)
                      }} / {{ formatTime(videoDuration) }}</span>
                  </div>
                  <div class="d-flex flex-row">
                    <v-icon color="black" size="24">mdi-volume-high</v-icon>
                    <v-card width="150px" class="elevation-0 transparent">
                      <v-slider class="mt-0 " track-colo="yellow" color="black" v-model="volume" @start="0" @end="100"
                        @input="changeVolume" min="0" max="100" hide-details></v-slider>
                    </v-card>
                    <span class="cursor" @click="toggleFullScreen">
                    <v-icon class="ma-0 pa-0"  color="black" size="28">mdi-fullscreen</v-icon>

                    </span>
                  </div>
                </div>
              </div>
            </div>




          </div>
        </div>
      </div>
    </div>
    <div class="my-4 pl-4">
      <v-btn @click="goToRoute('SubTopics', $route.query)"
        class="white onColor-T onColorBorder elevation-0 button-sub-unit rounded-xl">BACK</v-btn>
      <v-btn @click="goToRoute('content', $route.query)"
        class="mustardd white--text elevation-0 button-sub-unit onColor-B rounded-xl">NEXT</v-btn>
    </div>
  </div>
</template>
<script>
import PolygonStepperViewVue from "./PolygonStepperView.vue";
import CourseConsumptionController from "@/controllers/CourseConsumptionController";
import { myMixin } from '../myMixin.js';

export default {
  name: "SubTopicVideoConsume",
  mixins: [myMixin],
  components: {
    PolygonStepperViewVue,
  },
  data() {
    return {
      subTopicData: {},
      subject: "",
      showLayer: false,
      video: {},
      player: null,
      videoDuration: 0,
      watchedDuration: 0,
      isLoading: true,
      isPlaying: false,
      controlsVisible: false,
      hideControlsTimeout: null,
      lastMouseX: 0,
      lastMouseY: 0,
      volume: 50,
      videoAvailable: true,
      movementCheckTimeout: null,
i: 0,
      isFullScreen: false
    };
  },

  computed: {
    isVideoPlaying() {
  return this.player.getPlayerState() === window.YT.PlayerState.PLAYING;
}
  },

  methods: {
    toggleFullScreen() {
      const videoBox = this.$refs.videoBox;
      if (!document.fullscreenElement) {
        videoBox.requestFullscreen().then(() => {
          this.isFullScreen = true; // Set isFullScreen to true
        });
      } else {
        document.exitFullscreen().then(() => {
          this.isFullScreen = false; // Set isFullScreen to false
        });
      }
    },

    changeVolume() {
      if (this.player) {
        this.player.setVolume(this.volume);
        this.player.playVideo();


      }
    },
    async getSubTopicData() {
      const payload = this.getRouterQuery();
      const subTopicData = this.subTopicDataFromStore();
      this.subTopicData = subTopicData;
      const response = await CourseConsumptionController.getTopicData(payload, "videos");
      this.isLoading = false;
      if (response?.status === 200) {
          this.videoAvailable = true
        this.video = response.data.data || {};
        if (this.video.videoUrl?.includes('youtu')) {
          window.onYouTubeIframeAPIReady = this.onYouTubeIframeAPIReady(this.getYoutubeVideoId(this.video.videoUrl));
        } else {
      
        this.makeVideoPlayer(this.video.videoUrl);
        }
        this.updateCompleteStatus('video');
      } else {
        this.videoAvailable = false
        this.updatePlayer('Video not available.', 'player');
      }
    },

    makeVideoPlayer(videoUrl) {
      const videoContainer = document.getElementById('player');
      const videoElement = document.createElement('video');
      videoElement.src = videoUrl;
      videoElement.controls = true;
      videoElement.autoplay = true;
      videoElement.loop = true;
      videoElement.style.width = '100%';
      videoContainer.appendChild(videoElement);
    },

    getYoutubeVideoId(url) {
      const regExp = /^.*((youtu\.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?v=))([^\s&]+)/i;
      const match = url.trim().match(regExp);
      return match && match[7] ? match[7] : null;
    },

    onYouTubeIframeAPIReady(id) {
      this.player = new window.YT.Player('player', {
        videoId: id,
        playerVars: {
          'playsinline': 1,
          'rel': 0,
          'controls': 0,
        },
        events: {
          onReady: this.onPlayerReady,
          onStateChange: this.onStateChange,
          onPlaybackRateChange: this.onPlaybackRateChange,
        },
      });
    },

    onPlayerReady(event) {
      this.player = event.target;
      this.videoDuration = this.player.getDuration();
      this.showLayer = true;
      // console.log('now ready==========================')
      this.updateCurrentTime();
      setInterval(this.updateCurrentTime, 1000);
    },

    updateCurrentTime() {
      if (this.player && this.player.getCurrentTime) {
        this.watchedDuration = this.player.getCurrentTime();
        if (this.videoDuration - this.watchedDuration < 3) {
          this.showLayer = true;
          this.player.seekTo(0, true);
          this.player.pauseVideo();
        }
      }
    },

    onStateChange(event) {
      console.error(this.i++)
      // this.isPlaying = event.data === window.YT.PlayerState.PLAYING;

      if (event.data === window.YT.PlayerState.PLAYING) {
        // console.log('playing-----------')
        this.showLayer = false
        this.isPlaying = true
        this.showControls()
      }else{
        this.isPlaying = false
      }
    },
    onPlaybackRateChange() {
  const playbackRate = this.player.getPlaybackRate();
  if (playbackRate > 0) {
    // console.log('Video is playing');
  }
},

    togglePlayPause() {
      if (this.isPlaying) {
        this.player.pauseVideo();
        this.showLayer = true;
        // console.log('palyer after pause', this.player.getPlayerState())
      } else {
        this.player.playVideo();
        this.showControls()
        this.showLayer = false;
        // console.log('palyer after play', this.player.getPlayerState())

      }
    },

    seekVideo() {
      if (this.player && this.player.seekTo) {
        this.player.seekTo(this.watchedDuration, true);
        // this.player.playVideo();
      }
    },

    formatTime(seconds) {
      const hrs = Math.floor(seconds / 3600);
      const min = Math.floor((seconds % 3600) / 60);
      const sec = Math.floor(seconds % 60);
      // console.log('seconds', seconds, 'hrs', hrs, 'min', min, 'sec', sec)
      return `${hrs > 0 ? hrs + ':' : ''}${min < 10 ? '0' : ''}${min}:${sec < 10 ? '0' : ''}${sec}`;
    },

    showControls() {
      this.controlsVisible = true;
      this.resetTimer();
    },

    hideControls() {
      this.controlsVisible = false;
      clearTimeout(this.movementCheckTimeout);
    },

    resetTimer() {
      clearTimeout(this.hideControlsTimeout);
      this.hideControlsTimeout = setTimeout(() => {
        this.hideControls();
      }, 10000);
    },
    handleFullscreenChange() {
      this.isFullScreen = !!document.fullscreenElement;
    },

    onMouseMove(event) {
      const { clientX, clientY } = event;
      if (clientX !== this.lastMouseX || clientY !== this.lastMouseY) {
        this.showControls();
        this.lastMouseX = clientX;
        this.lastMouseY = clientY;

        clearTimeout(this.movementCheckTimeout);
        this.movementCheckTimeout = setTimeout(() => {
          this.hideControls();
        }, 10000);
      }
    },
  },

  async mounted() {
    this.getSubTopicData();
    this.subject = this.$route.query.subject;
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    document.addEventListener('fullscreenchange', this.handleFullscreenChange);
  },
};
</script>

<style scoped>
.custom-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.onColorBorder {
  border: 1px solid var(--subject-onColor) !important;
}


.button {
  margin-right: 10px;


  height: 48px !important;
  width: 234px !important;
}

#changeBGblue.paused {
  background-color: blue;

}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
