<template>
  <div class="root">
    <div class="container">
      <ul class="progressbar">
        <li v-for="(question, index) in questions" :key="index"
          :class="{ completed: index < selectedQuestion, current: index === selectedQuestion }">
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: ["selectedQuestion", "questions"],
};
</script>

<style scoped>
.container {
  width: 100%;
  height: max-content;
  position: relative;
  z-index: 1;
  margin-top: 0px;
  /* background-color: white; */
  padding: 10px;
  /* border-radius: 4px; */
  /* border: 0.5px solid var(--user-facing-color-text-text-disable, #e0e0e0); */
  /* background: var(--user-facing-color-surface-white, #fff); */
}

.progressbar {
  list-style-type: none;
  margin: 0;
  padding: 0px;
  counter-reset: step;
  display: flex;
  /* Add this line to set the background color */
  overflow: hidden !important;
  /* z-index: 100; */
  /* margin-top: 1px; */
  /* border: 1px solid var(--user-facing-color-text-text-disable, #E0E0E0); */
}

.progressbar li {
  flex: 1;
  text-align: center;
  position: relative;
}

.progressbar li:before {
  content: counter(step);
  counter-increment: step;
  
  /* position: absolute !important; */
  /* top: -10%; */
  /* left: 0px; */
  /* z-index: -1; */

  width: 40px;
  height: 40px;
  background-color: white;
  /* border: 1px solid #bebebe; */
  display: block;
  margin: 5px auto;
  border-radius: 50%;
  line-height: 40px;
  /* background: white; */
  border: 1px solid var(--subject-onColor);
  color: black;
  text-align: center;
  font-size: 18px;
  overflow: hidden !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* z-index: 1; */
}

.container {
  width: 100%;
  padding: 0px;
  margin-right: auto;
  margin-left: auto;
}

.progressbar li:after {
  content: "";
  position: absolute;
  width: calc(100% - 18px);
  height: 2px;
  /* background: #979797; */
  border: 1px solid var(--subject-onColor);
  top: 24px;
  left: 62%;
  z-index: -1;
}

.progressbar li:last-child:after {
  display: none;
}


.progressbar li.active:before,
.progressbar li.active:after {
  /* background-color: red !important; */
  background: var(--subject-onColor) !important;
  /* border: 1px solid var(--subject-onColor); */

  color: white;
}

.container{
  width: 100%;
  position: relative;
  z-index: 1;
  height: max-content;
  margin-top: 0px;
  /* margin-left: -10px; */
}



.number{
  visibility: hidden;
}

.progressbar li.completed .number,
.progressbar li.current .number {
  visibility: visible;
}

.progressbar li.completed:before,
.progressbar li.completed:after {
  background-color: green !important;
  content: "✓";
  color: white;
}


.progressbar li.current:before,
.progressbar li.current:after {
  background-color: var(--subject-onColor) !important;
  content: counter(step);
  color: white;
}

</style>