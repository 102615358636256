import axios from 'axios'
import AuthService from '@/services/AuthService';

const instance = axios.create({
    baseURL: process.env.VUE_APP_MASTERS_API_URL
});

export default {
    getAllGrades: async function () {
        try {
            var userId = AuthService.getUserId()
            const response = await instance.get(`grades-wa?userId=${userId}`, {
                headers: {
                  Authorization: AuthService.getToken(),
                },
              })
            return response;
        } catch (error) {
            return error.response;
        }
    }
    ,
    getAllGradesByPagination: async function (pageSize,page) {
        try {
            const response = await instance.get('grades?pageSize='+pageSize+'&page='+page)
            return response;
        } catch (error) {
            return error.response;
        }
    }
    ,
    updateGrade: async function (data,id) {
        // console.log(id,data)
        try {
            const response = await instance.put('grade?id='+id, data,
            {
         headers: {
                    'Authorization': AuthService.getToken()
                  }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },
   

   createGrade : async function ( data) {   

        try {
            // console.log("full grade data from controller",data)
            const response = await instance.post('grade',data,{
                headers: {
                    'Authorization': AuthService.getToken()
                  }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },

    deleteGrade : async function (id) {
        // console.log("delete selected id",id)
        try {
            const response = await instance.delete('admin/meta/grades/'+id,{
                headers: {
                    'Authorization': AuthService.getToken()
                  }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },
    deleteBulkGrade : async function (ids) {
        // console.log("delete",ids)
        try {
            const response = await instance.delete('grades',{
                data:{
                    ids:ids
                }
            },{
                headers: {
                    'Authorization': AuthService.getToken()
                  }
            })
            return response;
        } catch (error) {
            return error.response;
        }
    },
    filterGrade : async function (boards ,subjects){
        try{
            const response = await instance.put('/filterGrades',{boards,subjects},
            {
                headers:{
                    'Authorization':AuthService.getToken()
                }
            })
            return response;
        }
        catch (error){
            return error.response;
        }
    },
    searchGrade: async function (pageSize,page,search) {
        try {
            const response = await instance.get('/meta/grades?pageSize='+pageSize+'&page='+page+'&search='+search)
            return response;
        } catch (error) {
            return error.response;
        }
    }
    ,

}
