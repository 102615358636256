<template>
  <div class="sub-unit-screen pb-0 mt-0">
    <div>
      <div v-if="isLoading" class="loader-container">
        <img src="../assets/loader-eazily.gif" alt="Loading..." class="loader-img" />
      </div>
      <div class="text-subtitle-1 content-name ml-3 mt-2 mb-2">

        <v-icon @click="goToRoute('homeView', {})" class="unitss" size="20">mdi-home</v-icon>

        <div class="font-weight-medium cursor">
          > <span class="unitss mr-1">{{ subject }}</span> >
          <span class="unitss mr-1"
            @click="goToRoute('NoUnitSelected', { subjectId: $route.query?.subjectId, subject: $route.query?.subject })">Units</span>
          >
          <span class="unitss ml-1 mr-1">Chapters</span> >
          <span class="unitss ml-1 mr-1" @click="goToRoute('Topics', $route.query)">Topics</span> >
          <span class="unitss ml-1 mr-1" @click="goToRoute('SubTopics', $route.query)">Sub Topics</span>
          >
          <span class="topicss">PDFs</span>
        </div>
      </div>
      <div class="w-100 pl-4">
        <PolygonStepperViewVue  />

      </div>
      <div class="ml-2 pl-2 mt-4 mb-4 d-flex">
        <div class="text-h5 text-center d-flex align-center font-weight-medium">
          {{ name }}
        </div>
        <div class="image-containert-bb mt-1 cursor mr-4" @click="openPdfDialog(read)">
          <img src="../assets/fullScreen.svg" />
        </div>
      </div>   

      <div v-if="pdfDialog" fullscreen style="margin: 0; padding: 0">
        <v-card style="margin: 0; padding: 0" elevation="0">
          <v-card-text style="
              margin: 0;
              padding: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
            ">
            <!-- Container for the PDF viewer -->
            <div id="pdfContainer" style="width: 100%; height: calc(100vh - 346px); margin: 0px 16px">
              <!-- Embedded PDF -->
              <embed
              v-if="read"
                :src="read + '#toolbar=0'"
                type="application/pdf"
                style="width: 100%; height: 100%"
                id="pdfEmbed"
              />
              <div id="xyzdfa"></div>
              <!-- Full-screen button -->

            </div>
          </v-card-text>
        </v-card>
      </div>

      <v-dialog v-if="pdffDialog" v-model="pdffDialog" fullscreen style="margin: 0; padding: 0">
        <v-card style="margin: 0; padding: 0;">
          <v-toolbar dark style="color: black; background-color: #ececec">
            <v-btn icon @click="closePdfDialog">
              <v-icon style="color: black">mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title class="ml-2 title-of-pdf">
              {{ name }}
            </v-toolbar-title>


          </v-toolbar>
          <v-card-text style="margin: 0; padding: 0;  display: flex; align-items: center; justify-content: center;">
            <embed :src="read + '#toolbar=0'" type="application/pdf" style="width: 100%; height: calc(100vh)"
              id="pdfEmbed" />
          </v-card-text>
        </v-card>
      </v-dialog>

      <div class="button-container-sub-topic pl-4">
        <v-btn @click="goToRoute('Video', $route.query)"
          class="white onColorBorder onColor-T elevation-0 button rounded-xl">BACK</v-btn>
        <v-btn @click="goToRoute('BrainBooster', $route.query)"
          class="mustardd white--text elevation-0 button rounded-xl">NEXT</v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import PolygonStepperViewVue from "./PolygonStepperView.vue";
import CourseConsumptionController from "@/controllers/CourseConsumptionController";
import { myMixin } from '../myMixin.js';



export default {
  name: "SubUnitPdf",
  mixins: [myMixin],

  components: {
    PolygonStepperViewVue,
  },
  data() {
    return {
      read: "",
      pdfDialog: true,
      subject: "",
      isLoading: true,
      pdffDialog: false,
      name: "",
      readingData: {}
    };
  },
  methods: {

    closePdfDialog() {
      this.pdffDialog = false;
      this.pdfUrl = "";

    },
    openPdfDialog(pdfUrl) {
      this.pdfUrl = pdfUrl;
      this.pdffDialog = true;
    },

    async getSubTopicData() {
      try {
        var payload = this.getRouterQuery();
        const response = await CourseConsumptionController.getTopicData(
          payload,
          "reads"
        );
        if (response?.status === 200) {
          this.readingData = response?.data?.data;
          this.read = response?.data?.data?.url;
          this.name = response?.data?.data?.name; 
          this.updateCompleteStatus('read')        
          this.isLoading = false;
        }
        else {
          this.isLoading = false;
   
          this.updatePlayer('PDF not available.', 'xyzdfa')
        }
      } catch (error) {
        // Handle the error
        console.error('Error fetching sub-topic data:', error);

      }
    },


  },
  mounted() {
    this.getSubTopicData()    
    this.subject = this.$route.query.subject;

  },

};
</script>
<style scoped>
.onColorBorder {
  border: 1px solid var(--subject-onColor) !important;
}

.step-progress {
  position: inherit !important;
  width: 90%;
}


.step-progress-style {
  position: absolute;
  top: 120px;
}


.class-display {
  display: flex;
}

.image-containert {
  margin-left: auto;
}

.button {
  margin-right: 10px;
  /* Adjust spacing between buttons */

  height: 48px !important;
  width: 234px !important;
}



.content-last {
  display: flex;
  position: absolute !important;
  bottom: 12px !important;
  right: 20px;
}

.size-lock {
  width: 18px;
  height: 18px;
  /* color: red; */
}
</style>