<template>
  <div class="new-page">
    <div class="row mt-3">
      <div class="box">
        <!-- Your content for the first div goes here -->
        <v-row class="ml-4 mt-1">
          <v-col cols="1">
            <img src="../assets/Avatar.svg" alt="Icon for Unit 1" />
          </v-col>
          <v-col cols="11">
            <div class="primary--text text-subtitle-1 font-weight-bold">
              Palvi Sabharwal
            </div>
            <div class="primary--text text-subtitle-1 font-weight-bold">
              Prepared IIT JEE
            </div>
          </v-col>
        </v-row>
        <p class="text-subtitle-1 ml-5 mr-5 upperr_c">
          After doing course from Eazily, I’ve appeared IIT JEE 2023/2024 and
          got selected for my favorite college of India. All the topics
          coveredwere explained in detail along with Previous Year IIT JEE
          Questions and Techniques and were be so helpful for preparing for the
          JEE 2023.
        </p>
      </div>
    </div>
    <div class="row mt-3 ">
      <div class="box">
        <!-- Your content for the first div goes here -->
        <v-row class="ml-4 mt-1">
          <v-col cols="1">
            <img src="../assets/Avatar.svg" alt="Icon for Unit 1" />
          </v-col>
          <v-col cols="11">
            <div class="primary--text text-subtitle-1 font-weight-bold">
              Rahul Sabharwal
            </div>
            <div class="primary--text text-subtitle-1 font-weight-bold">
              Prepared IIT JEE
            </div>
          </v-col>
        </v-row>
        <p class="text-subtitle-1 ml-5 mr-5 upperr_c">
          After doing course from Eazily, I’ve appeared IIT JEE 2023/2024 and
          got selected for my favorite college of India. All the topics
          coveredwere explained in detail along with Previous Year IIT JEE
          Questions and Techniques and were be so helpful for preparing for the
          JEE 2023.
        </p>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  // name: "NewPage",
};
</script>
  
  <style scoped>
.new-page {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
}

.row {
  display: flex;
  margin: auto;
  /* justify-content: center; */
  margin-bottom: 16px; /* Add margin for spacing between rows */
}

.box {
  width: 100%;
  /* height: 235px; */
  height: 25vh;
  background-color: #f0effe; /* Your desired background color */
  padding: 16px;
  box-sizing: border-box;
  border-radius: 20px;
  /* color: white; */
}

.box h2 {
  font-size: 1.5em;
  margin-bottom: 8px;
}

.box p {
  font-size: 1em;
  overflow: hidden; /* Hide overflow content */
}
.upperr_c{
  margin-top: -15px;
}
</style>
  