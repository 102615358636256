<template>
  <div class="w-100 leftNav subject-secondary overflow-y-scroll relative d-flex flex-column">
    <img class="eazilyIcon my-6 align-self-center" src="../assets/eazily.png" />
    <v-list color="transparent">
      <v-list-item to="/" :class="$route.name == 'ProductView' ? 'nav-menu text--primary' : ''"
        active-class="nav-menu white--text">
        <v-list-item-avatar>
          <img src="../assets/dashboard.svg" />
        </v-list-item-avatar>
        <v-list-item-content class="">
          <v-list-item-title class="text-subtitle-1 font-weight-medium">
            Home
          </v-list-item-title>
          <v-list-item-subtitle class="caption">
            Your Journey Starts Here!
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/profile" active-class="nav-menu white--text">
        <v-list-item-avatar>
          <img src="../assets/userprofile.svg" />
        </v-list-item-avatar>

        <v-list-item-content class="">
          <v-list-item-title class="text-subtitle-1 font-weight-medium">
            My Profile
          </v-list-item-title>

          <v-list-item-subtitle class="caption">
            Update Your Username, Mobile No, etc!
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item to="/leaderboard" active-class="nav-menu white--text">
        <v-list-item-avatar>
          <img src="../assets/leaderboard.svg" />
        </v-list-item-avatar>

        <v-list-item-content class="">
          <v-list-item-title class="text-subtitle-1 font-weight-medium">
            Leaderboard
          </v-list-item-title>

          <v-list-item-subtitle class="caption">
            Check Your Rankings & Grow!
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>  
    </v-list>
    <img class="align-self-center logout-btn" src="../assets/madewithindia.svg" />
  </div>
</template>

<script>
export default {
  name: "NavDrawer",

  data() {
    return {};
  },

  watch: {
    "$route.query": function routerwatch() {
      this.getSubjectColor();
    },
  },

  methods: {

    async getCourseData() {
      await this.$store.dispatch("getCourseData");
    },
    async getSubjectColor() {
      try {
        var path = this.$route.path;
        if (path == "/courseDetail") {
          await this.getCourseData();
          var subjects = this.$store.state.courseData[0].subjects;
          var secondaryColor =
            subjects[this.$route.query.subjectId].primaryColor;
          var primaryColor =
            subjects[this.$route.query.subjectId].secondaryColor;

            var ctaColor =
            subjects[this.$route.query.subjectId].ctaColor;
          var onColor = subjects[this.$route.query.subjectId].onColor;
          document.documentElement.style.setProperty(
            "--subject-primary",
            primaryColor
          );
          document.documentElement.style.setProperty(
            "--subject-cta",
            ctaColor
          );
          document.documentElement.style.setProperty(
            "--subject-secondary",
            secondaryColor
          );
          document.documentElement.style.setProperty(
            "--subject-onColor",
            onColor
          );
        } else {
          document.documentElement.style.setProperty(
            "--subject-primary",
            "#5a4efa"
          );
          
          document.documentElement.style.setProperty(
            "--subject-secondary",
            "#dcd9ff"
          );
        }
      } catch (error) {
        // console.log("error",error)
      }
    },
  },

  mounted() {
    this.getSubjectColor();

  },
  beforeDestroy() { },
};
</script>

<style scoped>


</style>
