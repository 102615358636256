<template>
  <div class="userInfoCard fit-content align-self-right rounded-xl my-2 mr-2">
    <v-list color="transparent py-0 mb-2">
      <v-list-item class="pa-0">
        <v-list-item-avatar class="ml-2 mt-4">

        <v-badge avatar bordered overlap bottom class="mr-2 ml-2">
          <template v-slot:badge>
            <v-avatar>
             
            </v-avatar>
          </template>

          <v-avatar size="45">
            <img v-if="userInfo?.profilePic"  class="rounded-circle" width="100%" height="auto" :src="userInfo.profilePic" />
                <img v-else width="100%" height="auto" src="../assets/setupprofile.png" />
                
            <!-- <v-img src="https://cdn.vuetifyjs.com/images/john.png"></v-img> -->
          </v-avatar>
        </v-badge>


        <!-- <div class="profilePicc  ml-2 mt-1 overflow-hidden">

                <img v-if="userInfo.profilePic"  class="rounded-circle" width="100%" height="auto" :src="userInfo.profilePic" />
                <img v-else width="100%" height="auto" src="../assets/setupprofile.png" />
                

              </div> -->
        </v-list-item-avatar>
        <!-- text--primary -->
        <v-list-item-content class="py-0">
          <v-list-item-title
            class="text-subtitle-1  font-weight-medium"
          >
            <span v-if="$store.state.UserInfo?.personalCreds?.firstName"
              >{{
                this.$store.state.UserInfo?.personalCreds?.firstName +
                " " +
                this.$store.state.UserInfo?.personalCreds?.lastName
              }}
            </span>
            <span v-else>User</span>
          </v-list-item-title>
          <!-- text--secondary -->
          <v-list-item-subtitle class="caption primary--text">
            <!-- (IIT JEE) -->
            ({{ this.courseName}})
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action class="ml-0 my-0">
          <v-btn @click="showLogout = !showLogout" icon>
            <v-icon class="primary--text">mdi-chevron-down</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-btn
      @click="logout"
      v-if="showLogout"
      text
      class="w-100 red--text text-subtitle-1 font-weight-medium py-4 text-capitalize"
      ><v-icon>mdi-logout</v-icon>Logout</v-btn
    >
  </div>
</template>

<style scoped>
</style>
<script>
import AuthService from "../services/AuthService";
import axios from "axios";

export default {
  name: "userCard",
  data() {
    return {
      showLogout: false,
      userInfo: {},
      courseName: "",
    };
  },
  methods: {
    logout() {
      AuthService.logout();
      this.$router.push("/login");
    },
    async isPicValid(url) {
      try {
        const res = await axios.get(url);
        if (res?.status != 200) {
          this.userInfo.profilePic = "";
          return false;
        }
        return true;
      } catch (err) {
        // console.log(err);
        this.userInfo.profilePic = "";
        return false;
      }
    },
    async getUserInfo() {
      var userData = await this.$store.dispatch("getUserInfo");
      await this.$store.dispatch("getCourseData");
      this.courseName = localStorage.getItem("courseName");

      // console.log('asdfas%%%%%%',this.$store.state.courseData)
      this.userInfo = userData;
      // console.log("userData/..", userData);
      if (this.userInfo?.flag===false)
        this.logout();
    },
  },
  mounted() {
    this.getUserInfo();
  
  },
};
</script>
<style scoped>
.rounded-circle {
  border-radius: 50%;
}
</style>