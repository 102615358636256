<template>
  <div>
    <div v-if="isLoading" class="loader-container">
      <img
        src="../assets/loader-eazily.gif"
        alt="Loading..."
        class="loader-img"
      />
    </div>
    <div class="vh-100">
    <HeaderView/>
    <div class="w-100 content-height d-flex flex-row white">
      <v-card class="corDetFirDiivStart  elevation-0">
        <router-view class="ma-0 pa-0 "> </router-view>
      </v-card>
      <v-card class="corDetSecDiivStart  elevation-0">
        <SideNavCourses />
      </v-card>  
    </div>
  </div>
  </div>

</template>



  
  <script>
import SideNavCourses from "../components/SideNavCoursesView.vue";
import HeaderView from "../components/HeaderView.vue";

export default {
  name: "CourseConsumptionView",
  components: {
    SideNavCourses,
    HeaderView,
    // CourseContent,
  },
  data() {
    return {
      cardData: "",
      navbarData: [],
      subUnitsData: [],
      isLoading: true,
      topNavData: {
        Headder: "Module 01",
        subHeadder: "Understanding Growth and Development",
      },
    };
  },
  mounted() {
   
    setTimeout(() => {
     
      this.isLoading = false;
    }, 1000); 
  },

};
</script>

  
<style scoped>

/* .empty-box {
  height: calc(100vh - 110px);
  color: #616161 !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
} */
</style>