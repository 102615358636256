<template>
  <v-card
    width="100%"
    height="100%"
    class="subjectCard w-100 h-100 justify-space-evenly rounded-xl d-flex align-center flex-column"
    @click="productRedirection(subject.name)"
    
  >
    <img :src="subject?.icon" alt="Icon not available" class="subjectIcon" />

    <div class="w-100 text-center px-4">
      <h6 class="text-body-1 text--primary font-weight-bold my-0">
      {{ subject.name }}
    </h6>
    <v-progress-linear
      :value="progressPercentage"
      background-color="#C4C3C3"
      color="#8B81FF"
      class="mt-3"
      height="6"
      width="100%"
      rounded
    ></v-progress-linear>
    </div>
  
    <!-- class="progress-linear" -->
    <!-- height="10" -->
  </v-card>
</template>
 <script>
export default {
  name: "SubjectCard",
  props: ["subject" , "index"],
  data() {
    return {};
  },
 computed: {
  progressPercentage() {
    // if (this.homeData.subtopicCount === 0) return 0;
    
    const percentage = ((this.subject?.completedSubtopics || 0) / (this.subject.subtopicCount || 1) ) * 100;
    // console.log('Progress Percentagew:', percentage); 
    return percentage;
  }
},

  methods: {
    productRedirection(name) {
      this.$router.push({
        path: "/courseDetail",
        query: {
          subject: name,
          subjectId: this.index,     
        },
      });
    },
  },

  mounted() {},

  components: {},
};
</script>
